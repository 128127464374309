import { PaymentElement } from "@stripe/react-stripe-js";
import isEqual from "lodash/isEqual";
import { memo, useEffect, useMemo, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useBXContext } from "src/BXEngine/BXContext";

export const StripePaymentElements = memo((props: any) => {
  const { stripeTheme, stripeVariables, stripeLabels, stripeRules } = props;
  const { clientSecrets, stripePromises, setStripeAppearance } = useBXContext();
  const location = useLocation();
  const builderView = location.pathname.startsWith("/buildx/form-builder");
  const parentId = builderView ? props?.$config?.parent?.id : props?.__config?.parent?.id;
  const matchingClientSecret = clientSecrets?.find(clientSecret => clientSecret.id === parentId);
  const matchingStripePromise = stripePromises?.find(stripePromise => stripePromise.id === parentId);

  const appearance = useMemo(
    () => ({
      theme: stripeTheme,
      labels: stripeLabels,
      variables: stripeVariables,
      rules: stripeRules,
    }),
    [stripeTheme, stripeLabels, stripeVariables, stripeRules]
  );

  const prevAppearance = useRef(appearance);

  useEffect(() => {
    if (!isEqual(prevAppearance.current, appearance)) {
      setStripeAppearance(appearance);
      prevAppearance.current = appearance;
    }
  }, [appearance, setStripeAppearance]);

  return <>{matchingClientSecret && matchingStripePromise && <PaymentElement />}</>;
});
