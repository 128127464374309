import { useTheme } from "@mui/material";
import { IconEditCircle, IconFolderPlus, IconPlus, IconTrashX } from "@tabler/icons-react";
import update from "immutability-helper";
import _, { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useBXBuilderContext } from "src/BXEngine/BXBuilderContext";
import { useBXContext } from "src/BXEngine/BXContext";
import { BXConfirmationDialog } from "src/components/BXUI/AlertDialog/ConfirmationDialog";
import BXModal from "src/components/BXUI/Modal";
import { BXApp, BXAppCollection } from "src/types/BXAppType";
import { BxPatterns } from "src/utils/BXValidate/BxPatterns";
import { v4 as uuid } from "uuid";
import { changeIdsOfObject } from "../";
import { CreateCollectionForm } from "../forms/CreateCollectionForm";
import BuilderColumn from "./BuilderColumn";

type Props = {
  app?: BXApp;
  collections?: BXAppCollection[];
  selectedData?: any;
  onSelect: (data?: any) => void;
};
let isUpdateApps = true;

const CollectionColumn = (props: Props) => {
  const { app, collections: _collections, selectedData, onSelect } = props;
  const { editApp, addCollection, editCollection, deleteCollection } = useBXBuilderContext();
  const { setSelectedCollectionId } = useBXContext();

  const [isEdit, setIsEdit] = useState(false);
  const [isTrash, setIsTrash] = useState(false);
  const [collection, setCollection] = useState<BXAppCollection>();

  const [collections, setCollections] = useState(_collections);
  const [isDirty, setIsDirty] = useState(false);
  const { palette } = useTheme();

  useEffect(() => {
    if (!isUpdateApps) isUpdateApps = true;
    else setCollections(_collections);
  }, [_collections]);

  const moveCard = (dragIndex: any, hoverIndex: number) => {
    return setCollections((prev: any) => {
      const collections = update(prev, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prev?.[dragIndex]],
        ],
      });
      return collections;
    });
  };

  const updateCardDebounce = useCallback(
    debounce((app, collections) => {
      isUpdateApps = false;
      editApp(app.id, { ...app, templateConfig: { ...app?.templateConfig, collections } });
    }, 800),
    []
  );

  const updateCard = () => {
    updateCardDebounce(app, collections);
  };

  const handleEditClick = (value: any) => {
    setIsEdit(true);
    setCollection(value);
  };
  const handleTrashClick = (value: any) => {
    setIsTrash(true);
    setCollection(value);
  };

  const handleDuplicateClick = (value: any) => {
    const collection = { ...value, name: value?.name + " copy", slug: value.slug + "-copy" };

    const changedTemplateConfigIds = changeIdsOfObject(_.cloneDeep(collection));

    addCollection(app?.id!, changedTemplateConfigIds);
  };

  const data = collections?.map((item: any) => ({
    ...item,
    title: item?.name,
    subTitle: item?.slug,
    image: item?.icon,
    fallBack: "Folder",
  }));

  const handleMoveClick = (handleClose: any, appId: any, collectionId: any, pageId: any, item: any) => {
    addCollection(appId, item as any, () => {
      deleteCollection(app?.id!, item?.id);
    });
    handleClose?.();
  };

  return (
    <>
      {app?.id && collection && (
        <>
          <BXConfirmationDialog
            open={isTrash}
            title={"Are you sure you want to delete this collection?"}
            iconButton
            buttonProps={{ color: "error", children: <IconTrashX /> }}
            onConfirm={() => {
              deleteCollection(app.id, collection.id);
              setIsTrash(false);
            }}
            onCancel={() => setIsTrash(false)}
          />
          <BXModal
            open={isEdit}
            icon={<IconEditCircle />}
            buttonProps={{ startIcon: <IconEditCircle />, color: "secondary" }}
            label={"Edit"}
            title={`Edit collection : ${app?.name} > ${collection?.name}`}
            onClose={() => {
              setIsEdit(false);
            }}
            isDirty={isDirty}
          >
            {(handleClose: Function) => {
              return (
                <CreateCollectionForm
                  collection={collection}
                  collections={_collections}
                  onSave={data => {
                    const newCollection: BXAppCollection = {
                      ...collection,
                      ...data,
                    };
                    editCollection(app.id, collection.id, newCollection);
                    handleClose?.(true);
                  }}
                  onCancel={() => handleClose?.()}
                  setIsDirty={setIsDirty}
                />
              );
            }}
          </BXModal>
        </>
      )}

      <BuilderColumn
        id={app?.id}
        headerName={"Collections"}
        type={"collection"}
        withMove
        rows={data}
        selectedData={selectedData}
        moveApp={moveCard}
        updateApp={updateCard}
        onEditClick={handleEditClick}
        onTrashClick={handleTrashClick}
        onSelect={onSelect}
        onDuplicateClick={handleDuplicateClick}
        onMoveClick={handleMoveClick}
        modal={
          <BXModal
            label={"Collection"}
            icon={<IconFolderPlus />}
            buttonProps={{
              variant: "contained",
              style: { backgroundColor: palette.primary.main, borderRadius: 24 },
              startIcon: <IconPlus />,
              fullWidth: true,
              disabled: !app?.id,
            }}
            title={`New collection : ${app?.name}`}
          >
            {(handleClose: Function) => {
              return (
                <CreateCollectionForm
                  collections={_collections}
                  onSave={data => {
                    const newCollection: BXAppCollection = {
                      id: uuid(),
                      icon:
                        (data?.appConfig?.iconConfig?.visibility === "PUBLIC" && data?.iconConfig?.icon?.match(BxPatterns.mediaUrlPattern)
                          ? data?.appConfig?.iconConfig?.url
                          : data?.appConfig?.iconConfig?.icon) || data?.icon,
                      pages: [],
                      slug: data.slug,
                      name: data.name,
                    };
                    if (app?.id) {
                      addCollection(app?.id, newCollection);
                      setSelectedCollectionId(newCollection.id);
                    }
                    handleClose();
                  }}
                  onCancel={() => {
                    handleClose();
                  }}
                />
              );
            }}
          </BXModal>
        }
      />
    </>
  );
};

export default CollectionColumn;
