import { Box, FormHelperText } from "@mui/material";
import { CardCvcElement } from "@stripe/react-stripe-js";
import { StripeCardNumberElementOptions } from "@stripe/stripe-js";
import { memo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useBXContext } from "src/BXEngine/BXContext";

export const StripeCardCvcElement = memo((props: any) => {
  const { stripeVariables, stripePlaceholder } = props;
  const { clientSecrets, stripePromises } = useBXContext();
  const [error, setError] = useState<any>(null);
  const location = useLocation();
  const builderView = location.pathname.startsWith("/buildx/form-builder");
  const parentId = builderView ? props?.$config?.parent?.id : props?.__config?.parent?.id;
  const matchingClientSecret = clientSecrets?.find(clientSecret => clientSecret.id === parentId);
  const matchingStripePromise = stripePromises?.find(stripePromise => stripePromise.id === parentId);
  const { border = "1px solid #ccc", borderRadius = "8px", padding = "5px", backgroundColor = "#f5f5f5" } = stripeVariables || {};

  const CARD_ELEMENT_OPTIONS: StripeCardNumberElementOptions = {
    style: {
      base: stripeVariables,
    },
    placeholder: stripePlaceholder,
  };

  return (
    <>
      {matchingClientSecret && matchingStripePromise && (
        <Box
          sx={{
            height: "100%",
            border,
            borderRadius,
            padding,
            backgroundColor,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box sx={{ flex: 1 }}>
            <CardCvcElement
              onChange={(e: any) => {
                setError(e.error);
              }}
              options={CARD_ELEMENT_OPTIONS}
            />
          </Box>
        </Box>
      )}
      <FormHelperText error>{error?.message}</FormHelperText>
    </>
  );
});
