import { memo, useCallback, useEffect, useRef, useState } from "react";
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from "react-google-maps";
import { useBXContext } from "src/BXEngine/BXContext";
import { useReplaceDataPlaceholders } from "src/components/BXUI/DataTable/ActionButton";
import markerImage from "../../../../../../assets/images/icons8-marker.png";

const center = { lat: 34.052235, lng: -118.243683 };

const MapComponent = withScriptjs(
  withGoogleMap((props: any) => {
    const { selectedLocation, onMapClick, onMarkerDragEnd, options, onChange, zoomLevel, onZoomChanged } = props;

    const {
      enableMapTypeControl,
      enableFullscreenControl,
      enableZoomControl,
      enableStreetViewControl,
      keepPinCentered,
      longitudeDefaultValue,
      latitudeDefaultValue,
    } = options;
    const mapRef = useRef<any>(null);
    const [mapInstance, setMapInstance] = useState<any>(null);
    const latDefault = Number(latitudeDefaultValue);
    const lngDefault = Number(longitudeDefaultValue);
    const isLatValid = !isNaN(latDefault) && latDefault !== 0;
    const isLngValid = !isNaN(lngDefault) && lngDefault !== 0;
    const mapCenter =
      selectedLocation && typeof selectedLocation.lat === "number" && typeof selectedLocation.lng === "number"
        ? selectedLocation
        : {
            lat: isLatValid ? latDefault : center.lat,
            lng: isLngValid ? lngDefault : center.lng,
          };

    const handleCenterChanged = () => {
      if (mapInstance) {
        const newCenter = mapInstance.getCenter();
        const latitude = typeof newCenter.lat === "function" ? newCenter.lat() : newCenter.lat;
        const longitude = typeof newCenter.lng === "function" ? newCenter.lng() : newCenter.lng;
        if (onChange) {
          onChange({ lat: latitude, lng: longitude });
        }
      }
    };

    const handleZoomChanged = () => {
      if (mapInstance) {
        const newZoom = mapInstance.getZoom();
        if (onZoomChanged) {
          onZoomChanged(newZoom);
        }
      }
    };

    useEffect(() => {
      if (mapRef.current) {
        setMapInstance(mapRef.current.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED);
      }
    }, [mapRef]);

    useEffect(() => {
      if (mapInstance && typeof zoomLevel === "number") {
        mapInstance.setZoom(zoomLevel);
      }
    }, [mapInstance, zoomLevel]);

    useEffect(() => {
      if (keepPinCentered && mapInstance) {
        handleCenterChanged();
      }
    }, [mapInstance, keepPinCentered]);

    return (
      <div style={{ flex: 1 }}>
        <GoogleMap
          ref={mapRef}
          onCenterChanged={keepPinCentered ? handleCenterChanged : undefined}
          center={mapCenter}
          onClick={onMapClick}
          zoom={zoomLevel}
          onZoomChanged={handleZoomChanged}
          defaultOptions={{
            mapTypeControl: enableMapTypeControl,
            fullscreenControl: enableFullscreenControl,
            zoomControl: enableZoomControl,
            streetViewControl: enableStreetViewControl,
            center: mapCenter,
          }}
        >
          {selectedLocation && !keepPinCentered && <Marker position={selectedLocation} onDragEnd={onMarkerDragEnd} />}
        </GoogleMap>

        {keepPinCentered && (
          <div style={{ left: "50%", marginLeft: -24, marginTop: -48, position: "absolute", top: "50%" }}>
            <img style={{ height: 48, width: 48 }} src={markerImage} alt='' />
          </div>
        )}
      </div>
    );
  })
);

export const CustomGoogleMap = memo((props: any) => {
  const {
    enableMapTypeControl,
    enableFullscreenControl,
    enableZoomControl,
    enableStreetViewControl,
    onChange,
    referenceLatAndLngValues,
    keepPinCentered,
    googleMapApiKey,
    longitudeDefaultValue,
    latitudeDefaultValue,
    longitudeInputValue,
    latitudeInputValue,
    pageId,
    info,
  } = props;

  const localGoogleMapsApiKey = googleMapApiKey;
  const [selectedLocation, setSelectedLocation] = useState<null | { lat: number; lng: number }>();
  const [zoomLevel, setZoomLevel] = useState<number>(15);
  const { replaceDataPlaceholders } = useReplaceDataPlaceholders({ viewName: info?.viewName });
  const { currentApp, viewsState } = useBXContext();
  const localReferenceLatAndLngValues = `{this.state.${referenceLatAndLngValues}.geometry.location}`;

  const resolvedLocalReferenceLatAndLngValues = replaceDataPlaceholders({
    queryString: localReferenceLatAndLngValues,
    viewsState,
    pageId,
    viewName: info?.viewName,
    env: currentApp?.env,
  });

  const handleMapClick = useCallback(
    event => {
      const newLocation = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      setSelectedLocation(newLocation);

      if (onChange) {
        onChange(newLocation);
      }
    },
    [onChange]
  );

  const handleMarkerDragEnd = useCallback(
    event => {
      const newLocation = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      setSelectedLocation(newLocation);

      if (onChange) {
        onChange(newLocation);
      }
    },
    [onChange]
  );

  const handleZoomChanged = useCallback(newZoom => {
    setZoomLevel(newZoom);
  }, []);

  useEffect(() => {
    if (longitudeInputValue && latitudeInputValue) {
      const lat = Number(latitudeInputValue);
      const lng = Number(longitudeInputValue);

      if (isFinite(lat) && isFinite(lng)) {
        const newLocation = {
          lat,
          lng,
        };
        setSelectedLocation(newLocation);
      }
    }
  }, [longitudeInputValue, latitudeInputValue]);

  useEffect(() => {
    if (resolvedLocalReferenceLatAndLngValues) {
      const latitude =
        typeof resolvedLocalReferenceLatAndLngValues.lat === "function"
          ? resolvedLocalReferenceLatAndLngValues.lat()
          : resolvedLocalReferenceLatAndLngValues.lat;
      const longitude =
        typeof resolvedLocalReferenceLatAndLngValues.lng === "function"
          ? resolvedLocalReferenceLatAndLngValues.lng()
          : resolvedLocalReferenceLatAndLngValues.lng;

      setSelectedLocation({ lat: latitude, lng: longitude });
      setZoomLevel(15);
    }
  }, [resolvedLocalReferenceLatAndLngValues]);

  return (
    <MapComponent
      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${localGoogleMapsApiKey}&libraries=places`}
      loadingElement={<div style={{ height: "100%" }} />}
      containerElement={<div style={{ height: "100%" }} />}
      mapElement={<div style={{ height: "100%" }} />}
      selectedLocation={selectedLocation}
      onMapClick={handleMapClick}
      onMarkerDragEnd={handleMarkerDragEnd}
      onChange={onChange}
      zoomLevel={zoomLevel}
      onZoomChanged={handleZoomChanged}
      options={{
        enableMapTypeControl,
        enableFullscreenControl,
        enableZoomControl,
        enableStreetViewControl,
        keepPinCentered,
        latitudeDefaultValue,
        longitudeDefaultValue,
      }}
    />
  );
});
