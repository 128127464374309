import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { IconRefresh } from "@tabler/icons-react";

import { gql } from "@apollo/client";
import _ from "lodash";
import { FC, Fragment, useEffect, useRef, useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import ReactJson from "react-json-view";
import { BXEngine } from "src/BXEngine";
import Sound1 from "src/assets/sounds/notification-sound-1.mp3";
import Sound2 from "src/assets/sounds/notification-sound-2.mp3";
import Sound3 from "src/assets/sounds/notification-sound-3.mp3";
import { PowerPackDetailsCard } from "src/components/GridItem";
import { PermissibleRender } from "src/components/PermissionValidation/PermissibleRender";
import { Pagination } from "src/components/pagination";
import { ElementBaseProps, UIElement } from "src/types/UIElement";
import { getLastKeyFromObject, handleCleanupQueries } from "src/utils/generalUtils";
import { queryClient, useBXContext, useValue } from "../../../BXEngine/BXContext";
import { ActionButton, useReplaceDataPlaceholders } from "../DataTable/ActionButton";
import { UserInput } from "../UserInput";
export const BXCardList: FC<ElementBaseProps> = ({
  id,
  dataSource,
  info,
  actions,
  views,
  selectedViewId,
  prevVideoCardId,
  __data = {},
  closeModal,
  type,
  parentIds = [],
  index,
  pageId,
  path,
}) => {
  const { viewsState, setViewsState, currentApp } = useBXContext();
  const { replaceDataPlaceholders } = useReplaceDataPlaceholders();

  const [isApiMode, setIsApiMode] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [cursors, setCursors] = useState([prevVideoCardId]);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [currentActionEndpoint, setCurrentActionEndpoint] = useState("");
  const [dataEntry, setDataEntry] = useState("");
  const { palette } = useTheme();

  const selectedDataEntry = dataSource?.sourceType == "USER INPUT" ? dataEntry : dataSource?.dataEntry;

  const soundRef1 = useRef(null);
  const soundRef2 = useRef(null);
  const soundRef3 = useRef(null);

  const soundMap: any = {
    sound1: soundRef1,
    sound2: soundRef2,
    sound3: soundRef3,
  };

  const withInfiniteScroll = (!info?.paginationMode || info?.paginationMode == "Infinite Scroll") && !dataSource?.splitViewConfig?.enabled;

  const queryKeys = [
    `${pageId}-${info?.viewName}`,
    // selectedViewId
  ];

  const selectedRow = viewsState?.[queryKeys[0]]?._selectedItem;
  const userInputsValues = viewsState?.[queryKeys[0]]?.userInputs || {};

  if (cursors?.[currentPage - 1]) {
    queryKeys.push(cursors?.[currentPage - 1] as any);
  }

  const { data, isFetching, hasNextPage, fetchNextPage, refetchDataSource, isError } = useValue({
    queryKeys,
    __data,
    selectedItem: selectedRow,
    cursor: cursors?.[currentPage - 1],
    withInfiniteQuery: withInfiniteScroll,
    viewId: id,
    limit: dataSource?.splitViewConfig?.enabled ? 1 : dataSource?.limit || 20,
    isUserInput: dataSource?.sourceType == "USER INPUT",
    dataEntry: selectedDataEntry,
    pageId,
    notificationMessage: info?.notificationMessage,
    soundPlayer: soundMap[info?.notificationSound as any],
    pagination: { index },
    endpoint: currentActionEndpoint,
    options: {
      refetchInterval: info?.autoRefresh && (info?.timer || 60) * 1000,
      refetchIntervalInBackground: !!info?.autoRefresh,
      onSuccess: (data: any) => {
        if (dataSource?.splitViewConfig?.enabled && _.get(data, selectedDataEntry as any)?.[0]) {
          handleSelectRow(_.get(data, selectedDataEntry as any)?.[0]);
        }
      },
    },
  });

  let entries: any[];
  // @ts-ignore
  if (!withInfiniteScroll) {
    entries = (_.isNil(selectedDataEntry) || !selectedDataEntry ? data : _.get(data, selectedDataEntry as any))?.filter?.(Boolean);
  } else {
    entries = (
      _.isNil(selectedDataEntry) || !selectedDataEntry
        ? _.flatten(data?.pages)
        : _.flatten(data?.pages?.map((p: any) => _.get(p, selectedDataEntry as any)))
    )?.filter?.(Boolean);
  }

  const [sentryRef] = useInfiniteScroll({
    loading: isFetching as boolean,
    hasNextPage: !entries?.length ? false : hasNextPage || false,
    onLoadMore: () => {
      fetchNextPage?.();
    },
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: isError,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: "0px 0px 400px 0px",
  });
  let gqlQuery;
  const isGraphQL = dataSource?.payload?.isGraphQL;
  if (isGraphQL) {
    gqlQuery = gql`
      ${dataSource?.payload?.graphqlQuery}
    `;
  }

  useEffect(() => {
    return () => {
      if (dataSource?.sourceType == "USER INPUT") {
        handleCleanupQueries({
          isGraphQL,
          gqlQuery,
          queryKeys: [id],
        });
      }
    };
  }, []);

  const handleSelectRow = (itemData: any) => {
    setViewsState((prev: any) => {
      const data = { ...prev };
      data[`${pageId}-${info?.viewName}`] = {
        ...data[`${pageId}-${info?.viewName}`],
        _selectedItem: itemData,
      };
      return data;
    });
  };

  const selectedView = views?.find(view => view?.id == dataSource?.splitViewConfig?.viewId);
  const WrapperGrid = dataSource?.splitViewConfig?.enabled ? Grid : Fragment;
  const WrapperGridProps = dataSource?.splitViewConfig?.enabled ? { item: true, xs: 4 } : {};
  const userInputView = views?.find((view: any) => view?.id === dataSource?.userInputFormBuilderId);

  return (
    <Box marginY={1} paddingX={1}>
      <audio ref={soundRef1} src={Sound1} />
      <audio ref={soundRef2} src={Sound2} />
      <audio ref={soundRef3} src={Sound3} />
      <Card variant={"outlined"}>
        <Grid container paddingX={2} mt={2} mb={2}>
          <Grid container xs={12}>
            <Typography flex={1} fontSize={"16px"} lineHeight={2}>
              {replaceDataPlaceholders({
                queryString: info?.name,
                item: selectedRow,
                viewsState,
                pageId,
                __data,
                pagination: { index },
                env: currentApp?.env,
              })}
            </Typography>

            {_.isArray(actions) && !_.isEmpty(actions.filter(action => action.isGlobal)) && (
              <>
                {actions
                  ?.filter(action => action.isGlobal)
                  .map(action => (
                    <PermissibleRender isAllowed path={[path, action.label].join(".")} action={["VIEW"]}>
                      {({ permitted }) => {
                        if (!permitted) return null;
                        return (
                          <Box key={action.id} marginInlineStart={1} alignSelf='center'>
                            <ActionButton
                              item={{}}
                              path={[path, action.label].join(".")}
                              tableId={id}
                              queryKeys={queryKeys}
                              pageId={pageId}
                              viewName={info?.viewName}
                              tableAction={{
                                action: action,
                                label: action?.label,
                                icon: action?.icon,
                                condition: action?.condition,
                              }}
                              views={views}
                              closeModal={closeModal}
                              __data={__data}
                              parentIds={parentIds}
                              iconButton={!action?.showButtonLabel}
                            />
                          </Box>
                        );
                      }}
                    </PermissibleRender>
                  ))}
              </>
            )}
            <Tooltip title='Refresh'>
              <Box marginInlineStart={1} alignSelf='center'>
                <IconButton
                  onClick={() => {
                    setIsRefreshing(true);
                    queryClient.refetchQueries(queryKeys).then(() => {
                      setIsRefreshing(false);

                      // enqueueSnackbarRef?.("Refreshed Successfully", {
                      //   variant: "success",
                      // });
                    });
                  }}
                  disabled={isFetching}
                  style={{ backgroundColor: palette.primary.light }}
                >
                  {isRefreshing ? <CircularProgress size='16px' /> : <IconRefresh color={palette.text.primary} size={16} />}
                </IconButton>
              </Box>
            </Tooltip>
            {!withInfiniteScroll && !dataSource?.splitViewConfig?.enabled && !!entries?.length && (
              <Pagination
                currentPage={currentPage}
                totalPageCount={cursors?.length}
                hasNextPage={data?.hasMore && !isFetching}
                isFetching={isFetching}
                onPageChange={(page: number) => {
                  setCurrentPage(page);
                  if (page > currentPage) {
                    setCursors(prev => [...prev, data.cursor]);
                  } else {
                    setCursors(prev => prev.slice(0, page));
                  }
                }}
              />
            )}

            {info?.showApiMode != "Hidden" && !dataSource?.splitViewConfig?.enabled && (
              <Box display='flex' alignItems='center' border={"1px solid white"} marginInlineStart={2} paddingX={1.5} borderRadius={10}>
                <Typography>API Mode</Typography>
                <Switch onChange={(_, value) => setIsApiMode(value)} />
              </Box>
            )}
          </Grid>
          {dataSource?.sourceType == "USER INPUT" && (
            <Box width='100%'>
              <UserInput
                queryKeys={queryKeys}
                pageId={pageId}
                setCurrentActionEndpoint={setCurrentActionEndpoint}
                userInputView={userInputView}
                __data={__data}
                refetch={refetchDataSource}
                views={views}
                setDataEntry={setDataEntry}
              />
            </Box>
          )}
          <Grid container paddingY={2.5} spacing={"10px"}>
            {isApiMode ? (
              <ReactJson src={entries as any} theme={"paraiso"} style={{ width: "100%" }} />
            ) : (
              <Grid item xs={12} container spacing={"10px"}>
                <WrapperGrid {...WrapperGridProps}>
                  {entries?.map?.((item: any, index: number) => {
                    return (
                      <Grid
                        item
                        key={`gi-${item?.id || index}` || index}
                        xs={12}
                        {...(!dataSource?.splitViewConfig?.enabled && { sm: 6, md: 4, lg: 3, xl: 2 })}
                        onClick={() => handleSelectRow(item)}
                      >
                        <PowerPackDetailsCard
                          key={item?.id}
                          {...(typeof item === "object" ? item : { dataString: item })}
                          coreData={item}
                          prevId={index === 0 ? undefined : entries?.[index - 1]?.id}
                          index={(currentPage - 1) * (dataSource?.limit ?? 20) + index}
                          queryKeys={queryKeys}
                          viewName={info?.viewName}
                          views={views}
                          pageId={pageId}
                          viewType={type}
                          gridId={id}
                          actions={actions}
                          gridKey={dataSource?.gridKey}
                          dataSource={dataSource}
                          playOnHover={dataSource?.playOnHover}
                          userInputsValues={userInputsValues}
                          isUserInput={dataSource?.sourceType == "USER INPUT"}
                          onSelectRow={handleSelectRow}
                          __data={__data}
                          closeModal={closeModal}
                          parentIds={parentIds}
                          path={path}
                        />
                      </Grid>
                    );
                  })}

                  {((_.isEmpty(entries) && !isFetching) || isError) && (
                    <Grid item xs={12}>
                      <Typography component='div' align='center'>
                        {dataSource?.sourceType != "TABLE"
                          ? "No Records Available"
                          : `Select Data from ${dataSource?.table?.info?.name} Table`}
                      </Typography>
                    </Grid>
                  )}
                  {(isFetching || (!!entries?.length && hasNextPage)) && (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} ref={sentryRef}>
                      <CircularProgress />
                    </Grid>
                  )}
                </WrapperGrid>

                {dataSource?.splitViewConfig?.enabled && !isFetching && (
                  <Grid item xs>
                    <BXEngine
                      path={[path, (selectedView as UIElement).info?.name].join(".")}
                      auth={{}}
                      showModalHeader
                      page={{ id: pageId, views, layout: selectedView } as any}
                      layout={[{ ...selectedView, type: selectedView?.type }]}
                      isVisible
                      selectedViewId={entries?.[0]?.id}
                      __data={{
                        ...__data,
                        [(getLastKeyFromObject(__data) || "") + "#."]: info?.viewName,
                      }}
                      closeModal={closeModal}
                      parentIds={[...parentIds, id]}
                    />
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};
