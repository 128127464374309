import { Box, FormHelperText, SxProps, Tooltip, useTheme } from "@mui/material";
import { id } from "date-fns/locale";
import { joinObjects } from "hd-utils";
import _, { isObject } from "lodash";
import moment from "moment";
import { FC, Fragment, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { BXEngine, KeysToComponentMap } from "src/BXEngine";
import { useBXContext, useValue } from "src/BXEngine/BXContext";
import { PermissibleRender } from "src/components/PermissionValidation/PermissibleRender";
import { produceTrigger } from "src/features/appState/appStateSlice";
import { useAppState } from "src/features/appState/hooks";
import { validationRules } from "src/utils/BXValidate/validationRules";
import { colorToRgbVariants, getLastKeyFromObject, isUnixTimestamp, isValidDate } from "src/utils/generalUtils";
import { MUI_COMPONENTS } from "src/views/pages/BuildX/FormBuilder";
import { StripeContainer } from "src/views/pages/BuildX/FormBuilder/components/StripeComponents/StripeContainer";
import { ComponentItemType } from "src/views/pages/BuildX/FormBuilder/types";
import { isParentFlex, mapValuesRecursively } from "src/views/pages/BuildX/FormBuilder/utils";
import { AppController } from "../AppController";
import { ActionButton, useReplaceDataPlaceholders } from "../DataTable/ActionButton";
import ContainerChildren from "./ContainerChildren";
import OnTriggerHandler from "./OnTriggerHandler";
import { getControllerNameFallback, handleChange, handleClick, handleKeyDown } from "./RenderItems";

export const RenderChildren: FC<any> = ({ element, index, hiddenByStepper, ...props }) => {
  const {
    data,
    localViews,
    views,
    control,
    pageId,
    getValues,
    errors,
    __data,
    parentIds,
    closeModal,
    info,
    item,
    currentApp,
    viewsState,
    queryKey,
    dataSource,
    resetForm,
    disableInternalAction,
    onActionClick,
    componentData,
    setFormBuilderState,
    path,
    handleSelectRow,
    pageOutlet,
    multiLingual,
    disabled,
    parentKey,
    repeatedParentKey,
    elements,
    stripe,
    currentRepeatedItem,
    isLoadingImage,
  } = props;
  const metaData = {
    __data,
    item,
    queryKey,
    pageId,
    setFormBuilderState,
    path,
    viewName: info?.viewName,
  };
  const { replaceDataPlaceholdersRecursively, replaceDataPlaceholders } = useReplaceDataPlaceholders({ viewName: info?.viewName });
  const { handleSubmit } = useAppState();
  const theme = useTheme();

  const queryKeys = [`${pageId}-${info?.viewName}-${element?.props?.key}`];
  function updateElementBasedOnMapKey() {
    if (element?.props?.isMapValues) {
      const mapKey = replaceDataPlaceholders({
        queryString: element?.config?.mapKey || "",
        item,
        viewsState,
        pageId,
        __data,
        env: currentApp?.env,
        index,
        fallback: "",
      });

      const condition = element?.config?.conditionMapKey || "equal";
      let selectedOption;
      if (condition === "equal") {
        selectedOption = element?.optionMap?.[mapKey];
      } else if (condition === "startWith") {
        const mapKeyStr = String(mapKey);
        const matchingKey = Object.keys(element?.optionMap || {}).find(key => mapKeyStr.startsWith(key));
        if (matchingKey) {
          selectedOption = element?.optionMap?.[matchingKey];
        }
      }

      return selectedOption
        ? {
            ...selectedOption,
            optionMap: element?.optionMap || {},
            selectedType: element?.selectedType,
          }
        : {
            ...(element?.optionMap?.default || {}),
            optionMap: element?.optionMap || {},
            selectedType: element?.selectedType,
          };
    } else {
      return _.cloneDeep(element);
    }
  }
  element = updateElementBasedOnMapKey();

  function updateDataSourceBasedOnMapKey() {
    if (element?.config?.isMapDataSource) {
      const dataSourceMapKey = replaceDataPlaceholders({
        queryString: element?.config?.dataSourceMapKey,
        item,
        viewsState,
        pageId,
        __data,
        index,
        env: currentApp?.env,
        fallback: "",
      });

      const condition = element?.config?.conditionDataSourceMap || "equal";

      let selectedOption;
      if (condition === "equal") {
        selectedOption = element?.dataSourceMap?.[dataSourceMapKey];
      } else if (condition === "startWith") {
        const dataSourceMapKeyStr = String(dataSourceMapKey);
        const matchingKey = Object.keys(element?.dataSourceMap || {}).find(key => dataSourceMapKeyStr.startsWith(key));
        if (matchingKey) {
          selectedOption = element?.dataSourceMap?.[matchingKey];
        }
      }

      return selectedOption
        ? {
            ...element,
            configData: { ...selectedOption },
          }
        : {
            ...element,
            configData: { ...element?.dataSourceMap?.default },
          };
    } else {
      return element;
    }
  }
  element = updateDataSourceBasedOnMapKey();

  // const isDataFetch = watch(`${pageId}.${info?.viewName}.${element?.props?.key}`);

  const { refetchDataSource, fetchNextPage } = useValue({
    queryKeys,
    __data,
    viewId: id,
    limit: element?.configData?.paginationKey || 20,
    dataEntry: element?.configData?.dataEntry,
    pageId,
    selectedItem: item,
    options: {
      enabled: element?.configData?.sourceType == "API" || element?.configData?.sourceType == "LIST",
    },
    dataSource: element?.configData?.sourceType == "API" && element?.configData?.source,
    endpoint: element?.configData?.sourceType == "LIST" && element?.configData?.selectValues,
    viewName: info?.viewName,
    componentKey: element?.props?.key,
    path: path,
  });

  const { leftDrawerOpened, setLeftDrawerOpened, stripeEndpoints, setStripeEndpoints } = useBXContext();

  const ref = useRef(null);
  const handleLeftDrawerToggle = () => {
    setLeftDrawerOpened(!leftDrawerOpened);
  };
  const getControllerName = (element: any): string => {
    let componentKey;

    switch (element?.type) {
      case ComponentItemType.CustomRadio:
      case ComponentItemType.CustomCheckbox: {
        componentKey = element?.props?.groupName;
        break;
      }
      default: {
        componentKey = element?.props?.key;
        break;
      }
    }

    componentKey = componentKey || getControllerNameFallback(element);

    if (!_.isNil(index)) {
      // if (repeatedParentKey) {
      //   componentKey = `${repeatedParentKey}.${componentKey}-${index}`;
      // } else {
      componentKey = `${componentKey}-${index}`;
      // }
    }

    return `${pageId}.${info?.viewName}.${componentKey}`;
  };

  const getHierarchyName = (elementKey: any, parentKey = ""): string => {
    if (!_.isNil(index)) {
      return `${parentKey}.${elementKey}-${index}`;
    }
    return `${parentKey}.${elementKey}`;
  };

  const getControllerDefaultValue = (element: any) => {
    if (element?.config?.controlledComponent && element?.props?.key) {
      let value;

      if (!isObject(element?.props?.defaultValue)) {
        value = replaceDataPlaceholders({
          queryString: element?.props?.defaultValue,
          item,
          viewsState,
          pageId,
          index,
          __data,
          env: currentApp?.env,
          fallback: "",
        });
      } else {
        value = replaceDataPlaceholdersRecursively({
          obj: _.cloneDeep(element?.props?.defaultValue),
          item,
          viewsState,
          pageId,
          __data,
          env: currentApp?.env,
          fallback: "",
        });
      }

      // default value for color picker
      if (element?.type === ComponentItemType.ColorPicker) {
        value = colorToRgbVariants(value);
      }

      if (element?.type === ComponentItemType.CustomAutoCompleteBX) {
        value = { userOptions: { label: "", value: value, selectedData: null } };
      }

      //default value for date picker
      if (element?.type === ComponentItemType.DatePicker || element?.type === ComponentItemType.DateTimePicker) {
        const isUserLocalTime = element?.config?.isUserLocalTime;

        if (!value || !isValidDate(value)) {
          value = {
            utcISO: null,
            userLocalISO: null,
            epoch: null,
          };
        } else if (isUnixTimestamp(Number(value))) {
          const formattedUtcIso = moment(Number(value)).format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
          const date = new Date(Number(value));

          value = isUserLocalTime
            ? {
                utcISO: date.toISOString(),
                userLocalISO: moment(date).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                epoch: date.valueOf(),
              }
            : {
                utcISO: formattedUtcIso,
                userLocalISO: moment(formattedUtcIso).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                epoch: moment(formattedUtcIso).valueOf(),
              };
        } else {
          let formattedUtcIso = moment.utc(value).format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";

          const date = new Date(value);

          value = isUserLocalTime
            ? {
                utcISO: date.toISOString(),
                userLocalISO: moment(date).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                epoch: date.valueOf(),
              }
            : {
                utcISO: formattedUtcIso,
                userLocalISO: moment(formattedUtcIso).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                epoch: moment(formattedUtcIso).valueOf(),
              };
        }
      }

      // default for missing value according to the type of the component
      if (!value) {
        switch (element?.type) {
          case ComponentItemType.FileUploadInput:
            value = {
              key: null,
              url: null,
              file: null,
              ext: null,
            };
            break;
          case ComponentItemType.CustomSwitch:
            value = String(element?.props?.defaultValue) === "true" ? true : false;
            break;
          case ComponentItemType.BXSelect:
            value = element?.props?.defaultValue || element?.configData?.selectValues?.[0]?.value;
            break;
          case "formTable":
            value = [];
            break;
          case "keyValue":
            value = {};
            break;
          default:
            value = "";
            break;
        }
      }
      return value;
    }
  };

  const visibilityState = visibilityConfig => {
    try {
      const data = replaceDataPlaceholders({
        queryString: visibilityConfig?.visibilityCondition,
        item,
        index,
        pageId,
        __data,
        env: currentApp?.env,
      });

      const isConditionMet = eval(data);

      if (isConditionMet) {
        const visibilityType = visibilityConfig?.selectedVisibilityType || "Hidden";

        switch (visibilityType) {
          case "Hidden":
            return "hidden";
          case "None":
            return "none";
          default:
            return "visible";
        }
      }
    } catch (error) {}
    return "visible";
  };

  const isDisableConditionMet = disableConfig => {
    try {
      const data = replaceDataPlaceholders({
        queryString: disableConfig?.disableCondition,
        item,
        pageId,
        index,
        __data,
        env: currentApp?.env,
      });

      const isConditionMet = eval(data);

      return isConditionMet;
    } catch (error) {
      return false;
    }
  };

  //Respond to specific events and signals related to the current view or component and consume them
  const viewKey: string = getControllerName(element);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      produceTrigger({
        name: viewKey,
        type: "onLoad",
        eventPayload: {},
      })
    );
    if (element?.id && stripeEndpoints) {
      const updatedEndpoints = [...stripeEndpoints];

      const publishableSource = element?.props?.stripePublishableEndpoint?.source;
      const secretSource = element?.props?.stripeSecretEndpoint?.source;

      const existingEndpoint = updatedEndpoints.find(ep => ep.id === element?.id);

      if (publishableSource || secretSource) {
        const newEndpoint = {
          id: element?.id,
          publishableKeyEndpoint: publishableSource || (existingEndpoint?.publishableKeyEndpoint ?? null),
          clientSecretKeyEndpoint: secretSource || (existingEndpoint?.clientSecretKeyEndpoint ?? null),
        };

        if (existingEndpoint) {
          existingEndpoint.publishableKeyEndpoint = newEndpoint.publishableKeyEndpoint;
          existingEndpoint.clientSecretKeyEndpoint = newEndpoint.clientSecretKeyEndpoint;
        } else {
          updatedEndpoints.push(newEndpoint);
        }
      }

      setStripeEndpoints(updatedEndpoints);
    }
  }, []);

  const Component = MUI_COMPONENTS[element?.type];
  const CustomComponent = KeysToComponentMap[element?.type];
  if (!Component && !CustomComponent) return <></>;
  const componentProps = { ...element?.props };
  const componentVisibility = visibilityState(element?.config?.visibility);

  const { withOverlay = false, overlayColor = "#000000", overlayOpacity = "0.5" } = element?.config?.disable || {};

  const isComponentDisabled = (isDisableConditionMet(element?.config?.disable) && !withOverlay) || element?.props?.disabled || disabled;

  const overlay = withOverlay && (isDisableConditionMet(element?.config?.disable) || element?.props?.disabled) && (
    <Box
      sx={{
        backgroundColor: overlayColor || "#000",
        opacity: overlayOpacity,
        position: "absolute",
        userSelect: "none",
        zIndex: 5,
        width: "100%",
        height: "100%",
        borderRadius: componentProps?.sx?.borderRadius || componentProps?.borderRadius || `${theme.shape.borderRadius}px`,
      }}
    />
  );
  const viewRef = `${pageId}-${info?.viewName}`;
  const toggle = viewsState?.visibility?.[viewRef]?.[element?.props?.key]?.toggle;

  const displayRef = viewsState?.visibility?.[viewRef]?.[element?.props?.key]?.display ?? "none";
  let boxStyles: SxProps = {
    position: dataSource?.formBuilderConfig?.isDynamicHeight ? "unset" : "absolute",
    // position: isCustomContainerPresent ? "relative" : dataSource?.formBuilderConfig?.isDynamicHeight ? "unset" : "absolute",
    top: element?.top,
    left: element?.leftPercentage,
    width: element?.config?.isDynamicWidth
      ? "auto"
      : element?.config?.fixedWidth
      ? element?.config?.widthPx
      : element?.config?.widthPercentage,
    height: !element?.config?.isPercentageHeight ? element?.config?.heightPx : element?.config?.heightPercentage,
    overflow: element?.config?.overflow || "visible",
    ...element?.config?.sx,
  };

  if (displayRef === "none") {
    (boxStyles as any).display = !toggle ? (element?.config?.hideElement ? "none" : "block") : "none";
  }

  if (displayRef === "hidden") {
    (boxStyles as any).visibility = !toggle ? (element?.config?.hideElement ? "hidden" : "visible") : "hidden";
  }

  if (
    [ComponentItemType.FlexContainer, ComponentItemType.GridContainer, ComponentItemType.StepperContainer].includes(
      element?.config?.parent?.type
    )
  ) {
    boxStyles = joinObjects<SxProps>(
      {
        position: "static",
        width: element?.config?.isDynamicWidth
          ? "auto"
          : element?.config?.fixedWidth
          ? element?.config?.widthPx
          : element?.config?.widthPercentage,
        ...(element?.config?.parent?.type === ComponentItemType.GridContainer && {
          width: "100%",
        }),
      },
      element?.config?.parentStyle
    )!;
  }

  if (isParentFlex(element)) {
    boxStyles = joinObjects<SxProps>(
      {
        position: "static",
        width: element?.config?.isDynamicWidth
          ? "auto"
          : element?.config?.fixedWidth
          ? element?.config?.widthPx
          : element?.config?.widthPercentage,
        height: !element?.config?.isPercentageHeight ? element?.config?.heightPx : element?.config?.heightPercentage,
        ...element?.config?.sx,
        boxSizing: "border-box",
      },
      element?.config?.parentStyle
    )!;
  }

  const view = localViews?.find((view: any) => view?.info?.name == componentData?.[element?.config?.customComponentId]?.name) || {};

  const replacePropsPlaceholders = replaceDataPlaceholdersRecursively({
    obj: _.cloneDeep(componentProps),
    item,
    viewsState,
    pageId,
    formData: getValues,
    __data,
    viewName: props?.info?.viewName,
    env: currentApp?.env,
    index,
    fallback: "",
    multiLingual: multiLingual,
  });

  const resolvedProps = mapValuesRecursively(replacePropsPlaceholders);
  const CheckToolTipParent = element?.actionConfig?.label || resolvedProps.toolTip ? Tooltip : Fragment;
  const toolTipParentProps: any =
    element?.actionConfig?.label || resolvedProps?.toolTip ? { title: element?.actionConfig?.label || resolvedProps?.toolTip } : {};

  if (componentVisibility === "none") {
    return <></>;
  }

  const isContainer = [
    ComponentItemType.FlexContainer,
    ComponentItemType.CustomContainer,
    ComponentItemType.GridContainer,
    ComponentItemType.StepperContainer,
  ].includes(element.type);

  return (
    <PermissibleRender action={["VIEW"]} path={[path, element?.props?.key].join(".")}>
      {({ permitted }) =>
        permitted && (
          <>
            <ActionButton
              _key={componentProps?.key}
              path={[path, element?.props?.key].join(".")}
              queryKeys={queryKeys}
              pageId={pageId}
              viewName={info?.viewName}
              item={item}
              element={element}
              action={element?.actionConfig}
              interactionConfig={element?.interactionConfig}
              handleSubmit={handleSubmit}
              tableId={id}
              actionsMap={element?.actionsMap}
              views={views}
              iconButton={false}
              formData={getValues}
              __data={__data}
              closeModal={closeModal}
              parentIds={parentIds}
              disableInternalAction={disableInternalAction}
              onActionClick={onActionClick}
              disabled={!permitted}
              onSelectRow={handleSelectRow}
              index={index}
              isChainMapped={element?.config?.actionMappingConfig?.isChainMapped}
              actionsKey={element?.config?.actionMappingConfig?.actionsKey}
              isLoadingForEntireChain={element?.config?.actionMappingConfig?.isLoadingForEntireChain}
              stripe={stripe}
              elements={elements}
              currentRepeatedItem={currentRepeatedItem}
            >
              {({ actionHandlerMapper, isLoading }) => (
                <>
                  <OnTriggerHandler
                    element={element}
                    actionHandlerMapper={actionHandlerMapper}
                    pageId={pageId}
                    viewName={props?.info?.viewName}
                    viewsState={viewsState}
                    viewKey={viewKey}
                    refetchDataSource={refetchDataSource}
                    fetchNextPage={fetchNextPage}
                  />
                  <AppController
                    hierarchyName={getHierarchyName(element?.props?.key, parentKey)}
                    name={getControllerName(element)}
                    defaultValue={getControllerDefaultValue(element)}
                    isDisabledDirtyField={props?.isDisabledDirtyField || isContainer}
                    disabled={props?.disablePageDirty}
                    matchValidationField={replaceDataPlaceholders({
                      queryString: element?.props?.matchValue,
                      item,
                      viewsState,
                      pageId,
                      index,
                      __data,
                      env: currentApp?.env,
                      fallback: "",
                      multiLingual: multiLingual,
                    })}
                    validate={validationRules(element, value => {
                      return replaceDataPlaceholders({
                        queryString: value,
                        item,
                        viewsState,
                        pageId,
                        index,
                        __data,
                        env: currentApp?.env,
                        fallback: "",
                        multiLingual: multiLingual,
                      });
                    })}
                    render={({ onChange, value, error }) => (
                      <Box
                        sx={joinObjects(boxStyles, {
                          height: element?.config?.isDynamicHeight
                            ? "auto"
                            : [ComponentItemType.GridContainer].includes(element?.config?.parent?.type)
                            ? "100%"
                            : !element?.config?.isPercentageHeight
                            ? element?.config?.heightPx
                            : element?.config?.heightPercentage,
                          overflow: element?.config?.overflow || "visible",
                          ...((!leftDrawerOpened && element.type === ComponentItemType.BXSideBar) || hiddenByStepper
                            ? { display: "none" }
                            : {}),
                          contentVisibility: componentVisibility,
                          ...(element?.config?.sticky?.enabled
                            ? {
                                position: "sticky",
                                top: element?.config?.sticky?.top,
                                right: element?.config?.sticky?.right,
                                left: element?.config?.sticky?.left,
                                bottom: element?.config?.sticky?.bottom,
                                zIndex: element?.config?.sticky?.zIndex ?? 1200,
                              }
                            : {}),
                        })}
                        aria-invalid={!!error}
                      >
                        <CheckToolTipParent {...toolTipParentProps}>
                          <div style={{ position: "relative", width: "100%", height: "100%" }}>
                            {overlay}
                            {CustomComponent || element?.config?.BxComponent ? (
                              <BXEngine
                                path={
                                  element?.config?.BxComponent
                                    ? [path].filter(Boolean).join(".")
                                    : [path, view?.info?.name].filter(Boolean).join(".")
                                }
                                auth={{}}
                                page={
                                  element?.config?.BxComponent
                                    ? ({ views, layout: [{ id: element?.id, type: element?.type } as any], id: pageId } as any)
                                    : ({ views, layout: view, id: pageId } as any)
                                }
                                inputValidationName={`${info?.viewName || ""}.${componentProps?.key || ""}`}
                                getValues={getValues}
                                error={error}
                                componentName={
                                  !element?.config?.BxComponent ? null : componentData?.[element?.config?.customComponentId]?.name
                                }
                                componentData={
                                  element?.config?.BxComponent
                                    ? {
                                        ...element?.props,
                                        config: element?.configData,
                                        disableApis: false,
                                      }
                                    : componentData?.[element?.config?.customComponentId]
                                }
                                layout={
                                  element?.config?.BxComponent
                                    ? [{ id: element?.id, type: element?.type } as any]
                                    : [{ id: element?.id, type: element?.type } as any]
                                }
                                isVisible
                                disabled={isComponentDisabled}
                                __data={{
                                  ...__data,
                                  [(getLastKeyFromObject(__data) || "") + "#."]: info?.viewName,
                                }}
                                parentIds={[...parentIds, id]}
                                closeModal={closeModal}
                                info={info}
                              />
                            ) : (
                              <>
                                <Component
                                  {...resolvedProps}
                                  appId={currentApp?.id}
                                  config={element?.config}
                                  __config={element?.config}
                                  $views={views}
                                  $parentRef={ref}
                                  $configData={element?.configData}
                                  $pageOutlet={pageOutlet}
                                  $drawerOpen={leftDrawerOpened}
                                  $drawerToggle={handleLeftDrawerToggle}
                                  value={value}
                                  loading={element?.props?.enableLoading ? isLoading : false}
                                  disabled={isComponentDisabled}
                                  metaData={metaData}
                                  pageId={pageId}
                                  info={info}
                                  {...(componentProps?.testId && { id: componentProps?.testId })}
                                  renderId={"renderedView"}
                                  data-bx-key={`${pageId}.${props?.info?.viewName}.${componentProps?.key}`}
                                  data-testid={componentProps?.testId}
                                  onKeyDown={event =>
                                    handleKeyDown(event, element, actionHandlerMapper, pageId, props?.info?.viewName, viewsState)
                                  }
                                  onChange={(event: any, disabledDirty?: boolean) =>
                                    handleChange(
                                      event,
                                      element,
                                      onChange,
                                      actionHandlerMapper,
                                      pageId,
                                      props?.info?.viewName,
                                      viewsState,
                                      disabledDirty
                                    )
                                  }
                                  onClick={event => handleClick(event, element, pageId, props?.info?.viewName, viewsState)}
                                  children={
                                    isContainer ? (
                                      element?.config?.isStripeWrapper ? (
                                        <StripeContainer element={element}>
                                          <ContainerChildren
                                            props={props}
                                            data={element?.children}
                                            path={[path, element?.props?.key].join(".")}
                                            index={index}
                                            parentKey={getHierarchyName(element?.props?.key, parentKey)}
                                            disabled={isComponentDisabled}
                                            element={element}
                                            stripe={stripe}
                                            elements={elements}
                                          />
                                        </StripeContainer>
                                      ) : (
                                        <ContainerChildren
                                          props={props}
                                          data={element?.children}
                                          element={element}
                                          path={[path, element?.props?.key].join(".")}
                                          index={index}
                                          parentKey={getHierarchyName(element?.props?.key, parentKey)}
                                          disabled={isComponentDisabled}
                                        />
                                      )
                                    ) : (
                                      resolvedProps.children
                                    )
                                  }
                                  isLoadingImage
                                  error={!!error}
                                />

                                {!!error && !element?.props?.isChildContainerGroup && (
                                  <FormHelperText error>
                                    {replaceDataPlaceholders({
                                      obj: _.cloneDeep(componentProps),
                                      queryString: error,
                                      validationRules: componentProps,
                                      item,
                                      viewsState,
                                      index,
                                      pageId,
                                      __data,
                                      env: currentApp?.env,
                                      fallback: "",
                                      multiLingual: multiLingual,
                                    })}
                                  </FormHelperText>
                                )}
                              </>
                            )}
                          </div>
                        </CheckToolTipParent>
                      </Box>
                    )}
                  />
                </>
              )}
            </ActionButton>
          </>
        )
      }
    </PermissibleRender>
  );
};
