import { yupResolver } from "@hookform/resolvers/yup";
import { Button, DialogActions, Grid } from "@mui/material";
import React from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useBXContext } from "src/BXEngine/BXContext";
import { ApiInput } from "src/components/ApiInput";
import { formatJSON } from "src/components/BXUI/DataTable/ActionButton";
import { useCallbackPrompt } from "src/hooks/useCallbackPrompt";
import * as yup from "yup";
import OASSelector from "../../../OASSelector";

const schema = yup.object().shape({
  props: yup.object().shape({
    stripePublishableEndpoint: yup.object().shape({
      source: yup.string().required("Field is required"),
    }),
    stripeSecretEndpoint: yup.object().shape({
      source: yup.string().required("Field is required"),
    }),
  }),
});

export const IntentConfigurationModal = ({ onSave, item }) => {
  const { stripeEndpoints, setStripeEndpoints } = useBXContext();
  const { handleSubmit, formState, control, setValue, getValues, watch } = useForm<FieldValues>({
    defaultValues: {
      props: {
        stripePublishableEndpoint: {
          uri: item?.props?.stripePublishableEndpoint?.uri || "",
          source: item?.props?.stripePublishableEndpoint?.source || "",
          method: item?.props?.stripePublishableEndpoint?.method || "GET",
          body: "",
          headers: {},
        },
        stripeSecretEndpoint: {
          uri: item?.props?.stripeSecretEndpoint?.uri || "",
          source: item?.props?.stripeSecretEndpoint?.source || "",
          method: item?.props?.stripeSecretEndpoint?.method || "GET",
          body: "",
          headers: {},
        },
      },
    },
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const { errors } = formState;
  const isDirty = formState.isDirty;
  useCallbackPrompt(isDirty);

  const handleSave = (formData: any) => {
    const payload = {
      ...formData,
    };

    const updatedEndpoints = [...stripeEndpoints];

    if (item?.id) {
      const existingEndpoint = updatedEndpoints.find(ep => ep.id === item.id);

      const newEndpoint = {
        id: item?.id,
        publishableKeyEndpoint: formData?.item?.props?.stripePublishableEndpoint?.source || null,
        clientSecretKeyEndpoint: formData?.item?.props?.stripeSecretEndpoint?.source || null,
      };

      if (existingEndpoint) {
        existingEndpoint.publishableKeyEndpoint = newEndpoint.publishableKeyEndpoint;
        existingEndpoint.clientSecretKeyEndpoint = newEndpoint.clientSecretKeyEndpoint;
      } else {
        updatedEndpoints.push(newEndpoint);
      }

      setStripeEndpoints(updatedEndpoints);
    }

    onSave(formData, item?.props);
  };

  return (
    <React.Fragment>
      <Grid container xs={12} spacing={2}>
        <Grid item xs={12}>
          <ApiInput
            watch={watch}
            getValues={getValues}
            setValue={setValue}
            error={(errors as any).props?.stripePublishableEndpoint}
            errorURL={(errors as any).props?.stripePublishableEndpoint?.source}
            apiLabel='Stripe Publish Key Endpoint'
            control={control}
            path='props.stripePublishableEndpoint'
            pathURL='props.stripePublishableEndpoint.source'
            OASElement={
              <OASSelector
                swaggerProps={{
                  onSuccess: (values: any) => {
                    setValue("props.stripePublishableEndpoint.uri", values.action.source);
                    setValue("props.stripePublishableEndpoint.source", values.action.source);
                    setValue("props.stripePublishableEndpoint.method", values.action.method.toUpperCase());
                    setValue("props.stripePublishableEndpoint.body", formatJSON(values.action.body));
                    setValue("props.stripePublishableEndpoint.headers", values.action.headers);
                  },
                }}
              />
            }
          />
        </Grid>

        <Grid item xs={12}>
          <ApiInput
            watch={watch}
            getValues={getValues}
            setValue={setValue}
            error={(errors as any).props?.stripeSecretEndpoint}
            errorURL={(errors as any).props?.stripeSecretEndpoint?.source}
            apiLabel='Stripe Secret Key Endpoint'
            control={control}
            path='props.stripeSecretEndpoint'
            pathURL='props.stripeSecretEndpoint.source'
            OASElement={
              <OASSelector
                swaggerProps={{
                  onSuccess: (values: any) => {
                    setValue("props.stripeSecretEndpoint.uri", values.action.source);
                    setValue("props.stripeSecretEndpoint.source", values.action.source);
                    setValue("props.stripeSecretEndpoint.method", values.action.method.toUpperCase());
                    setValue("props.stripeSecretEndpoint.body", formatJSON(values.action.body));
                    setValue("props.stripeSecretEndpoint.headers", values.action.headers);
                  },
                }}
              />
            }
          />
        </Grid>

        <Grid item xs={12}>
          <DialogActions style={{ padding: 0, marginTop: 16, justifyContent: "center" }}>
            <Button onClick={handleSubmit(handleSave)} variant='contained' aria-label='save'>
              Save
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
