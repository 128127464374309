import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import classnames from "classnames";
import { FC } from "react";
import { paginationStyles } from "./pagination.style";
import { DOTS, usePagination } from "./usePagination";
const Pagination: FC<any> = props => {
  const classes = paginationStyles(props);

  const {
    onPageChange,
    siblingCount = 1,
    currentPage,
    totalPageCount,
    className,
    hasNextPage,
    isFetching,
    showPrevButton = true,
    showNextButton = true,
    pageType,
    maxPrev,
    isPaginationBar,
    lengthData,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    siblingCount,
    totalPageCount,
    hasNextPage,
    pageType,
    onPageChange,
    maxPrev,
    isPaginationBar,
    lengthData,
  });

  if (!paginationRange?.length) {
    return null;
  }

  const onNext = () => {
    if (!isFetching && hasNextPage) {
      onPageChange(currentPage + 1);
    }
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange?.[paginationRange?.length - 1];

  return (
    <ul className={classnames(classes.paginationContainer, { [className]: className })}>
      {showPrevButton && (
        <li
          className={classnames(classes.paginationItem, {
            disabled: currentPage === 1 || (currentPage === lastPage && isFetching),
          })}
          onClick={onPrevious}
        >
          <KeyboardArrowLeftOutlinedIcon color={"primary"} />
        </li>
      )}

      {paginationRange?.map((pageNumber: any, index: number) => {
        if (pageNumber === DOTS) {
          return (
            <li key={`${pageNumber}-${index}`} className={classnames(classes.paginationItem, "dots")}>
              &#8230;
            </li>
          );
        }

        return (
          <li
            key={`${pageNumber}-${index}`}
            className={classnames(classes.paginationItem, {
              selected: pageNumber === currentPage,
            })}
            onClick={() => {
              onPageChange(pageNumber);
            }}
          >
            {pageNumber}
          </li>
        );
      })}
      {showNextButton && (
        <li
          className={classnames(classes.paginationItem, {
            disabled: currentPage === lastPage || (currentPage == paginationRange?.[paginationRange?.length - 2] && lastPage === "..."),
          })}
          onClick={onNext}
        >
          <KeyboardArrowRightOutlinedIcon color={"primary"} />
        </li>
      )}
    </ul>
  );
};

export default Pagination;
