import * as acorn from "acorn";
import { useEffect } from "react";
import { useBXContext } from "src/BXEngine/BXContext";

const RenderScripts = () => {
  const { currentApp, isAdministrationMode, fqdnApp } = useBXContext();

  function isValidJS(code: string): boolean {
    try {
      acorn.parse(code, { ecmaVersion: 2020 });
      return true;
    } catch (e) {
      return false;
    }
  }
  useEffect(() => {
    if (!currentApp?.appConfig?.script && !fqdnApp?.appConfig?.script) return;
    if (isAdministrationMode) return;

    const app = currentApp || fqdnApp;
    const addedScripts: HTMLScriptElement[] = [];
    const addedNoscripts: HTMLElement[] = [];

    const headScriptsString = app?.appConfig?.script?.head || "";
    const bodyNoscriptsString = app?.appConfig?.script?.body || "";

    // Use DOMParser to extract multiple <script> tags from the head string
    const parser = new DOMParser();
    const headDoc = parser.parseFromString(headScriptsString, "text/html");
    const scriptTags = headDoc.querySelectorAll("script");

    // Append <script> tags to head
    scriptTags.forEach((scriptTag: any) => {
      const newScript = document.createElement("script");

      // Copy attributes and content from the original script
      for (const attr of scriptTag.attributes) {
        newScript.setAttribute(attr.name, attr.value);
      }
      if (scriptTag.text) {
        newScript.text = scriptTag.text;
      }

      // Append to head
      if (isValidJS(scriptTag.text)) {
        document.head.appendChild(newScript);
        addedScripts.push(newScript);
      }
    });

    // Use Regex to extract multiple <noscript> tags from the body string
    const noscriptRegex = /<noscript>([\s\S]*?)<\/noscript>/gi;
    let match;
    const noscriptContents: any = [];
    while ((match = noscriptRegex.exec(bodyNoscriptsString)) !== null) {
      noscriptContents.push(match[1]);
    }

    // Append <noscript> tags to body
    noscriptContents.forEach(content => {
      const newNoscript = document.createElement("noscript");
      newNoscript.innerHTML = content;

      // Append to body
      document.body.appendChild(newNoscript);
      addedNoscripts.push(newNoscript);
    });

    // Cleanup function to remove scripts when component unmounts or currentApp changes
    return () => {
      addedScripts.forEach(script => {
        if (document.head.contains(script)) {
          document.head.removeChild(script);
        }
      });
      addedNoscripts.forEach(noscript => {
        if (document.body.contains(noscript)) {
          document.body.removeChild(noscript);
        }
      });
    };
  }, [currentApp, fqdnApp, isAdministrationMode]);

  return null;
};

export default RenderScripts;
