/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-loop-func */
/* eslint-disable no-loop-func */
import { gql } from "@apollo/client";
import { LoadingButton } from "@mui/lab";
import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { AddressElement, CardCvcElement, CardExpiryElement, CardNumberElement, PaymentElement } from "@stripe/react-stripe-js";
import { IconX } from "@tabler/icons-react";
import _ from "lodash";
import { SnackbarContent } from "notistack";
import Papa from "papaparse";
import { FC, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { isHostAvailable } from "src/App";
import { BXEngine } from "src/BXEngine";
import { apolloClient, encodeURLParams, replaceBaseUrl, useBXContext } from "src/BXEngine/BXContext";
import { BXIcon } from "src/components/BXUI/Icon";
import { produceTrigger, resetLoading, setLoading } from "src/features/appState/appStateSlice";
import { useAppState } from "src/features/appState/hooks";
import useAuth from "src/hooks/useAuth";
import { IViewState, UIElement } from "src/types/UIElement";
import { closeSnackbarRef, enqueueSnackbarRef } from "src/utils/SnackbarUtilsConfigurator";
import axiosServices from "src/utils/axios";
import extAxiosServices from "src/utils/axiosExt";
import { getAuthorizationHeader, getLastKeyFromObject, handleRefetchQueries, reverseDataOfObjectValues } from "src/utils/generalUtils";
import { actionTypes } from "src/views/pages/BuildX/AppBuilder/forms/CreateViewForm";
import { getSharedViews, updateStepperGroupIndex } from "src/views/pages/BuildX/FormBuilder/utils";
import { v4 as uuid } from "uuid";
import * as XLSX from "xlsx";
import { BXConfirmationDialog } from "../AlertDialog/ConfirmationDialog";
import { triggerActionableComponent } from "../FormBuilder/RenderItems";
import BXModal from "../Modal";
import { ViewerModal } from "../viewerModal";
import CSVProgressDialog from "./CSVProgressDialog";
import CreatePayload from "./CreatePayload";

export const useReplaceDataPlaceholders = (props?: any) => {
  const { viewName } = props || {};

  const { viewsState, setViewsState, queriesStateGraphQL } = useBXContext();
  const { getValues, setValue, watch, getValue, getError, getDirty, getIsDirty, deleteValue, watchError } = useAppState();

  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  location.pathname = window.location.pathname;
  location.state = location.state;
  const searchParams = new URLSearchParams(location.search);

  const queryStrings = {};
  searchParams.forEach((value, key) => {
    queryStrings[key] = value;
  });

  const replaceDataPlaceholdersProps = {
    queriesStateGraphQL,
    viewsState,
    viewName,
    setViewsState,
    getValues,
    getValue,
    setValue,
    deleteValue,
    getError,
    getDirty,
    getIsDirty,
    watch,
    watchError,
    navigate,
    location: { params, queryStrings, pathname: location.pathname, state: location.state, url: window.location.href },
  };

  return {
    replaceDataPlaceholders: props => replaceDataPlaceholders({ ...props, ...replaceDataPlaceholdersProps }),
    replaceDataPlaceholdersRecursively: props => replaceDataPlaceholdersRecursively({ ...props, ...replaceDataPlaceholdersProps }),
    updateDataPlaceholders: props => updateDataPlaceholders({ ...props, ...replaceDataPlaceholdersProps }),
  };
};

export const updateDataPlaceholders = (props: any) => {
  const { queryString, pageId, setValue, index: itemIndex, viewName, $action, $payload, navigate, location, watch, deleteValue } = props;

  let result = queryString;

  if (typeof queryString === "boolean" || typeof queryString === "number") return queryString;
  const placeholders = queryString?.match(/\{(.*?)\}/g);

  placeholders?.forEach(function (placeholder: any) {
    let phText = "";

    const index = placeholder.split("").findIndex((key: string) => key == ".");
    phText = placeholder.substring(index + 1, placeholder.length - 1);

    if (phText.startsWith("state.")) {
      let preDefinedVariable = placeholder.substring(1, index);
      if (preDefinedVariable === "this") {
        preDefinedVariable = viewName;
      }
      phText = phText.replace("state.", "");

      if ($action === "DELETE") {
        phText = phText.replace("[*]", "");

        const key = `${pageId}.${preDefinedVariable}.${phText}`;

        const data = [...watch(key)];

        if (key.endsWith(".value")) {
          const elementKey = key.replace(".value", "");
          const children = watch(`${elementKey}.children`);
          if (Array.isArray(children)) {
            children.forEach(child => {
              const deleteKey = `${pageId}.${viewName}.${child}-${itemIndex}`;
              deleteValue(deleteKey);
            });
          }
        }
        if (Array.isArray(data)) {
          const indexToRemove = data.findIndex(item => item.index === itemIndex);
          if (indexToRemove !== -1) {
            data.splice(indexToRemove, 1);
          }
        }

        setValue(`${key}`, data, false, true);
      } else if ($action === "ADD") {
        phText = phText.replace("[*]", `[${itemIndex}]`);
        const key = `${pageId}.${preDefinedVariable}.${phText}`;
        const data = [...watch(key)];
        if (Array.isArray(data)) {
          const maxIndex = data.length > 0 ? Math.max(...data.map(item => item.index)) : -1;
          data.push($payload ?? { index: maxIndex + 1 });
        }
        setValue(`${key}`, data, false, true);
      } else if ($action === "UPDATE") {
        phText = phText.replace("[*]", `[${itemIndex}]`);
        const key = `${pageId}.${preDefinedVariable}.${phText}`;
        const currentValues = watch(key);
        if (Array.isArray(currentValues)) {
          const updatedValues = [...currentValues];
          updatedValues[itemIndex] = $payload;
          setValue(`${key}.state`, updatedValues, false, true);
        } else {
          setValue(`${key}.state`, $payload, false, true);
        }
      }
    } else if (phText.startsWith("location.")) {
      let preDefinedVariable = placeholder.substring(1, index);
      if (preDefinedVariable === "this") {
        preDefinedVariable = viewName;
      }
      phText = phText.replace("location.state", "");
      if ($action === "UPDATE") {
        const currentState = typeof location.state === "object" && location.state !== null ? location.state : {};

        if (phText) {
          phText = phText.startsWith(".") ? phText.slice(1) : phText;
          navigate(location.pathname, {
            state: {
              ...currentState,
              [phText]: $payload,
            },
          });
        } else {
          navigate(location.pathname, {
            state: $payload,
          });
        }
      }
    } else if (phText.startsWith("localStorage.")) {
      let preDefinedVariable = placeholder.substring(1, index);
      if (preDefinedVariable === "browser" && $action === "UPDATE") {
        phText = phText.replace("localStorage.", "");
        if (typeof $payload == "object") {
          localStorage.setItem(phText, JSON.stringify($payload));
        } else {
          localStorage.setItem(phText, $payload);
        }
      } else if (preDefinedVariable === "browser" && $action === "DELETE") {
        phText = phText.replace("localStorage.", "");
        localStorage.removeItem(phText);
      }
    } else if (phText.startsWith("location.")) {
      let preDefinedVariable = placeholder.substring(1, index);
      if (preDefinedVariable === "this") {
        preDefinedVariable = viewName;
      }
      phText = phText.replace("location.state", "");
      if ($action === "UPDATE") {
        const currentState = typeof location.state === "object" && location.state !== null ? location.state : {};

        if (phText) {
          phText = phText.startsWith(".") ? phText.slice(1) : phText;
          navigate(location.pathname, {
            state: {
              ...currentState,
              [phText]: $payload,
            },
          });
        } else {
          navigate(location.pathname, {
            state: $payload,
          });
        }
      }
    } else if (phText.startsWith("localStorage.")) {
      let preDefinedVariable = placeholder.substring(1, index);
      if (preDefinedVariable === "browser" && $action === "UPDATE") {
        phText = phText.replace("localStorage.", "");
        if (typeof $payload == "object") {
          localStorage.setItem(phText, JSON.stringify($payload));
        } else {
          localStorage.setItem(phText, $payload);
        }
      } else if (preDefinedVariable === "browser" && $action === "DELETE") {
        phText = phText.replace("localStorage.", "");
        localStorage.removeItem(phText);
      }
    }
  });

  return result;
};

function getIgnoredStepperPages(stepperGroups: any) {
  const result: string[] = [];
  for (const groupId in stepperGroups) {
    const group = stepperGroups[groupId];
    const worksWith = group.worksWith;
    const selectedPage = group.selectedPage;
    const pages = group.pages;

    if (worksWith === "customPages") {
      for (const page of pages) {
        if (page.customPageSelected === false) {
          result.push(page.pageReference);
        }
      }
    } else if (worksWith === "currentPage") {
      for (let i = 0; i < pages.length; i++) {
        const page = pages[i];
        let currentPageIndex = _.toNumber(selectedPage || 0);

        if (_.isNaN(currentPageIndex)) {
          currentPageIndex = _.findIndex(group.pages, (page: any) => page.pageReference === group.selectedPage);
        }
        currentPageIndex = _.clamp(currentPageIndex, 0, pages?.length - 1);

        if (i !== currentPageIndex) {
          result.push(page.pageReference);
        }
      }
    } else if (worksWith === "allPages") {
      continue;
    }
  }
  return result;
}

function concat(...args) {
  return args.join("");
}

function urlEncode(input) {
  return encodeURIComponent(input);
}

function contrast(hexColor1: string, hexColor2: string): number {
  const Color = require("color");
  const backgroundColor = Color(hexColor1);
  const fontColor = Color(hexColor2);

  const contrastRatio = backgroundColor.contrast(fontColor);
  return contrastRatio;
}

function buildLinks(texts: string[], urls: string[], separator: string = ""): string {
  const anchors = texts.map((text, index) => {
    if (!text) return "";
    return urls?.[index] ? `<a href="${urls[index]}">${text}</a>` : text;
  });
  return anchors.filter(Boolean).join(separator);
}

function join(array, delimiter) {
  return array.join(delimiter);
}

function evaluateExpression(expression) {
  if (typeof expression !== "string") return expression;

  const operations = {
    "=concat": concat,
    "=urlEncode": urlEncode,
    "=contrast": contrast,
    "=buildLinks": buildLinks,
    "=join": join,
    "=eval": eval,
  };

  const evalExpr = expr => {
    for (const [prefix, func] of Object.entries(operations)) {
      if (expr.startsWith(prefix)) {
        return eval(expr.replace(prefix, func.name));
      }
    }
    return expr;
  };

  expression = expression.replace(/\{=(.*?)\}/g, (_, exprContent) => {
    try {
      return evalExpr(`=${exprContent}`);
    } catch {
      return "";
    }
  });

  if (expression.startsWith("=")) {
    try {
      return evalExpr(expression);
    } catch {
      return "";
    }
  }

  return expression;
}

export const replaceDataPlaceholders = (props: any) => {
  const {
    queryString,
    item = {},
    viewsState,
    watch,
    watchError,
    getDirty,
    getIsDirty,
    getValue,
    pageId,
    formData,
    __data = {},
    fallback,
    actionResponse,
    dnd,
    env,
    pagination,
    multiLingual,
    validationRules,
    index: itemIndex,
    queriesStateGraphQL,
    viewName,
    ...rest
  } = props;

  let result = queryString;
  const mapObj = {
    data: item,
    pagination,
    actionResponse,
    dnd,
    ...rest,
  };

  if (typeof queryString === "boolean" || typeof queryString === "number" || queryString == undefined) return queryString;
  const placeholders = queryString?.match?.(/\{(?!=)(.*?)\}/g);

  placeholders?.forEach(function (placeholder: any) {
    let phText = "";
    let data;

    const index = placeholder.split("").findIndex((key: string) => key == ".");
    phText = placeholder.substring(index + 1, placeholder.length - 1);

    const currentStepperGroups = getValue?.(`${pageId}.${viewName}._BX_stepperGroups`);
    let componentPath = "";
    const ignoredStepperPages = getIgnoredStepperPages(currentStepperGroups);
    const stepperGroups = _.reduce(
      currentStepperGroups ?? {},
      (result, group) => {
        if (!group || typeof group !== "object") {
          return result;
        }

        const parsedSelectedIndex = parseInt(group?.selectedPage, 10);
        const selectedIndex = isNaN(parsedSelectedIndex)
          ? group?.pages?.findIndex(page => page.pageReference === group?.selectedPage) ?? -1
          : parsedSelectedIndex;

        const selectedPageRef = group?.pages?.[selectedIndex]?.pageReference;

        result[group?.groupName] = {
          index: selectedIndex,
          ref: selectedPageRef,
        };

        return result;
      },
      {}
    );

    data = mapObj;
    if (placeholder.startsWith("{browser.localStorage.")) {
      phText = phText.replace(`localStorage.`, "");
      data = localStorage.getItem(phText);
      phText = "";
    } else if (placeholder.startsWith("{this.dataComp.")) {
      phText = phText.replace(`dataComp.`, "");
      const key = phText.match(/^([^.]+)/)?.[0];
      phText = phText.replace(`${key}`, "");
      if (phText?.startsWith(".")) {
        phText = phText.replace(".", "");
      }
      componentPath = "";
      data = watch(`${pageId}.${viewName}.${key}.data`);

      if (!_.isNil(itemIndex)) {
        data = data?.[itemIndex];
      }
    } else if (placeholder.startsWith("{this.response.")) {
      data = viewsState?.[`${pageId}-${viewName}`]?.response;
      phText = phText.replace(`response.`, "");
      componentPath = "";
    } else if (placeholder.startsWith("{this.state.")) {
      let currentState = watch(`${pageId}.${viewName}`);
      data = { ...currentState, ...(stepperGroups || {}) };
      phText = phText.replace(`state.`, "");
      const keys = phText.split(".");
      componentPath = _.has(data, `${keys?.[0]}.state`) ? ".state" : "";

      const baseKey = keys[0].split("[")[0];

      if (_.has(data, `${baseKey}.leafChildren`) && !["maxItems", "value"].includes(keys[1])) {
        let tokens: any = [];
        let regex = /([^\.\[\]]+)|\[(\d+)\]/g;
        let match;
        while ((match = regex.exec(phText)) !== null) {
          if (match[1]) {
            tokens.push(match[1]);
          } else if (match[2]) {
            tokens.push(parseInt(match[2], 10));
          }
        }

        let currentData = data;
        let i = 0;
        while (i < tokens.length) {
          let token: any = tokens[i];

          if (typeof token === "string" && _.has(currentData, `${token}.leafChildren`)) {
            const repeatedItem = currentData[token];
            i++;
            let nextToken = tokens[i];

            if (typeof nextToken === "number") {
              let index = nextToken;
              i++;
              let property = tokens[i];
              if (typeof property === "string") {
                i++;
              } else {
                property = null;
              }

              const arrObj = {};
              repeatedItem?.leafChildren?.forEach(child => {
                const childState = _.get(data, `${child}-${repeatedItem?.value?.[index]?.index}.state`, null);
                _.set(arrObj, child, childState);
              });
              currentData = property ? arrObj[property] : arrObj;
            } else if (typeof nextToken === "string") {
              let prop = nextToken;
              i++;

              const dataArr: any = [];
              for (let j = 0; j < repeatedItem?.value?.length; j++) {
                const arrObj: any = {};
                repeatedItem?.leafChildren?.forEach(child => {
                  const childState = _.get(data, `${child}-${repeatedItem?.value?.[j]?.index}.state`, null);
                  _.set(arrObj, child, childState);
                });
                dataArr.push(arrObj[prop]);
              }
              currentData = dataArr;
            } else {
              const dataArr: any = [];
              for (let j = 0; j < repeatedItem?.value?.length; j++) {
                const arrObj = {};
                repeatedItem?.leafChildren?.forEach(child => {
                  const childState = _.get(data, `${child}-${repeatedItem?.value?.[j]?.index}.state`, null);
                  _.set(arrObj, child, childState);
                });
                dataArr.push(arrObj);
              }
              currentData = dataArr;
            }
          } else {
            if (currentData === null || currentData === undefined) {
              currentData = undefined;
              break;
            }

            if (Array.isArray(currentData)) {
              if (typeof token === "number") {
                currentData = currentData[token];
                i++;
              } else if (typeof token === "string") {
                currentData = _.map(currentData, token);
                i++;
              }
            } else if (typeof currentData === "object") {
              currentData = currentData[token];
              i++;
            } else {
              currentData = undefined;
              break;
            }
          }
        }

        phText = "";
        data = currentData;
        componentPath = "";
      }
    } else if (placeholder.startsWith("{this.error.")) {
      phText = phText.replace(`error.`, "");
      const error = watchError(`${pageId}.${viewName}.${phText}`, ignoredStepperPages);
      phText = "";
      data = `${error}`;
    } else if (placeholder.startsWith("{this.dirty.")) {
      if (placeholder === "{this.dirty._page}") {
        const isDirtyPage = getIsDirty(pageId);
        phText = "";
        data = `${isDirtyPage}`;
      } else {
        phText = phText.replace(`dirty.`, "");
        const dirty = getDirty(`${pageId}.${viewName}.${phText}`, ignoredStepperPages);
        phText = "";
        data = `${dirty}`;
      }
    } else if (placeholder.startsWith("{this.confirmation.")) {
      data = viewsState?.[`${pageId}-${viewName}`]?.confirmation;
      phText = phText.replace("confirmation.", "");
    } else if (placeholder.startsWith("{$.")) {
      data = formData?.();
    } else if (placeholder.startsWith("{%@.")) {
      const validationKey = phText;
      if (validationRules?.[validationKey]) {
        data = validationRules;
        phText = validationKey;
      }
    } else if (placeholder.startsWith("{i18n.")) {
      const translationKey = phText;
      const translationObject = multiLingual?.translations?.find((t: any) => t.key === translationKey);

      const defaultLanguage = multiLingual?.selectedLanguage;
      const selectedLanguage = defaultLanguage ? defaultLanguage : "";

      data = translationObject?.allLanguages;
      phText = selectedLanguage;
    } else if (placeholder.startsWith("{{_name")) {
      phText = placeholder.substring(2, placeholder.length - 1);
      data = { _name: env?.name };
    } else if (placeholder.startsWith("{{")) {
      phText = placeholder.substring(2, placeholder.length - 1);
      data = {
        ...env?.config?.variables,
        ...env?.upConfig?.variables,
      };
    } else if (placeholder.startsWith("{#.")) {
      const hashes = Object.keys(__data)?.reverse();
      phText = placeholder.substring(1, placeholder.length - 1);

      const currentHash = hashes?.find(hash => phText.startsWith(hash)) as any;

      if (!currentHash) return;

      const viewName = reverseDataOfObjectValues(__data)[currentHash];

      phText = phText.replace(currentHash, "");
      data = viewsState?.[`${pageId}-${viewName}`];

      if (phText.startsWith("data.")) {
        phText = phText.replace("data.", "");
        data = data?._selectedItem;
      }
    } else if (!placeholder.startsWith("{this.")) {
      let preDefinedVariable = placeholder.substring(1, index);
      if (placeholder.startsWith(`{${preDefinedVariable}.data._selectedItem`)) {
        phText = phText.replace("data._selectedItem.", "");
        data = viewsState?.[`${pageId}-${preDefinedVariable}`]?._selectedItem;
      } else if (phText.startsWith("dataComp.")) {
        let preDefinedVariable = placeholder.substring(1, index);

        phText = phText.replace(`dataComp.`, "");
        const key = phText.match(/^([^.]+)/)?.[0];
        phText = phText.replace(`${key}`, "");
        if (phText?.startsWith(".")) {
          phText = phText.replace(".", "");
        }
        componentPath = ".data";
        data = watch(`${pageId}.${preDefinedVariable}.${key}`);
      } else if (placeholder.startsWith(`{${preDefinedVariable}.data`)) {
        phText = phText.replace(`data.`, "");
        const queryKey = `${pageId}.${preDefinedVariable}.data`;
        data = watch(`${queryKey}`);
      } else if (phText.startsWith("state.subviews")) {
        let preDefinedVariable = placeholder.substring(1, index);
        phText = phText.replace("state.subviews", "");

        let subview = phText.substring(phText.indexOf(".") + 1);

        let finalData = watch(`${pageId}.${preDefinedVariable}`);

        if (subview) {
          finalData = {
            ...finalData,
            [preDefinedVariable]: finalData?.[preDefinedVariable]?.map(item => item?.[subview]),
          };
        }

        data = finalData;
        phText = `${subview}`;
      } else if (phText.startsWith("state.")) {
        componentPath = ".state";
        let preDefinedVariable = placeholder.substring(1, index);
        phText = phText.replace(`state.`, "");
        data = { ...watch(`${pageId}.${preDefinedVariable}`), ...(stepperGroups || {}) };
      } else {
        let preDefinedVariable = placeholder.substring(1, index);
        phText = phText.replace(`${preDefinedVariable}.`, "");
        data = viewsState?.[`${pageId}-${preDefinedVariable}`];
      }
    }

    phText = phText.replace("[*]", `[${itemIndex}]`);
    let replacedValue: any = null;
    if (phText.includes("[]")) {
      const prefix = phText.substring(0, phText.indexOf("[]"));
      const suffix = phText.substring(phText.indexOf("[].") + 3, phText.length);

      if (_.isArray(_.get(data, prefix))) {
        replacedValue = _.get(data, prefix)?.map(item => _.get(item, suffix));
      }
    } else {
      if (data && !phText) {
        replacedValue = data;
      } else {
        let newPhTextIndex = phText.indexOf(".");
        let newPhText = `${phText}${componentPath}`;
        if (newPhTextIndex > 0) {
          newPhText = `${phText.substring(0, newPhTextIndex)}${componentPath}${phText.substring(newPhTextIndex)}`;
        }

        let pathParts = newPhText.split(".");
        let lastPart = pathParts.pop();
        let parentPath = pathParts.join(".");

        let parentData = _.get(data, parentPath);

        if (_.isArray(parentData)) {
          replacedValue = _.map(parentData, lastPart);
        } else {
          replacedValue = !_.isNil(_.get(data, newPhText)) ? _.get(data, newPhText) : fallback;
        }
      }
    }
    if (typeof replacedValue == "object") {
      const regex = /^\{[^{}]*\}$/;
      if (regex.test(result)) {
        result = replacedValue;
      } else {
        result = result.replace(placeholder.startsWith("{{") ? placeholder + "}" : placeholder, JSON.stringify(replacedValue));
      }
    } else {
      result = result.replace(placeholder.startsWith("{{") ? placeholder + "}" : placeholder, replacedValue);
    }
  });

  return evaluateExpression(result);
};

export const replaceDataPlaceholdersRecursively = ({
  item,
  viewsState,
  pageId,
  formData,
  __data,
  multiLingual,
  env,
  obj = {},
  ...rest
}: any) => {
  Object.keys?.(obj ?? {})?.forEach?.(key => {
    let template = obj?.[key];
    if (typeof obj[key] === "object" && obj[key] !== null) {
      return replaceDataPlaceholdersRecursively({
        obj: obj[key],
        item,
        viewsState,
        pageId,
        env,
        formData,
        multiLingual,
        __data,
        ...rest,
      });
    }
    obj[key] = replaceDataPlaceholders({
      queryString: template,
      item,
      viewsState,
      pageId,
      formData,
      __data,
      multiLingual,
      env,
      ...rest,
    });
  });

  return obj;
};

export const formatJSON = (val = {}) => {
  try {
    const res = JSON.parse(val as any);
    return JSON.stringify(res, null, 2);
  } catch {
    return undefined;
  }
};

export const ActionButton: FC<{
  actionsMap?: any;
  action?: any;
  pageId?: any;
  onStateChange?: (newState: string) => void;
  disabled?: boolean;
  item: any;
  isUserInput?: boolean;
  views?: UIElement[];
  onSelectRow?: any;
  tableId: any;
  handleSubmit?: any;
  iconButton?: boolean;
  formData?: any;
  fullWidth?: boolean;
  variant?: string;
  __data?: any;
  closeModal?: any;
  parentIds?: any;
  isDismissibleView?: string;
  queryKeys?: any[];
  withBorder?: boolean;
  isResetEnabled?: boolean;
  viewName?: string;
  disableInternalAction?: boolean;
  onActionClick?: any;
  entries?: any;
  path?: string;
  selectedRows?: any;
  _key?: any;
  components?: any;
  index?: any;
  isChainMapped?: any;
  actionsKey?: any;
  interactionConfig?: any;
  tableAction?: any;
  isLoadingForEntireChain?: boolean;
  element?: any;
  stripe?: any;
  elements?: any;
  conditionKeyFromTable?: any;
  currentRepeatedItem?: any;
}> = props => {
  const {
    actionsMap = {},
    onStateChange = () => {},
    disabled = false,
    item,
    isUserInput,
    views,
    onSelectRow,
    pageId,
    tableId,
    handleSubmit,
    formData,
    parentIds,
    iconButton = true,
    fullWidth = false,
    variant = "contained",
    __data = {},
    closeModal = () => {},
    withBorder = true,
    queryKeys = [],
    viewName,
    disableInternalAction,
    onActionClick,
    entries,
    selectedRows,
    path,
    children,
    _key,
    index,
    isChainMapped,
    actionsKey,
    interactionConfig,
    tableAction,
    isLoadingForEntireChain,
    action,
    conditionKeyFromTable,
    element,
    stripe,
    elements,
    currentRepeatedItem,
  } = props;
  const actionId = tableAction?.action ? _key : element?.id;
  const [open, setOpen] = useState(false);
  const [jsonProgress, setJsonProgress] = useState<any[]>([]);
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const [proceedToNextAction, setProceedToNextAction] = useState<any>({});
  const [actionModal, setActionModal] = useState<any>();
  const [showModalForAction, setShowModalForAction] = useState<boolean>(false);

  const [isViewActionTriggered, setIsViewActionTriggered] = useState<boolean>(false);
  const [actionView, setActionView] = useState<any>();
  const [selectedView, setSelectedView] = useState<any>();

  let cancelWorkflow = false;
  let isConfirmationDenied = false;
  let dirtyConfirmationDenied = false;
  let validationError = false;
  const { palette } = useTheme();
  const navigate = useNavigate();
  const ref = useRef<any>({});
  const { resetState, removeDirty, watch, setValue, getValue, getValues, clearValidation, runAllValidations, getDirty } = useAppState();

  const {
    currentApp,
    currentProfileId,
    viewsState,
    setViewStacks,
    queriesStateGraphQL,
    setViewsState,
    loadingViews,
    setLoadingViews,
    loginToApp,
    registerAppDevice,
    fqdnApp,
    //Manages the state and control flow for action confirmation between different actions,
    //including dialog data, confirmation, and cancellation logic.
    isConfirmAction,
    setIsConfirmAction,
    actionConfirmControl,
    setActionConfirmControl,
    unprotectedPages,
    logoutOfApp,
    setCurrentProfileId,
    setAppProfiles,
  } = useBXContext();

  const { isLoggedIn, setUserAuth } = useAuth();

  const dispatch = useDispatch();

  const { replaceDataPlaceholders, replaceDataPlaceholdersRecursively, updateDataPlaceholders } = useReplaceDataPlaceholders({ viewName });

  const [isLoading, setIsLoading] = useState(false);

  //Condition to evaluate for action button in table
  const data = replaceDataPlaceholders({
    queryString: tableAction?.condition,
    item,
    viewsState,
    pageId,
    __data,
    env: currentApp?.env,
    selectedRows,
    index,
  });

  try {
    if (!eval(data) && !_.isEmpty(data)) return <></>;
  } catch (error) {
    return <></>;
  }

  const checkUnprotected = path => {
    const pathSlugs = path?.split(".").slice(0, 3) || [];

    if (pathSlugs?.length < 3) {
      return true;
    }
    const pagePath = pathSlugs?.join(".");
    return unprotectedPages.current.has(pagePath as string);
  };

  const handleCustomMessage = (action, keyMessage, statusMessage) => {
    let statusMessagesIndex;
    let mapValuesObjectsMessages;
    let condition;
    let customMessage;

    if (keyMessage) {
      statusMessagesIndex = action?.statusMessages?.findIndex((item: any) => item?.key == keyMessage);

      if (statusMessagesIndex !== -1 && action.statusMessages[statusMessagesIndex]) {
        mapValuesObjectsMessages = action.statusMessages[statusMessagesIndex].mapValuesObjectsMessage.values || [];
        condition = action.statusMessages[statusMessagesIndex].mapValuesObjectsMessage.condition || "equal";
        customMessage = mapValuesObjectsMessages.default;

        if (mapValuesObjectsMessages) {
          if (condition === "equal" && mapValuesObjectsMessages[statusMessage]) {
            customMessage = mapValuesObjectsMessages[statusMessage];
          } else if (condition === "startWith") {
            const matchingKey = Object.keys(mapValuesObjectsMessages).find(key => statusMessage.startsWith(key));
            if (matchingKey) {
              customMessage = mapValuesObjectsMessages[matchingKey];
            }
          }
        }
      }
    }

    return customMessage;
  };

  const handleOnSuccess = async (action, response, variables) => {
    const { data, request, status } = response;
    setLoadingViews((prev: any) => {
      const updatedViews = { ...prev };
      updatedViews[`${pageId}-${viewName}`] = false;
      return updatedViews;
    });
    if (_key) {
      setViewsState((prev: any) => {
        _.set(prev, `${pageId}-${viewName}.response.${_key}`, { ...data, requestStatusCode: status });

        return prev;
      });
    }

    if (tableAction?.action && action?.isResetEnabled) {
      resetState(`${pageId}.${viewName}`);
    }

    if (variables?.isCsv) {
      variables?.then?.();
      setJsonProgress(prevProgress =>
        prevProgress.map(item => {
          if (item.id === variables?.csvRow?.id) {
            return { ...item, status: "Success" };
          }
          return item;
        })
      );
      onStateChange("normal");
      return;
    }

    const findKey = action?.statusMessages?.find((item: any) => item?.key == request?.status);
    const statusMessage = findKey?.value;
    const keyMessage = findKey?.key;
    const statusMessageReplace = replaceDataPlaceholders({
      queryString: statusMessage,
      item,
      viewsState,
      pageId,
      formData,
      __data,
      actionResponse: data,
      env: currentApp?.env,
      selectedRows,
      index,
    });

    let customMessage = handleCustomMessage(action, keyMessage, statusMessageReplace);

    customMessage = replaceDataPlaceholders({
      queryString: customMessage,
      item,
      viewsState,
      pageId,
      formData,
      __data,
      actionResponse: data,
      env: currentApp?.env,
      selectedRows,
      index,
    });

    if (action?.showSnackbar ?? true) {
      enqueueSnackbarRef?.(customMessage || statusMessageReplace || "Posted Successfully", {
        variant: "success",
      });
    }

    if (action?.isDismissibleView != "No") {
      closeModal?.(data, false);
    }
    const isGraphQL = action?.isGraphQL || false;
    const key = `${pageId}-${viewName}`;
    const viewConfig = views?.find(view => view?.info?.viewName === viewName);
    if (viewConfig && viewConfig.dataSource?.sourceType != "NONE") {
      handleRefetchQueries({
        isGraphQL,
        queriesStateGraphQL,
        key,
        queryKeys,
        dispatch,
      });
    }

    if (parentIds) {
      parentIds.forEach((id: any) => {
        handleRefetchQueries({
          isGraphQL,
          queriesStateGraphQL,
          key,
          queryKeys: [id],
          dispatch,
        });
      });
    }

    if (tableAction?.action && action?.refreshActionView) {
      handleRefreshViews(tableAction?.action);
    }

    onStateChange("normal");
    // success: remove item from data
    if (action?.method.toLowerCase() === "delete") {
    } else if (action?.method.toLowerCase() === "post") {
      // update record because it is an item action
    } else if (action?.method.toLowerCase() === "put") {
      // update record because it is an item action
    } else if (action?.method.toLowerCase() === "patch") {
      // update record because it is an item action
    }
  };

  const handleOnError = (action, error, variables) => {
    setLoadingViews((prev: any) => {
      const updatedViews = { ...prev };
      updatedViews[`${pageId}-${viewName}`] = false;
      return updatedViews;
    });
    if (_key) {
      setViewsState((prev: any) => {
        _.set(prev, `${pageId}-${viewName}.response.${_key}`, error);
        return prev;
      });
    }

    if (variables?.isCsv) {
      variables?.then?.();
      setJsonProgress(prevProgress =>
        prevProgress.map(item => {
          if (item.id === variables?.csvRow?.id) {
            return {
              ...item,
              status: "Error",
              __error: error,
            };
          }
          return item;
        })
      );
      onStateChange("normal");
      return;
    }
    onStateChange("normal");
    const findKey = action?.statusMessages?.find((item: any) => item?.key == error?.requestStatusCode);
    const statusMessage = findKey?.value;
    const keyMessage = findKey?.key;
    const errorMessage = replaceDataPlaceholders({
      queryString: statusMessage,
      item,
      viewsState,
      pageId,
      formData,
      __data,
      actionResponse: error,
      env: currentApp?.env,
      selectedRows,
      index,
    });

    let customMessage = handleCustomMessage(action, keyMessage, errorMessage);
    customMessage = replaceDataPlaceholders({
      queryString: customMessage,
      item,
      viewsState,
      pageId,
      formData,
      __data,
      actionResponse: error,
      env: currentApp?.env,
      selectedRows,
      index,
    });

    if (action?.showSnackbar ?? true) {
      enqueueSnackbarRef?.(
        customMessage ||
          errorMessage ||
          error?.response?.data?.error ||
          JSON.parse(error?.response?.config?.data || "{}")?.errorMessage ||
          "Wrong Services",
        {
          variant: "error",
        }
      );
    }
  };

  const onAction = async (action, variables) => {
    const isGraphQL = action?.isGraphQL || false;
    setLoadingViews((prev: any) => {
      const updatedViews = { ...prev };
      updatedViews[`${pageId}-${viewName}`] = true;
      return updatedViews;
    });
    setIsLoading(true);
    const _appId = currentApp?.id ?? fqdnApp?.id;
    const isUnprotectedPage = checkUnprotected(path);
    if (fqdnApp && isUnprotectedPage) {
      await registerAppDevice?.(_appId as string, (currentApp ?? fqdnApp)?.appConfig?.auth?.deviceApi, currentApp ?? fqdnApp);
    }
    const accessToken = localStorage.getItem(_appId + `-${currentProfileId}-accessToken`);
    const deviceToken = localStorage.getItem(`${_appId}-accessToken-device`);

    const token = isUnprotectedPage ? deviceToken : accessToken || deviceToken;

    onStateChange("loading");

    let url = replaceDataPlaceholders({
      queryString: action?.source,
      item,
      viewsState,
      formData,
      pageId,
      __data,
      env: currentApp?.env,
      csvRow: variables?.csvRow,
      index,
    });

    url = encodeURLParams(replaceBaseUrl(url, currentApp ?? fqdnApp));
    let data = undefined;

    const actionBody = isGraphQL ? action?.graphqlVariables : action?.body;

    const processedBody = replaceDataPlaceholdersRecursively({
      obj: JSON.parse(action?.showModal == "Yes" ? actionBody : actionBody || "{}"),
      viewsState,
      pageId,
      item,
      formData,
      __data,
      env: currentApp?.env,
      csvRow: variables?.csvRow,
      selectedRows,
      fallback: null,
      index,
    });

    const requestHeaders = {
      ...getAuthorizationHeader(currentApp?.appConfig?.auth, token),
      ...replaceDataPlaceholdersRecursively({
        obj: action?.showModal == "Yes" ? action?.headers : action?.headers,
        viewsState,
        pageId,
        item,
        formData,
        __data,
        env: currentApp?.env,
        csvRow: variables?.csvRow,
        selectedRows,
        index,
      }),
    };

    if (handleSubmit) {
      data = actionBody && processedBody;
    } else {
      data = actionBody && processedBody;
    }
    let response = {} as any;
    try {
      if (isGraphQL) {
        const gqlQuery = gql`
          ${action?.graphqlQuery}
        `;
        const isMutation = action?.graphQLMethod === "MUTATION" && action?.graphqlQuery?.includes("mutation");
        const operationFunction = (isMutation ? apolloClient.mutate : apolloClient.query) as any;
        const operationKey = isMutation ? "mutation" : "query";
        const graphQLContext = {
          uri: url,
          headers: requestHeaders,
        };
        response = await operationFunction({
          [operationKey]: gqlQuery,
          variables: data,
          context: graphQLContext,
        });
        if (variables?.onSuccess) {
          variables?.onSuccess(response?.data, variables);
        } else {
          handleOnSuccess(action, response, variables);
        }
      } else {
        response = await extAxiosServices.request({
          url: url,
          params: {
            skipAuthErrorClear: isHostAvailable,
          },
          method: action?.method?.toLowerCase?.(),
          headers: requestHeaders,
          data,
        });
        if (variables?.onSuccess) {
          variables?.onSuccess(response?.data, variables);
        } else {
          handleOnSuccess(action, response, variables);
        }
      }
    } catch (error) {
      cancelWorkflow = true;
      handleOnError(action, error, variables);
    } finally {
      setIsLoading(false);
      setLoadingViews((prev: any) => {
        const updatedViews = { ...prev };
        updatedViews[`${pageId}-${viewName}`] = false;
        return updatedViews;
      });
    }
  };

  const handleSelectClick = action => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = ".csv, .xlsx";
    fileInput.addEventListener("change", e => handleFileSelect(action, e));
    fileInput.click();
  };

  const handleFileSelect = (action, event: any) => {
    const selectedFile = event.target.files && event.target.files[0];
    if (selectedFile) {
      if (selectedFile.name.endsWith(".csv") || selectedFile.name.endsWith(".xlsx")) {
        convertXlsxAndCsvToJson(action, selectedFile);
      } else {
        console.error("Unsupported file format");
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
    setJsonProgress([]);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const convertXlsxAndCsvToJson = async (action, file: File) => {
    const duration = action?.duration;

    const delayFactor = ~~duration || 50;

    const maxCalls = ~~action?.maxCalls || 1;

    const handleOpenLogic = async (jsonArray: any[], delayFactor: number) => {
      handleOpen();

      const totalRequests = jsonArray.length - 1;
      let currentIndex = 0;

      let activeThreads = maxCalls;
      const makeRequest = () => {
        let isOpen = false;
        setOpen(prev => {
          isOpen = prev;
          return prev;
        });
        if (!isOpen) return;

        const handleNextRequest = async () => {
          await new Promise(resolve => setTimeout(resolve, delayFactor));
          currentIndex += 1;
          if (totalRequests < currentIndex) {
            activeThreads -= 1;
            if ((activeThreads === 0 && action?.showSnackbar) ?? true) {
              const successMessage = "CSV Processing Completed Successfully!";
              enqueueSnackbarRef?.(successMessage, {
                variant: "success",
              });
            }
            return;
          }
          makeRequest();
        };
        setJsonProgress((prevProgress: any[]) => [jsonArray[currentIndex], ...prevProgress]);
        onAction(action, { csvRow: jsonArray[currentIndex], isCsv: true, then: handleNextRequest });
      };

      for (let index = 0; index < maxCalls; index++) {
        if (totalRequests < currentIndex) return;
        makeRequest();
        currentIndex += 1;
      }
    };

    const reader = new FileReader();

    reader.onload = async event => {
      if (event.target && event.target.result instanceof ArrayBuffer) {
        const data = event.target.result;
        const workbook = XLSX.read(new Uint8Array(data), { type: "array" });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];

        let jsonArray: any[] = [];

        if (file.name.endsWith(".xlsx")) {
          jsonArray = XLSX.utils.sheet_to_json(worksheet).map((row: any) => ({
            __id: uuid(),
            ...row,
            status: "Pending",
          }));
          handleOpenLogic([...jsonArray], delayFactor);
        } else if (file.name.endsWith(".csv")) {
          Papa.parse(file, {
            complete: async (result: any) => {
              if (result.data && result.data.length > 1) {
                const keys: string[] = result.data[0];

                const statusIndex = keys.indexOf("status");
                if (statusIndex !== -1) {
                  keys.splice(statusIndex, 1);
                  keys.push("status");
                }

                for (let i = 1; i < result.data.length - 1; i++) {
                  const rowData: any[] = result.data[i];
                  const obj: any = {
                    __id: uuid(),
                  };
                  keys.forEach((key, j) => {
                    obj[key] = rowData[j];
                  });
                  obj.status = "Pending";
                  jsonArray.push(obj);
                }
                handleOpenLogic([...jsonArray], delayFactor);
              } else {
                console.error("Error reading file");
              }
            },
          });
        }
      } else {
        console.error("Error reading file");
      }
    };

    reader.readAsArrayBuffer(file);
  };

  const downloadCSV = () => {
    if (entries?.length === 0) {
      return;
    }

    const keys = Object.keys(entries[0]);

    const csvContent = [keys.join(","), ...entries.map((entry: any) => keys.map(key => entry[key]).join(","))].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "data.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    URL.revokeObjectURL(url);
  };

  const handleResetForm = async action => {
    const inputReference = action?.dataReference ?? "";
    const elementKey = replaceNamePlaceHolder(inputReference);

    if (elementKey) {
      resetState(elementKey);
    } else {
      // handle old reset state without key item logic
      resetState(`${pageId}.${viewName}`);
    }
  };

  const handleActionClick = async action => {
    if (loadingViews[`${pageId}-${viewName}`]) return;
    if (action.enableCSV && action?.actionSourceType?.id !== -3) {
      handleSelectClick(action);
      return;
    }

    if (action?.actionSourceType?.id === -3) {
      downloadCSV();
      return;
    }

    // if (action?.dialog?.enabled && !openConfirmation) {
    //   return setOpenConfirmation(true);
    // }

    if (handleSubmit) {
      const { isError, values } = handleSubmit();
      if (isError) {
        cancelWorkflow = true;
        validationError = true;
        return;
      }

      if (disableInternalAction) {
        await onAction(action, {});
        await onActionClick(values, action);
      } else {
        await onAction(action, {});
      }
    } else {
      await onAction(action, {});
    }
  };

  const handleStripePayment = async action => {
    if (!stripe || !elements) {
      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);
    const cardCvcElement = elements.getElement(CardCvcElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);
    const addressElement = elements.getElement(AddressElement);
    const paymentElement = elements.getElement(PaymentElement);

    if (cardNumberElement && cardCvcElement && cardExpiryElement) {
      const payload = await stripe.createPaymentMethod({
        type: "card",
        card: cardNumberElement,
      });
    } else if (addressElement || paymentElement) {
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: { return_url: "" },
        redirect: "if_required",
      });
    } else {
      console.error("Missing required elements");
      return;
    }
  };

  const moveGroupStepperIndexBy = (offset: number, _action: any) => {
    setValue(
      `${pageId}.${viewName}._BX_stepperGroups`,
      updateStepperGroupIndex(watch(`${pageId}.${viewName}._BX_stepperGroups`) || {}, _action?.stepperGroupReference, "UPDATE", offset),
      true,
      true
    );
  };

  const setStepperIndex = (index: number, _action: any) => {
    setValue(
      `${pageId}.${viewName}._BX_stepperGroups`,
      updateStepperGroupIndex(watch(`${pageId}.${viewName}._BX_stepperGroups`) || {}, _action?.stepperGroupReference, "SET", index),
      true,
      true
    );
  };

  const handleSetStepperIndex = _action => {
    setStepperIndex(_action?.stepperIndex, _action);
  };

  const handleStepperPrevious = _action => {
    moveGroupStepperIndexBy(-1, _action);
  };
  const handleStepperNext = _action => {
    moveGroupStepperIndexBy(1, _action);
  };

  const addItem = action => {
    updateDataPlaceholders({
      queryString: action.dataReference,
      item,
      viewsState,
      pageId,
      __data,
      index,
      $action: "ADD",
    });
  };

  const trigger = async (action, e, viewsState, wait?: any) => {
    const triggerSelf = action.triggerSelf;
    const key = triggerSelf ? _key : action.triggerKey;

    await new Promise<void>(resolve => {
      setTimeout(async () => {
        if (wait) {
          await triggerActionableComponent(e, pageId, viewName, key, viewsState);
        } else {
          triggerActionableComponent(e, pageId, viewName, key, viewsState);
        }
        resolve();
      }, 0);
    });
  };

  const deleteItem = action => {
    updateDataPlaceholders({
      queryString: action.dataReference,
      item,
      viewsState,
      pageId,
      __data,
      index,
      $action: "DELETE",
    });
  };

  const updateItem = action => {
    let updatedValue;
    if (action.selectedType === "object") {
      const object = JSON.parse(action.updatedValue);
      updatedValue = replaceDataPlaceholdersRecursively({
        obj: object,
        item,
        viewsState,
        pageId,
        __data,
        env: currentApp?.env,
        selectedRows,
        index,
      });
    } else {
      updatedValue = replaceDataPlaceholders({
        queryString: action.updatedValue,
        item,
        viewsState,
        formData,
        pageId,
        __data,
        index,
      });
    }
    updateDataPlaceholders({
      queryString: action.dataReference,
      item,
      viewsState,
      pageId,
      __data,
      index,
      $action: "UPDATE",
      $payload: updatedValue,
    });
  };

  const cleanDirty = action => {
    const inputReference = action?.dataReference ?? "";
    const elementKey = replaceNamePlaceHolder(inputReference);

    if (elementKey) {
      removeDirty(elementKey);
    }
  };

  const timerAction = async action => {
    const timer = Number(action?.timerValue);
    const sec = timer * 1000;
    await new Promise(resolve => {
      setTimeout(() => {
        resolve(true);
      }, sec);
    });
  };

  const handleCopyClick = action => {
    const copySourceValue =
      replaceDataPlaceholders({
        queryString: action.copySource,
        item,
        viewsState,
        pageId,
        __data,
        env: currentApp?.env,
        selectedRows,
        index,
      }) || "";

    if (copySourceValue) {
      navigator.clipboard
        .writeText(copySourceValue)
        .then(() => {
          if (action?.showSnackbar ?? true) {
            enqueueSnackbarRef("Copied!", { variant: "success" });
          }
        })
        .catch(err => {
          cancelWorkflow = true;
          if (action?.showSnackbar ?? true) {
            enqueueSnackbarRef("Failed to copy!", { variant: "error" });
          }
        });
    }
  };
  const showSnackbar = action => {
    const app = currentApp ?? fqdnApp;

    type VerticalPosition = "top" | "bottom";
    type HorizontalPosition = "left" | "center" | "right";
    const customMessage = replaceDataPlaceholders({
      queryString: action.snackbarMessage,
      item,
      viewsState,
      pageId,
      formData,
      __data,
      env: app?.env,
      selectedRows,
      index,
    });

    const defaultVariants = ["default", "success", "error", "info", "warning"];

    const duration = action.snackbarDuration || app?.appConfig?.snackbar?.duration;
    const durationInMs = Number(duration) * 1000;
    const customVariant = app?.appConfig?.snackbar?.allVariants?.includes(action?.snackbarVariant)
      ? action.snackbarVariant
      : app?.appConfig?.snackbar?.variant;

    const position = action?.snackbarPosition == "Inherent from app" ? app?.appConfig?.snackbar?.position : action?.snackbarPosition;
    const actionButton =
      action.snackbarActionButton == "Inherent from app" ? app?.appConfig?.snackbar?.closeButton : action.snackbarActionButton;
    const preventDuplicate = action.snackbarPreventDuplicate;
    const [vertical, horizontal] = position?.split("-") as [VerticalPosition, HorizontalPosition];
    const isCustom = action?.isCustom;
    const variantsSettings = app?.appConfig?.snackbar?.variantsSettings;
    const customPosition = isCustom ? action?.alignText : variantsSettings?.[customVariant]?.alignText;
    const customBgColor = isCustom ? action?.backgroundColorAdvance : variantsSettings?.[customVariant]?.backgroundColorAdvance;
    const customColor = isCustom ? action?.colorAdvance : variantsSettings?.[customVariant]?.colorAdvance;
    const customIcon = variantsSettings?.[customVariant]?.iconAdvance;
    const iconColor = isCustom ? action?.iconColor : variantsSettings?.[customVariant]?.iconColor;

    enqueueSnackbarRef?.(customMessage, {
      variant: customVariant,
      anchorOrigin: {
        vertical,
        horizontal,
      },
      ...(duration && { autoHideDuration: durationInMs }),
      ...(actionButton !== "Without Button" && {
        action: key =>
          actionButton == "Icon X" || actionButton == "Dismiss" ? (
            <Button
              onClick={() => {
                closeSnackbarRef(key);
              }}
            >
              {actionButton == "Icon X" && <IconX color='white' size={18} />}
              {actionButton == "Dismiss" && <Typography color={"white"}>Dismiss</Typography>}
            </Button>
          ) : (
            <Box
              style={{
                overflow: "hidden",
                height: "100%",
                cursor: "pointer",
                position: "absolute",
                top: "5px",
                right: "5px",
              }}
            >
              <IconX
                color='white'
                size={14}
                onClick={() => {
                  closeSnackbarRef(key);
                }}
              />
            </Box>
          ),
      }),
      ...(actionButton == "Without Button" && {
        action: key => <></>,
      }),

      preventDuplicate,
      ...((!defaultVariants.includes(customVariant) || isCustom) && {
        content: (key, message) => {
          return (
            <SnackbarContent
              style={{
                backgroundColor: customBgColor || "",
                color: customColor || "",
                width: "100%",
                boxSizing: "border-box",
                margin: "8px",
                padding: "12px 16px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderRadius: "5px",
                position: "relative",
              }}
            >
              <Box
                style={{
                  width: actionButton == "Without Button" || actionButton == "Corner X" ? "100%" : "auto",
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: customPosition || "left",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <BXIcon
                  width={"25px"}
                  height={"25px"}
                  icon={isCustom ? action?.iconAdvance?.icon : variantsSettings?.[customVariant]?.iconAdvance?.icon}
                  url={isCustom ? action?.iconAdvance?.url : variantsSettings?.[customVariant]?.iconAdvance?.url}
                  visibility={isCustom ? action?.iconAdvance?.visibility : variantsSettings?.[customVariant]?.iconAdvance?.visibility}
                  color={iconColor || "white"}
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                />
                {customMessage}
              </Box>
              {actionButton !== "Without Button" && actionButton == "Corner X" && (
                <Box
                  style={{
                    overflow: "hidden",
                    height: "100%",
                    cursor: "pointer",
                    position: "absolute",
                    top: "5px",
                    right: "5px",
                  }}
                >
                  <IconX
                    color='#8a8a8a'
                    size={14}
                    onClick={() => {
                      closeSnackbarRef(key);
                    }}
                  />
                </Box>
              )}

              {actionButton !== "Without Button" && (actionButton == "Icon X" || actionButton == "Dismiss") && (
                <Button
                  onClick={() => {
                    closeSnackbarRef(key);
                  }}
                >
                  {actionButton == "Icon X" && <IconX color='black' size={18} />}
                  {actionButton == "Dismiss" && <Typography color={"black"}>Dismiss</Typography>}
                </Button>
              )}
            </SnackbarContent>
          );
        },
      }),
    });
  };

  const stopWorkflow = action => {
    cancelWorkflow = true;
  };

  const handleLinkClick = async action => {
    if (!action?.navigationAfterAction && action?.actionSourceType?.type !== "Link") return;
    const linkUrlValue =
      replaceDataPlaceholders({
        queryString: action?.linkUrl,
        item,
        viewsState,
        pageId,
        __data,
        env: currentApp?.env,
        selectedRows,
        index,
      }) || "";

    if (linkUrlValue) {
      const path = encodeURLParams(replaceBaseUrl(linkUrlValue, undefined, fqdnApp ? "" : currentApp?.slug));
      if (linkUrlValue.startsWith("/")) {
        let updatedValue;
        if (action.selectedType === "object") {
          const object = JSON.parse(action.updatedValue);
          updatedValue = replaceDataPlaceholdersRecursively({
            obj: object,
            item,
            viewsState,
            pageId,
            __data,
            env: currentApp?.env,
            selectedRows,
            index,
          });
        } else {
          updatedValue = replaceDataPlaceholders({
            queryString: action.updatedValue,
            item,
            viewsState,
            formData,
            pageId,
            __data,
            index,
          });
        }
        await new Promise((resolve, reject) => {
          setTimeout(() => {
            try {
              if (action?.openLinkAs == "_blank") {
                resolve(window.open(path, "_blank"));
              } else {
                resolve(
                  navigate(path, {
                    state: updatedValue,
                  })
                );
              }
            } catch (error) {
              reject(error);
            }
          }, 0);
        });
      } else {
        await new Promise((resolve, reject) => {
          setTimeout(() => {
            try {
              resolve(window.open(path, action?.openLinkAs || "_blank"));
            } catch (error) {
              reject(error);
            }
          }, 0);
        });
      }
    } else {
      cancelWorkflow = true;
      if (action?.showSnackbar ?? true) {
        enqueueSnackbarRef("Invalid URL!", { variant: "error" });
      }
    }
  };

  const handleRefreshViews = async action => {
    const isGraphQL = action?.isGraphQL || false;

    const _refreshList =
      typeof action?.refreshActionView?.[0] === "string"
        ? action?.refreshActionView.map(name => {
            const { id } = views?.find(view => view?.info?.name === name) as UIElement;
            return {
              id: id,
              name,
            };
          })
        : action?.refreshActionView;

    const refreshViewsIds = new Set(_refreshList.map(v => v?.id));
    const refreshViews = views?.filter(view => refreshViewsIds.has(view?.id));

    refreshViews?.forEach(async refreshView => {
      const refreshViewName = refreshView?.info?.viewName;
      const refreshKey = `${pageId}.${refreshViewName}`;

      await handleRefetchQueries({
        isGraphQL,
        queriesStateGraphQL,
        key: refreshKey,
        queryKeys: refreshKey,
        dispatch,
      });
    });
  };

  const downloadFile = (url, showSnackbar) => {
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", blob.type);
        document.body.appendChild(link);
        link.click();
      })
      .catch(e => {
        cancelWorkflow = true;
        if (showSnackbar) {
          enqueueSnackbarRef("Failed to download!", { variant: "error" });
        }
      });
    return;
  };

  const handleDownloadClick = async action => {
    if (action?.downloadType === "api") {
      onAction(action, {
        onSuccess: data => {
          let url = data;
          if (action?.downloadKey) {
            url = data?.[action?.downloadKey];
          }
          downloadFile(url, action?.showSnackbar ?? true);
        },
      });
      return;
    }

    downloadFile(action?.directUrl, action?.showSnackbar ?? true);
  };

  const handleStackClick = (action, e) => {
    e.stopPropagation();
    onSelectRow?.(item, true);
    setViewStacks(
      prev =>
        [
          ...prev,
          <BXEngine
            path={[path, selectedView?.info?.name].join(".")}
            auth={{}}
            page={{ views, layout: selectedView, id: pageId } as any}
            layout={[{ ...(selectedView as any), type: action?.actionSourceType?.type }]}
            isVisible
            selectedViewId={item.id}
            __data={{
              ...__data,
              [(getLastKeyFromObject(__data) || "") + "#."]: viewName,
            }}
            closeModal={closeModal}
            parentIds={[...parentIds, ...queryKeys]}
          />,
        ] as UIElement[]
    );
  };

  const handleLoginAction = async action => {
    setIsLoading(true);
    const email = replaceDataPlaceholders({
      queryString: action?.BXLogin?.emailField,
      viewsState,
      pageId,
      __data,
      env: currentApp?.env,
      fallback: "",
    });

    const password = replaceDataPlaceholders({
      queryString: action?.BXLogin?.passwordField,
      viewsState,
      pageId,
      __data,
      env: currentApp?.env,
      fallback: "",
    });

    await registerAppDevice?.(currentApp?.id || fqdnApp?.id || "", fqdnApp?.appConfig?.auth?.deviceApi, fqdnApp);
    const response = await loginToApp?.(
      currentApp?.id || fqdnApp?.id || "",
      email,
      password,
      undefined,
      undefined,
      () => {},
      undefined,
      fqdnApp?.appConfig?.auth?.authApi,
      fqdnApp,
      undefined,
      {
        skipAuthErrorClear: true,
      },
      action?.BXLogin?.disableAutoNavigate
    );

    if (_key) {
      setViewsState(prev => {
        _.set(prev, `${pageId}-${viewName}.response.${_key}`, { ...response, requestStatusCode: response?.request?.code });
        return prev;
      });
    }
    setIsLoading(false);
  };

  const handleLogoutAction = async action => {
    const isFQDN = !!fqdnApp;
    if (isFQDN) {
      setUserAuth();
    }
    await logoutOfApp?.(currentApp?.id as string, isFQDN);
    setCurrentProfileId(null);
    setAppProfiles(appProfiles => {
      const newAppProfiles = { ...appProfiles };
      delete newAppProfiles[currentApp?.id as string];
      return newAppProfiles;
    });

    if (isHostAvailable || (currentApp?.appConfig?.withProfiles && currentApp?.appConfig?.isSingleSignOn)) {
      await axiosServices.delete(`application/${currentApp?.id}/profile/${currentProfileId}`);
    }
  };

  const handleStartLoading = () => {
    //Change loading state for page and view
    dispatch(setLoading({ keys: { page: pageId, view: `${pageId}.${viewName}` } }));
  };

  const handleEndLoading = () => {
    //Change loading state for page and view
    dispatch(resetLoading({ keys: { page: pageId, view: `${pageId}.${viewName}` } }));
  };

  const handleVisibilityToggle = action => {
    const keyReference = action.keyReference;
    if (keyReference) {
      setViewsState(prevState => {
        const viewRef = `${pageId}-${viewName}`;
        const newState: IViewState = {
          ...prevState,
          state: prevState.state,
          visibility: {
            ...prevState.visibility,
            [viewRef]: {
              ...prevState.visibility?.[viewRef],
              [keyReference]: {
                toggle: !prevState.visibility?.[viewRef]?.[keyReference]?.toggle,
                display: action.displayRef ? "hidden" : "none",
              },
            },
          },
        };

        return newState;
      });
    }
  };

  const handleConfirmAction = async () => {
    actionConfirmControl.confirm();
    setIsConfirmAction(null);
  };

  const handleCancelAction = async () => {
    actionConfirmControl.cancel();
    setIsConfirmAction(null);
  };

  const replaceNamePlaceHolder = (dataSourceKey: string) => {
    let elementKey = "";

    if (dataSourceKey.startsWith("{this")) {
      if (dataSourceKey === "{this}") {
        //Current View
        elementKey = `${pageId}.${viewName}`;
      } else if (dataSourceKey === "{this._page}") {
        //Current Page
        elementKey = `${pageId}`;
      } else {
        //Component in the current view
        const placeholder = dataSourceKey.slice("{this.".length, -1);
        elementKey = `${pageId}.${viewName}.${placeholder}`;
      }
    } else {
      //Another view in the same page
      dataSourceKey.replace(/{(\w+)(\.\w+)?}/g, (_match, view, placeholder) => {
        elementKey = placeholder ? `${pageId}.${view}${placeholder}` : `${pageId}.${view}`;
        return elementKey;
      });
    }
    return elementKey;
  };

  const handleTriggerDataSource = async action => {
    //Read data source key
    const dataSourceKey = action?.dataSourceKey;
    const elementKey = replaceNamePlaceHolder(dataSourceKey);
    await new Promise((resolve, reject) => {
      dispatch(
        produceTrigger({
          name: elementKey,
          type: "refetch",
          eventPayload: { resolver: resolve },
        })
      );
    });
  };

  const handleRunValidation = action => {
    const validationKey = action?.dataReference;
    const elementKey = replaceNamePlaceHolder(validationKey);
    const stepperGroupsPath = `${pageId}.${viewName}._BX_stepperGroups`;
    const stepperGroups = watch(stepperGroupsPath) || {};
    const ignoredPages = getIgnoredStepperPages(stepperGroups);

    const result = runAllValidations(elementKey, ignoredPages);
    validationError = !result;

    const scrollToErrorEnabled = action?.scrollToError ?? true;
    if (scrollToErrorEnabled) {
      setTimeout(() => {
        const firstInvalidField = document.querySelector('[aria-invalid="true"]');

        if (firstInvalidField) {
          firstInvalidField.scrollIntoView({ behavior: "smooth", block: "center" });
          (firstInvalidField as HTMLElement).focus();
        }
      }, 0);
    }
  };
  const handleCheckDirty = action => {
    //Read reference
    const validationKey = action?.dataReference;
    const elementKey = replaceNamePlaceHolder(validationKey);

    const stepperGroupsPath = `${pageId}.${viewName}._BX_stepperGroups`;
    const stepperGroups = watch(stepperGroupsPath) || {};
    const ignoredPages = getIgnoredStepperPages(stepperGroups);

    const result = getDirty(elementKey, ignoredPages);

    if (result) {
      // eslint-disable-next-line no-restricted-globals
      if (!confirm("There are some changes. Are you sure you want to navigate?")) {
        dirtyConfirmationDenied = true;
      } else {
        removeDirty(elementKey);
      }
    }
  };
  const handleNextPageAction = async action => {
    const dataSource = replaceNamePlaceHolder(action?.dataSourceKey);
    const paginationBar = replaceNamePlaceHolder(action?.paginationBarKey);

    const paginationBarState = getValue(paginationBar);
    const currentPage = paginationBarState?.state?.currentPage;
    const hasMore = paginationBarState?.state?.hasMore;

    if (!hasMore) {
      return;
    }

    await new Promise(resolve => {
      dispatch(
        produceTrigger({
          name: paginationBar,
          type: "updatePage",
          eventPayload: { resolver: resolve, page: currentPage + 1 },
        })
      );
    });
  };

  const handlePreviousPageAction = async action => {
    const dataSource = replaceNamePlaceHolder(action?.dataSourceKey);
    const paginationBar = replaceNamePlaceHolder(action?.paginationBarKey);

    const paginationBarState = getValue(paginationBar);
    const currentPage = paginationBarState?.state?.currentPage;
    const hasMore = paginationBarState?.state?.hasMore;

    if (!hasMore) {
      return;
    }

    await new Promise(resolve => {
      dispatch(
        produceTrigger({
          name: paginationBar,
          type: "updatePage",
          eventPayload: { resolver: resolve, page: currentPage - 1 },
        })
      );
    });
  };

  const handleClearValidation = action => {
    const elementKey = replaceNamePlaceHolder(action?.dataReference);

    if (elementKey) {
      clearValidation(elementKey);
    }
  };

  const handleActionMapper = async (action, e, viewsState) => {
    const isPureAction = actionTypes?.find(actionType => actionType?.id === action?.actionSourceType?.id);
    const isViewAction = !isPureAction;
    if (isViewAction) {
      const _views = views?.concat(getSharedViews(currentApp));
      setSelectedView(_views?.find(view => view?.id === action?.actionSourceType?.id));
      setActionView(action);
      setIsViewActionTriggered(true);
      await new Promise(resolve => {
        setProceedToNextAction({
          currentAction: action,
          confirm: () => {
            resolve(true);
          },
          cancel: () => {
            cancelWorkflow = true;
            resolve(false);
          },
        });
      });
      return;
    }

    if (action?.actionSourceType?.type === "API" && action?.dialog?.enabled) {
      const confirmationDialogData = replaceDataPlaceholdersRecursively({
        obj: action?.dialog,
        item,
        viewsState,
        pageId,
        __data,
        env: currentApp?.env,
        selectedRows,
        index,
      });

      //Store confirmation state in views state context for later referencing in the dialog view if any.
      setViewsState((prev: any) => {
        if (!confirmationDialogData?.customViewEnabled) {
          _.set(prev, `${pageId}-${viewName}.confirmation`, confirmationDialogData);
          return prev;
        }

        const _views = views?.concat(getSharedViews(currentApp));
        const confirmationViewName = _views?.find(v => v?.id === action?.dialog?.customView)?.info?.viewName;
        _.set(prev, `${pageId}-${confirmationViewName}.confirmation`, confirmationDialogData);
        return prev;
      });

      setIsConfirmAction(actionId);
      await new Promise(resolve => {
        setActionConfirmControl({
          currentAction: action,
          title: confirmationDialogData?.title,
          confirmationDialogData: confirmationDialogData,
          confirm: async () => {
            await handleActionClick(action);
            resolve(true);
          },
          cancel: () => {
            cancelWorkflow = true;
            //When the user denies of the confirmation dialog, suspend the subsequent actions
            isConfirmationDenied = true;
            resolve(false);
          },
        });
      });
      return;
    }

    if (action?.actionSourceType?.type === "API" && action?.showModal === "Yes") {
      setActionModal(action);
      setShowModalForAction(true);
      await new Promise(resolve => {
        setProceedToNextAction({
          currentAction: action,
          confirm: () => {
            resolve(true);
          },
          cancel: () => {
            cancelWorkflow = true;
            resolve(false);
          },
        });
      });
      return;
    }

    if (isLoading || disabled) return;
    switch (action?.actionSourceType?.type) {
      case "API":
        await handleActionClick(action);
        break;
      case "Stripe Payment":
        await handleStripePayment(action);
        break;
      case "Views": {
        setSelectedView(views?.find(view => view?.id === action?.actionSourceType?.id));
        setActionView(action);
        setIsViewActionTriggered(true);
        await new Promise(resolve => {
          setProceedToNextAction({
            currentAction: action,
            confirm: () => {
              resolve(true);
            },
            cancel: () => {
              cancelWorkflow = true;
              resolve(false);
            },
          });
        });
        break;
      }
      case "Download":
        await handleDownloadClick(action);
        break;
      case "Copy":
        handleCopyClick(action);
        break;
      case "Show Snackbar":
        showSnackbar(action);
        break;
      case "Link":
        handleLinkClick(action);
        break;
      case "BuildX Login":
        await handleLoginAction(action);
        break;
      case "BuildX Logout":
        await handleLogoutAction(action);
        break;
      case "Stack":
        handleStackClick(action, e);
        break;
      case "Visibility Toggle":
        handleVisibilityToggle(action);
        break;
      case "Export CSV":
        handleActionClick(action);
        break;
      case "Add item":
        addItem(action);
        break;
      case "Delete item":
        deleteItem(action);
        break;
      case "Update item":
        updateItem(action);
        break;
      case "Trigger":
        if (!action.triggerSelf && action.triggerAwait && action.triggerKey !== _key) {
          const wait = true;
          await trigger(action, e, viewsState, wait);
        } else {
          trigger(action, e, viewsState);
        }
        break;
      case "Clean Dirty":
        cleanDirty(action);
        break;
      case "Timer":
        await timerAction(action);
        break;
      case "Dismiss": {
        closeModal?.(null);
        break;
      }
      case "Start Loading":
        handleStartLoading();
        break;
      case "End Loading":
        handleEndLoading();
        break;
      case "Stepper Previous": {
        handleStepperPrevious?.(action);
        break;
      }
      case "Stepper Next": {
        handleStepperNext?.(action);
        break;
      }
      case "Select Stepper": {
        handleSetStepperIndex?.(action);
        break;
      }
      case "Refresh Views": {
        handleRefreshViews(action);
        break;
      }
      case "Reset Form": {
        handleResetForm(action);
        break;
      }
      case "Confirm": {
        await handleConfirmAction?.();
        break;
      }
      case "Cancel": {
        await handleCancelAction?.();
        break;
      }
      case "Trigger Data Source": {
        await handleTriggerDataSource(action);
        break;
      }
      case "Run Validation": {
        await handleRunValidation?.(action);
        break;
      }
      case "Check Dirty": {
        await handleCheckDirty?.(action);
        break;
      }
      case "Next Page": {
        handleNextPageAction(action);
        break;
      }
      case "Previous Page": {
        handlePreviousPageAction?.(action);
        break;
      }
      case "Stop Workflow": {
        stopWorkflow?.(action);
        break;
      }
      case "Clear Validation": {
        handleClearValidation?.(action);
        break;
      }
      default:
    }
  };

  async function executeChain(e: any, _viewsState?: any) {
    let actions: any[] = actionsMap?.default || [];
    // reset the cancel work flow error
    cancelWorkflow = false;
    dirtyConfirmationDenied = false;
    isConfirmationDenied = false;
    validationError = false;
    // reset .response for the whole workflow action
    if (_key) {
      setViewsState((prev: any) => {
        _.set(prev, `${pageId}-${viewName}.response.${_key}`, undefined);
        return prev;
      });
    }

    let mappedActionKey: string;

    if (!isChainMapped) {
      mappedActionKey = "default";
      actions = actionsMap?.default || [];
      if (props?.action && _.isEmpty(actionsMap)) {
        actions = [{ actionConfig: props?.action }, ...actions];
      }
      if (_.isEmpty(actionsMap) && tableAction?.action?.actionSourceType) {
        actions = [{ actionConfig: tableAction?.action }, ...actions];
      }
    } else {
      const resolvedActionsKey = replaceDataPlaceholders({
        queryString: actionsKey,
        item,
        viewsState,
        pageId,
        formData,
        __data,
        env: currentApp?.env,
        selectedRows,
        index,
      });
      mappedActionKey = resolvedActionsKey;

      const condition = element?.config?.conditionActionMapKey || conditionKeyFromTable || "equal";
      if (condition === "equal" && actionsMap?.[resolvedActionsKey]) {
        actions = actionsMap?.[resolvedActionsKey];
      } else if (condition === "startWith") {
        const matchingKey = Object.keys(actionsMap).find(key => resolvedActionsKey?.startsWith(key));
        if (matchingKey) {
          actions = actionsMap?.[matchingKey];
        }
      }
    }

    if (!_.isEmpty(actions) && e) {
      e.stopPropagation();
    }

    actions = actions.filter(action => Object.keys(action?.actionConfig)?.length !== 0);
    if (isLoadingForEntireChain?.[mappedActionKey]) {
      actions = actions.filter(action => !action?.actionConfig?.actionSourceType?.type?.includes("Loading"));
      handleStartLoading?.();
    }

    for (let i = 0; i < actions?.length; i++) {
      const action = actions?.[i]?.actionConfig;
      const actionCondition = replaceDataPlaceholders({
        queryString: action.actionCondition ?? action.condition,
        actionResponse: _.get(viewsState, `${pageId}-${viewName}.response.${_key}`),
        item,
        viewsState,
        pageId,
        formData,
        __data,
        env: currentApp?.env,
        selectedRows,
        index,
      });

      try {
        if (!eval(actionCondition) && !_.isEmpty(actionCondition)) {
          continue;
        }
      } catch (error) {
        console.error("Expression throws an exception");
      }

      await handleActionMapper(action, e, _viewsState || viewsState);

      if (isConfirmationDenied) {
        break;
      }

      if (validationError && (action.abort ?? true)) {
        break;
      }

      if (dirtyConfirmationDenied) {
        break;
      }

      if (cancelWorkflow && (action.abort ?? true)) {
        // enqueueSnackbarRef?.("Workflow aborted", { variant: "error" });
        handleEndLoading?.();
        break;
      }

      // enqueueSnackbarRef?.("Workflow executed successfully", { variant: "success" });
    }
    if (isLoadingForEntireChain?.[mappedActionKey]) {
      handleEndLoading?.();
    }
  }

  const renderActionableContent = () => {
    return typeof children === "function" ? children({ actionHandlerMapper: executeChain, isLoading }) : children;
  };

  const onClickHandler = e => {
    //For old action version
    if (!interactionConfig) {
      executeChain(e);
      return;
    }

    if (currentRepeatedItem && onSelectRow) {
      onSelectRow?.(currentRepeatedItem);
    }

    const selfTriggers = interactionConfig?.filter(interaction => interaction.type === "OnClick" && interaction?.triggerSelf);
    selfTriggers?.forEach(_ => executeChain(e));
  };

  const renderActionableComponent = () => {
    return children ? (
      <span style={{ display: "contents" }} onClick={onClickHandler}>
        {renderActionableContent()}
      </span>
    ) : (
      <Tooltip title={tableAction?.label}>
        {iconButton ? (
          <IconButton
            onClick={e => {
              onSelectRow(item);
              e.stopPropagation();
              executeChain(e);
            }}
            disabled={disabled}
          >
            <BXIcon icon={tableAction?.icon} width={16} height={16} color={"currentColor"} />
          </IconButton>
        ) : (
          <LoadingButton
            loading={isLoading}
            loadingPosition='start'
            aria-label={tableAction?.label}
            variant={variant as any}
            disabled={disabled}
            onClick={e => {
              onSelectRow(item);
              e.stopPropagation();
              executeChain(e);
            }}
            startIcon={tableAction?.icon && <BXIcon icon={tableAction?.icon} width={20} height={20} color={palette.text.primary} />}
            fullWidth={fullWidth}
            style={!withBorder ? { borderWidth: 0, justifyContent: "flex-start" } : {}}
          >
            <Typography color={palette.text.primary}>{tableAction?.label}</Typography>
          </LoadingButton>
        )}
      </Tooltip>
    );
  };

  // if (!action || _.isEmpty(action) || action?.actionSourceType?.id === -7) return (renderActionableContent() as any) || null;
  // const isAPI = _.isNaN(action?.actionSourceType?.type) || !action?.actionSourceType?.type || action?.actionSourceType?.type == "API";

  const isAPI = false;

  return (
    <>
      {isAPI && <CSVProgressDialog open={open} handleClose={handleClose} jsonData={jsonProgress} />}

      {isConfirmAction === actionId && (
        <BXConfirmationDialog
          views={views}
          open={isConfirmAction === actionId}
          title={actionModal?.title || "Are you sure?"}
          iconButton
          onConfirm={async () => {
            await handleConfirmAction();
          }}
          onCancel={() => {
            handleCancelAction();
          }}
          isCustom={actionConfirmControl?.confirmationDialogData?.customViewEnabled}
          customDialogView={actionConfirmControl?.confirmationDialogData?.customView}
          customDialogData={actionConfirmControl?.confirmationDialogData}
          pageId={pageId}
        />
      )}

      {actionModal && (
        <BXModal
          open={showModalForAction}
          buttonComponent={children}
          label={actionModal?.label}
          icon={<BXIcon icon={actionModal?.icon} width={14} height={14} color={"currentColor"} />}
          buttonProps={{
            variant: "contained",
            startIcon: <BXIcon icon={actionModal?.icon} width={14} height={14} color={"currentColor"} />,
          }}
          title={actionModal?.label}
          withoutLabel={iconButton}
          onClose={() => {
            setShowModalForAction(false);
            setActionModal(null);
            proceedToNextAction?.cancel();
          }}
        >
          {(handleClose: Function) => {
            const isGraphQL = actionModal?.isGraphQL || false;

            const actionBody = isGraphQL ? actionModal?.graphqlVariables : actionModal?.body;

            const resolvedBody = replaceDataPlaceholdersRecursively({
              obj: JSON.parse(actionModal?.showModal === "Yes" ? actionBody : actionBody || "{}"),
              viewsState,
              pageId,
              item,
              formData,
              __data,
              env: currentApp?.env,
              selectedRows,
              index,
            });

            const resolvedHeaders = replaceDataPlaceholdersRecursively({
              obj: actionModal?.headers,
              viewsState,
              pageId,
              item,
              formData,
              __data,
              env: currentApp?.env,
              selectedRows,
              index,
            });

            const uri = replaceDataPlaceholders({
              queryString: actionModal?.source,
              item,
              viewsState,
              formData,
              pageId,
              __data,
              index,
            });
            return (
              <CreatePayload
                payload={{
                  ...(isGraphQL
                    ? { graphqlVariables: JSON.stringify(resolvedBody), graphqlQuery: actionModal?.graphqlQuery }
                    : { body: JSON.stringify(resolvedBody) }),
                  headers: resolvedHeaders,
                  uri,
                  method: actionModal?.method,
                  graphQLMethod: actionModal?.graphQLMethod,
                  isGraphQL: actionModal?.isGraphQL,
                }}
                onSave={async (payload: any) => {
                  proceedToNextAction.action = {
                    ...proceedToNextAction.action,
                    ...(isGraphQL ? { graphqlVariables: payload?.graphqlVariables } : { body: payload?.body }),
                    headers: payload?.headers,
                    source: payload?.uri,
                    method: actionModal?.method,
                    graphQLMethod: actionModal?.graphQLMethod,
                  };

                  await onAction(proceedToNextAction?.action, {});
                  setActionModal(null);
                  proceedToNextAction?.confirm();
                }}
                label={actionModal?.label || "Save"}
                onClose={() => handleClose()}
                isLoading={isLoading}
                disabled={disabled}
              />
            );
          }}
        </BXModal>
      )}
      {renderActionableComponent()}
      {isViewActionTriggered && (
        <ViewerModal
          action={actionView}
          path={path}
          viewName={viewName}
          actionSourceType={actionView?.actionSourceType}
          item={item}
          pageId={pageId}
          selectedView={selectedView}
          openView={isViewActionTriggered}
          onSelectRow={onSelectRow}
          views={views}
          withoutLabel={iconButton}
          iconColor='currentColor'
          buttonComponent={children}
          isUserInput={isUserInput}
          showHeader={!!actionView?.showHeader}
          buttonProps={{
            style: iconButton
              ? {
                  // backgroundColor: palette.primary.main, padding: 6
                }
              : { ...(!withBorder && { borderWidth: 0, justifyContent: "flex-start" }) },
            fullWidth,
            variant,
          }}
          modalSize={actionView?.modalSize}
          __data={__data}
          id={tableId}
          parentIds={[...parentIds, ...queryKeys]}
          clearActionView={() => {
            proceedToNextAction.confirm();
            setIsViewActionTriggered(false);
            setActionView(null);
            setSelectedView(null);
          }}
        />
      )}
    </>
  );
};
