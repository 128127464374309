import {
  Box,
  Button,
  Card,
  CircularProgress,
  DialogActions,
  Grid,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  useTheme,
} from "@mui/material";
import { IconEye, IconHistory, IconPencil, IconPlus, IconTrashX } from "@tabler/icons-react";
import _ from "lodash";
import { FC, useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useInfiniteQuery, useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { useBXBuilderContext } from "src/BXEngine/BXBuilderContext";
import { BXConfirmationDialog } from "src/components/BXUI/AlertDialog/ConfirmationDialog";
import BXModal from "src/components/BXUI/Modal";
import { enqueueSnackbarRef } from "src/utils/SnackbarUtilsConfigurator";
import axios from "src/utils/axios";
import CreateTemplateModal from "./CreateTemplateModal";
import TemplateHistory from "./TemplateHistory";

const columns: any[] = [
  { id: "name", label: "name", minWidth: 150 },
  { id: "type", label: "Type", minWidth: 100 },
  { id: "visibility", label: "Visibility", minWidth: 100 },
];

type TemplatesTableProps = {
  isImport: boolean;
  setOpenTemplateList?: (openTemplateList: boolean) => void;
  setSelectedRow?: (selectedRow: object) => void;
  setOpenReplaceModal?: (openReplaceModal: boolean) => void;
  addTemplate?: (template: any, replaceAll: boolean) => void;
  isEmptyView?: boolean;
  templateId?: string;
  defaultType?: any;
};

const TemplatesTable: FC<TemplatesTableProps> = ({
  isImport,
  setOpenTemplateList,
  setSelectedRow,
  setOpenReplaceModal,
  addTemplate,
  isEmptyView,
  templateId,
  defaultType,
}) => {
  const capitalizeFirstLetter = str => {
    return str?.charAt(0).toUpperCase() + str?.slice(1).toLowerCase();
  };
  const [searchText, setSearchText] = useState("");
  const [typeFilter, setTypeFilter] = useState(isImport ? capitalizeFirstLetter(defaultType) || "Any" : "Any");
  const [visibilityFilter, setVisibilityFilter] = useState("Any");

  const navigate = useNavigate();
  const { palette } = useTheme();
  const { saveAsTemplate, getTemplateById } = useBXBuilderContext();
  const { mutateAsync: onSaveAsTemplate, isLoading } = useMutation({
    mutationFn: (data: any) => saveAsTemplate({ ...data }, data?.id),
  });
  const { mutateAsync: mutateTemplateById, data: templateData } = useMutation({
    mutationFn: async (templateId: string) => {
      const response = await getTemplateById(templateId);
      const data = await response.data;
      return data;
    },
    mutationKey: "getTemplateById",
  });

  const handleBackupClick = (template: any) => {
    axios.post(process.env.REACT_APP_HOST_API_KEY + `/api/admin/template-history/${template?.id}/backup`).then(() => {
      enqueueSnackbarRef?.("Backup Success", {
        variant: "success",
      });
    });
  };

  const [openEditModal, setOpenEditModal] = useState(false);

  let queryKey = ["template-list", visibilityFilter.trim(), typeFilter.trim()];

  if (searchText.trim()) {
    queryKey.push(searchText.trim());
  }

  const { data, hasNextPage, fetchNextPage, refetch, isFetching, isError } = useInfiniteQuery(
    queryKey,
    ({ pageParam, queryKey }) => {
      if (queryKey[1] && queryKey[1].length < 3) {
        return {
          data: {
            items: [],
          },
        } as any;
      }
      return axios.get(process.env.REACT_APP_HOST_API_KEY + "/api/admin/template", {
        params: {
          cursor: pageParam,
          name: searchText || undefined,
          visibility: visibilityFilter === "Any" ? undefined : visibilityFilter.toLocaleUpperCase(),
          type: typeFilter === "Any" ? undefined : typeFilter.toLocaleUpperCase(),
        },
      });
    },
    {
      keepPreviousData: true,
      getNextPageParam: (lastPage: any) => (lastPage?.data?.hasMore ? lastPage?.data?.cursor : undefined),
      refetchOnWindowFocus: false,
    }
  );
  async function updateTemplate(data: any, templateId: string) {
    try {
      await onSaveAsTemplate({ ...data, config: templateData?.config, id: templateId });
    } catch (error) {}
    refetch();
  }

  const { mutate: deleteTemplate } = useMutation(
    (itemToDelete: any) => {
      return axios.delete(process.env.REACT_APP_HOST_API_KEY + `/api/admin/template/${itemToDelete?.id}`);
    },
    {
      onSuccess: (_, editedData: any) => {
        refetch();
        enqueueSnackbarRef?.("Deleted successfully", {
          variant: "success",
        });
      },
    }
  );

  const [sentryRef] = useInfiniteScroll({
    loading: isFetching,
    hasNextPage: hasNextPage || false,
    onLoadMore: () => fetchNextPage(),
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: isError,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: "0px 0px 400px 0px",
  });

  const onRedirectToTemplate = (id: string) => {
    navigate(`/buildx/form-builder/template/${id}`);
  };

  const entities = _.flatten(data?.pages?.map((p: any) => _.get(p.data, "items")));

  return (
    <Card style={{ padding: 24 }}>
      <Grid xs={12} item container spacing={2}>
        <Grid item spacing={2} xs={8} md={4} alignItems='center'>
          <TextField
            size='small'
            fullWidth
            label={"Search"}
            onChange={e => {
              setSearchText(e.target.value);
            }}
          />
        </Grid>

        <Grid item direction='column' justifyContent='center' alignItems='center' xs={2}>
          <Grid item xs={12}>
            <TextField
              variant='outlined'
              select
              label={"Type"}
              disabled={isImport}
              fullWidth
              size='small'
              defaultValue={isImport ? capitalizeFirstLetter(defaultType) : "Any"}
              onChange={e => setTypeFilter(e.target.value)}
            >
              {["Any", "View", "Application", "Page"]?.map(item => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Grid item direction='column' justifyContent='center' alignItems='center' xs={2}>
          <Grid item xs={12}>
            <TextField
              variant='outlined'
              select
              label={"Visibility"}
              fullWidth
              size='small'
              defaultValue={"Any"}
              onChange={e => setVisibilityFilter(e.target.value)}
            >
              {["Any", "Private", "Public"]?.map(item => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </Grid>
      <TableContainer style={{ backgroundColor: palette.background.paper }}>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {!isImport && <TableCell style={{ backgroundColor: palette.background.paper, minWidth: 100 }}>Actions</TableCell>}

              {columns.map(column => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, backgroundColor: palette.background.paper }}
                >
                  {column.label}
                </TableCell>
              ))}
              {isImport && <TableCell style={{ backgroundColor: palette.background.paper }} />}
            </TableRow>
          </TableHead>
          <TableBody>
            {entities?.length === 0 && (
              <TableRow>
                <TableCell colSpan={columns.length + 1} align={"center"}>
                  No templates yet
                </TableCell>
              </TableRow>
            )}
            {entities?.map((row: any) => {
              if (row.id === templateId && isImport) return <></>;
              return (
                <TableRow hover role='checkbox' tabIndex={-1} key={row.code}>
                  {!isImport && (
                    <TableCell sx={{ width: 130, display: "flex", justifyContent: "flex-start" }}>
                      <DialogActions sx={{ padding: 0 }}>
                        <IconButton
                          onClick={() => {
                            mutateTemplateById(row.id, {
                              onSuccess: () => {
                                setOpenEditModal(true);
                              },
                            });
                          }}
                        >
                          <IconPencil color={palette.primary.main} size={18} />
                        </IconButton>
                        <Tooltip title='preview'>
                          <IconButton onClick={() => onRedirectToTemplate(row.id)}>
                            <IconEye size={18} />
                          </IconButton>
                        </Tooltip>
                        <BXModal
                          maxWidth={"md"}
                          icon={<IconHistory height={20} width={20} style={{ padding: 0 }} />}
                          buttonProps={{
                            startIcon: <IconHistory height={20} width={20} style={{ padding: 0 }} />,
                          }}
                          withoutLabel
                          title={`History -> ${row?.name}`}
                          label='History'
                        >
                          {(handleClose: any) => (
                            <TemplateHistory backupTemplate={handleBackupClick} template={row} handleClose={handleClose} />
                          )}
                        </BXModal>

                        <Box marginInlineStart={1}>
                          <BXConfirmationDialog
                            title={"Are you sure you want to delete this template?"}
                            iconButton
                            buttonProps={{
                              color: "error",
                              children: <IconTrashX height={20} width={20} style={{ padding: 0 }} />,
                            }}
                            onConfirm={() => {
                              deleteTemplate(row);
                            }}
                          />
                        </Box>
                      </DialogActions>
                    </TableCell>
                  )}

                  {columns.map(column => {
                    const value = _.get(row, column.id);
                    return (
                      <TableCell key={column.id}>
                        <Grid container alignItems={"center"}>
                          {value}
                        </Grid>
                      </TableCell>
                    );
                  })}
                  {isImport && (
                    <TableCell>
                      <Button
                        variant='contained'
                        startIcon={<IconPlus />}
                        onClick={() => {
                          if (setOpenTemplateList && setSelectedRow && setOpenReplaceModal && addTemplate) {
                            if (row?.type != defaultType) {
                              enqueueSnackbarRef?.(`You can't import ${row?.type} template inside ${defaultType} builder`, {
                                variant: "error",
                              });
                            } else {
                              setOpenTemplateList(false);
                              setSelectedRow(row);
                              if (!isEmptyView) setOpenReplaceModal(true);
                              else addTemplate(row, true);
                            }
                          }
                        }}
                      >
                        Add
                      </Button>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
            {openEditModal && (
              <CreateTemplateModal
                open={openEditModal}
                isUpdate={true}
                isLoading={isLoading}
                defaultName={templateData?.name}
                templateID={templateData?.id}
                defaultVisibility={templateData?.visibility}
                defaultType={templateData?.type}
                onSubmit={updateTemplate}
                openViewBuilder={() => onRedirectToTemplate(templateData?.id)}
                onClose={() => {
                  setOpenEditModal(false);
                }}
              />
            )}
            {(isFetching || hasNextPage) && (
              <TableRow ref={sentryRef} sx={{ width: "100%", justifyContent: "center" }}>
                <TableCell colSpan={columns.length + 1 || 1} style={{ textAlign: "center" }}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default TemplatesTable;
