/* eslint-disable no-lone-blocks */
import { FC } from "react";
import { produceTrigger } from "src/features/appState/appStateSlice";
import { useAppState } from "src/features/appState/hooks";
import store from "src/store/store";
import { generateKeyDescriptor } from "src/utils/generalUtils";
import { ComponentItemType, StepperComponentState } from "src/views/pages/BuildX/FormBuilder/types";
import { stepperGroupComponentState } from "src/views/pages/BuildX/FormBuilder/utils";
import { RenderChildren } from "./RenderChildren";
import { RepeatedItemsComponent } from "./RepeatedItemsComponent";

export const triggerActionableComponent = async (event, pageId, viewName, key, viewsState) => {
  const elementKey = `${pageId}.${viewName}.${key}`;
  await new Promise((resolve, reject) => {
    store.dispatch(
      produceTrigger({
        name: elementKey,
        type: "action",
        eventPayload: { resolver: resolve, actionEvent: event },
      })
    );
  });
};

export const handleKeyDown = (event, element, actionHandlerMapper, pageId, viewName, viewsState) => {
  const keyDescriptor = generateKeyDescriptor(event);
  const onKeyPressTriggers = element.interactionConfig?.filter(
    interaction => interaction.type === "OnKeyPress" && interaction?.key && interaction?.key === keyDescriptor
  );

  onKeyPressTriggers?.forEach((trigger, index) => {
    if (trigger?.triggerSelf === false) {
      triggerActionableComponent(event, pageId, viewName, trigger?.actionableKey, viewsState);
    } else {
      const actionHandler = actionHandlerMapper();
      actionHandler?.();
    }
  });
};

export const handleChange = (event, element, onChange, actionHandlerMapper, pageId, viewName, viewsState, disabledDirty) => {
  if (!element?.config?.isGroup) {
    onChange(event, disabledDirty);
  }

  if (element?.type == "CustomAutoCompleteBX") {
    if (event?.noChange) {
      return;
    }
  }

  const onChangeTriggers = element.interactionConfig?.filter(interaction => interaction.type === "OnChange");

  onChangeTriggers?.forEach((trigger, index) => {
    if (trigger?.triggerSelf === false) {
      triggerActionableComponent(event, pageId, viewName, trigger?.actionableKey, viewsState);
    } else {
      const actionHandler = actionHandlerMapper();
      actionHandler?.();
    }
  });
};

export const handleClick = (event, element, pageId, viewName, viewsState) => {
  const onClickTriggers = element.interactionConfig?.filter(interaction => interaction.type === "OnClick");
  onClickTriggers?.forEach((trigger, index) => {
    if (trigger?.triggerSelf === false) {
      triggerActionableComponent(event, pageId, viewName, trigger?.actionableKey, viewsState);
    }
  });
};
export const handleLoad = (element, actionHandlerMapper, pageId, viewName, viewsState) => {
  const onLoadTriggers = element.interactionConfig?.filter(interaction => interaction.type === "OnLoad");

  onLoadTriggers?.forEach(trigger => {
    if (trigger?.triggerSelf === false) {
      const loadEvent = {
        type: "OnLoad",
      };
      triggerActionableComponent(loadEvent, pageId, viewName, trigger?.actionableKey, viewsState);
    } else {
      const actionHandler = actionHandlerMapper();
      if (typeof actionHandler === "function") {
        actionHandler();
      }
    }
  });
};

export const getControllerNameFallback = (element: any) => {
  switch (element?.type) {
    case ComponentItemType.CustomTabs: {
      return element?.config?.parent?.key;
    }
    default: {
      return "";
    }
  }
};

export const RenderItems: FC<any> = props => {
  const {
    data,
    pageId,
    __data,
    info,
    item,
    currentApp,
    viewsState,
    multiLingual,
    index: itemIndex,
    referenceStepperGroups,
    parentKey,
  } = props;
  const { watch } = useAppState();
  const key = `${pageId}.${info?.viewName}._BX_stepperGroups`;

  return (
    <>
      {!!data?.length &&
        data?.map((element: any, index: number) => {
          if (element?.config?.repeated?.enabled) {
            return (
              <RepeatedItemsComponent
                key={element?.id + "-" + index + "-" + itemIndex}
                parentKey={parentKey}
                element={element}
                item={item}
                viewsState={viewsState}
                pageId={pageId}
                __data={__data}
                props={props}
                currentApp={currentApp}
                multiLingual={multiLingual}
                referenceStepperGroups={referenceStepperGroups}
                viewName={info?.viewName}
              />
            );
          }
          const componentStepperState = stepperGroupComponentState(element?.props?.key, watch(key) || referenceStepperGroups);

          const hiddenByStepper = componentStepperState === StepperComponentState.FoundButNotSelected;

          return (
            <RenderChildren
              parentKey={parentKey}
              key={element?.id + "-" + index}
              element={element}
              index={itemIndex}
              hiddenByStepper={hiddenByStepper}
              {...props}
            />
          );
        })}
    </>
  );
};
