import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useEffect, useRef, useState } from "react";
import { useBXContext } from "src/BXEngine/BXContext";
import BuilderRow from "./BuilderRow";

type Props = {
  id?: any;
  withDivider?: boolean;
  rows?: any;
  selectedData?: any;
  headerName: string;
  modal?: any;
  type?: string;
  withMove?: boolean;
  moveApp: (dragIndex: any, hoverIndex: number) => void;
  updateApp: (dragIndex: any, hoverIndex: number) => void;
  onConfigClick?: (prop?: any) => void;
  onUserClick?: (prop?: any) => void;
  onTrashClick?: (prop?: any) => void;
  onSSOClick?: (prop?: any) => void;
  onLanguageClick?: (prop?: any) => void;
  onEditClick?: (prop?: any) => void;
  onViewBuilderClick?: (prop?: any) => void;
  onLayoutClick?: (prop?: any) => void;
  onMoveClick?: any;
  onDuplicateClick?: (prop?: any) => void;
  onExportClick?: (prop?: any) => void;
  onSelect?: (prop?: any) => void;
  handleSyncClick?: (prop?: any) => void;
};

const BuilderColumn = (props: Props) => {
  const {
    id,
    rows,
    selectedData,
    headerName,
    modal,
    type,
    withMove,
    onSelect,
    updateApp,
    moveApp,
    onConfigClick,
    onEditClick,
    onViewBuilderClick,
    onLayoutClick,
    onTrashClick,
    onSSOClick,
    onLanguageClick,
    onUserClick,
    onMoveClick,
    onDuplicateClick,
    onExportClick,
    handleSyncClick,
    withDivider = true,
  } = props;

  const [selectedRow, setSelectedRow] = useState<any>();
  const leftDrawerOpened = useBXContext();
  const selectedRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setSelectedRow(selectedData);
  }, [selectedData]);
  useEffect(() => {
    if (selectedRef.current) {
      selectedRef.current.scrollIntoView({ behavior: "auto", block: "center" });
    }
  }, []);

  const BuilderColumnHeader = () => {
    return (
      <>
        <Grid container alignItems='center' paddingX={1}>
          <Grid item xs>
            <Typography fontSize={16} fontWeight={600}>
              {headerName}
            </Typography>
          </Grid>
          <Grid item>{modal}</Grid>
        </Grid>
        <Divider style={{ marginTop: 10 }} />
      </>
    );
  };

  return (
    <Grid item xs={3} height='100%'>
      <BuilderColumnHeader />
      <Grid container height='92%'>
        <Grid
          xs
          flexDirection={"column"}
          height={leftDrawerOpened ? "calc(100% - 280px)" : "calc(100vh - 280px)"}
          marginY={2}
          marginInlineEnd={withDivider ? 1 : 0}
          style={{
            overflowY: "auto",
            overflowX: "auto",
            marginInlineEnd: "0px",
            paddingInlineEnd: "10px",
            height: "100%",
          }}
          marginX={headerName == "Apps" ? 0 : 2}
        >
          {!!rows?.length &&
            rows.map((item: any, index: any) => (
              <div ref={item?.id === selectedData?.id ? selectedRef : null}>
                <BuilderRow
                  key={item?.id}
                  item={item}
                  index={index}
                  isShared={item?.isShared}
                  isGenericPage={item?.isGenericPage}
                  withMove={withMove}
                  withEye={type == "view"}
                  type={type}
                  onMoveClick={onMoveClick}
                  isHidden={item?.info?.visibility == "Hidden"}
                  selectedRow={selectedRow}
                  setSelectedRow={(_item: any) => {
                    onSelect?.(_item);
                    setSelectedRow(_item);
                  }}
                  updateApp={updateApp}
                  moveApp={moveApp}
                  handleSyncClick={handleSyncClick}
                  headerName={`${headerName}-${id}`}
                  onConfigClick={onConfigClick && (() => onConfigClick(item))}
                  onUserClick={onUserClick && (() => onUserClick(item))}
                  onTrashClick={onTrashClick && (() => onTrashClick(item))}
                  onSSOClick={onSSOClick && (() => onSSOClick(item))}
                  onLanguageClick={onLanguageClick && (() => onLanguageClick(item))}
                  onEditClick={onEditClick && (() => onEditClick(item))}
                  onViewBuilderClick={onViewBuilderClick && (() => onViewBuilderClick(item))}
                  onLayoutClick={onLayoutClick && (() => onLayoutClick(item))}
                  onDuplicateClick={onDuplicateClick && (() => onDuplicateClick(item))}
                  onExportClick={onExportClick && (() => onExportClick(item))}
                />
              </div>
            ))}
        </Grid>
        {withDivider && (
          <Grid item>
            <Divider orientation='vertical' style={{ height: "95%", transform: "translateX(0px)" }} />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default BuilderColumn;
