import SettingsIcon from "@mui/icons-material/Settings";
import {
  Box,
  Button,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { IconCopy, IconSettingsCode, IconTrashX } from "@tabler/icons-react";
import { useState } from "react";
import { FieldValues, useFieldArray, useForm } from "react-hook-form";
import BXModal from "src/components/BXUI/Modal";
import DraggableRow from "../AppBuilder/forms/DraggableRow";
import { ActionConfigContent } from "./components/ComponentEditor/ActionConfigContent";

export const ChainActionsConfig = ({
  item,
  view,
  onSave,
  views,
  actions,
  isLoadingForEntireChain,
  onLoadingChange,
  selectedValue,
  onDeleteAction,
  appId,
  collectionId,
  pageId,
}) => {
  const [isActionConfigModalOpen, setIsActionConfigModalOpen] = useState(false);
  const [selectedActionIndex, setSelectedActionIndex] = useState<any>(null);

  const { control, setValue, watch } = useForm<FieldValues>({
    defaultValues: {
      actions: actions,
    },
  });

  const { append, remove, fields, move } = useFieldArray<FieldValues>({
    control,
    name: "actions",
  });

  const element = item;

  const handleOpenModal = index => {
    setSelectedActionIndex(index);
    setIsActionConfigModalOpen(true);
  };

  const getTypeLabel = index => {
    const type = watch(`actions`)?.[index]?.actionConfig?.actionSourceType?.type;
    return type === "form-builder" ? "View" : type;
  };

  const getActionRelevantInfo = index => {
    const isView =
      watch(`actions`)?.[index]?.actionConfig?.actionSourceType?.type !== "API" &&
      watch(`actions`)?.[index]?.actionConfig?.actionSourceType?.type !== "Download" &&
      watch(`actions`)?.[index]?.actionConfig?.actionSourceType?.type !== "Link" &&
      watch(`actions`)?.[index]?.actionConfig?.actionSourceType?.type !== "Copy" &&
      watch(`actions`)?.[index]?.actionConfig?.actionSourceType?.type !== "None" &&
      watch(`actions`)?.[index]?.actionConfig?.actionSourceType?.type !== "Dismiss" &&
      watch(`actions`)?.[index]?.actionConfig?.actionSourceType?.type !== "Refresh Views" &&
      watch(`actions`)?.[index]?.actionConfig?.actionSourceType?.type !== "Reset Form" &&
      watch(`actions`)?.[index]?.actionConfig?.actionSourceType?.type !== "BuildX Login" &&
      watch(`actions`)?.[index]?.actionConfig?.actionSourceType?.type !== "Add item" &&
      watch(`actions`)?.[index]?.actionConfig?.actionSourceType?.type !== "Delete item" &&
      watch(`actions`)?.[index]?.actionConfig?.actionSourceType?.type !== "Clean Dirty" &&
      watch(`actions`)?.[index]?.actionConfig?.actionSourceType?.type !== "Update item" &&
      watch(`actions`)?.[index]?.actionConfig?.actionSourceType?.type !== "Timer" &&
      watch(`actions`)?.[index]?.actionConfig?.actionSourceType?.type !== "Stop Workflow" &&
      watch(`actions`)?.[index]?.actionConfig?.actionSourceType?.type !== "Dismiss";

    if (isView) {
      const viewId = watch(`actions`)?.[index]?.actionConfig?.actionSourceViewType?.id;
      const view = views?.find(view => view.id === viewId);
      return view?.info?.name;
    }

    const actionRelevantInfo = {
      API: watch(`actions`)?.[index]?.actionConfig?.source,
      Download: watch(`actions`)?.[index]?.actionConfig?.uri,
      Copy: watch(`actions`)?.[index]?.actionConfig?.copySource,
      Link: watch(`actions`)?.[index]?.actionConfig?.linkUrl,
    };

    const viewType = watch(`actions`)?.[index]?.actionConfig?.actionSourceType?.type;
    return actionRelevantInfo[viewType] || "N/A";
  };

  const moveAction = (dragIndex: number, dropIndex: number) => {
    move(dragIndex, dropIndex);
  };

  const reorderActions = () => {
    onSave(watch("actions"));
  };

  const handleCopyAction = index => {
    const currentAction = watch(`actions[${index}]`);
    if (currentAction) {
      const newAction = JSON.parse(JSON.stringify(currentAction));

      append(newAction);
      onSave(watch("actions"));
    }
  };

  return (
    <>
      <Box width={"100%"} display={"flex"} justifyContent={"start"} alignItems={"center"}>
        <Typography sx={{ fontSize: "17px" }}>Enable loading state for all actions</Typography>
        <Switch
          size='medium'
          checked={!!isLoadingForEntireChain?.[selectedValue]}
          onChange={e => {
            onLoadingChange?.(e);
          }}
          inputProps={{ "aria-label": "loading-chain" }}
        />
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label='action configuration table'>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "25%" }}>Condition</TableCell>
              <TableCell sx={{ width: "25%" }}>Type</TableCell>
              <TableCell sx={{ width: "45%" }}>Info</TableCell>
              <TableCell align='center' sx={{ width: "2%" }}></TableCell>
              {/* <TableCell align='center' sx={{ width: "2%" }}></TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((item, index) => {
              const actionRow = (
                <DraggableRow
                  component={TableRow}
                  key={item.id}
                  id={item.id}
                  index={index}
                  name='table-row'
                  updateElement={() => reorderActions()}
                  moveElement={moveAction}
                >
                  <TableCell>
                    <Typography>
                      {watch(`actions[${index}]`)?.actionConfig?.actionCondition ??
                        watch(`actions[${index}]`)?.actionConfig?.condition ??
                        "None"}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography>{getTypeLabel(index) || "N/A"}</Typography>
                  </TableCell>

                  <TableCell>
                    <Typography>{getActionRelevantInfo(index)}</Typography>
                  </TableCell>

                  <TableCell align='center' sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "5px" }}>
                    <IconButton onClick={() => handleOpenModal(index)}>
                      <IconSettingsCode />
                    </IconButton>
                    {selectedActionIndex === index && isActionConfigModalOpen && (
                      <BXModal
                        open={true}
                        icon={<SettingsIcon />}
                        maxWidth='md'
                        title='Action Configuration'
                        onClose={() => setIsActionConfigModalOpen(false)}
                      >
                        <ActionConfigContent
                          views={views}
                          view={view}
                          item={element}
                          getAction={watch}
                          index={index}
                          appId={appId}
                          collectionId={collectionId}
                          pageId={pageId}
                          onActionConfigSave={data => {
                            const actionKey = `actions[${index}]`;
                            setValue(actionKey, data);
                            onSave(watch("actions"));
                            setIsActionConfigModalOpen(false);
                            setSelectedActionIndex(null);
                          }}
                          selectedChainOfActions={selectedValue}
                        />
                      </BXModal>
                    )}
                    <IconButton onClick={() => handleCopyAction(index)}>
                      <IconCopy />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        onDeleteAction(index);
                        remove(index);
                      }}
                    >
                      <IconTrashX color='red' />
                    </IconButton>
                  </TableCell>
                </DraggableRow>
              );

              if (!getTypeLabel(index)?.includes("Loading")) {
                return actionRow;
              }

              if (!isLoadingForEntireChain?.[selectedValue]) {
                return actionRow;
              }

              return null;
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 3, mb: 2 }}>
        <Button
          variant='contained'
          color='primary'
          onClick={() => {
            append({ actionConfig: {} });
            onSave(watch("actions"));
          }}
          sx={{ mr: 2 }}
        >
          Add Action
        </Button>
      </Box>
    </>
  );
};
