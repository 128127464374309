import axios, { Method } from "axios";
import _, { PropertyPath } from "lodash";
import {
  createContext,
  Dispatch,
  FC,
  MutableRefObject,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { osVersion } from "react-device-detect";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { useLocation, useNavigate } from "react-router-dom";
import { isHostAvailable } from "src/App";
import { useCheckPermissions } from "src/components/PermissionValidation";
import { setSession } from "src/contexts/JWTContext";
import useAuth from "src/hooks/useAuth";
import { BXApp, BXAppCollection, BXEnv } from "src/types/BXAppType";
import { ElementDataSource, IViewState, UIElement } from "src/types/UIElement";
import axiosServices from "src/utils/axios";
import externalAxiosServices from "src/utils/axiosExt";
import { getAuthorizationHeader, removeEmptyQueryParams } from "src/utils/generalUtils";
import { enqueueSnackbarRef } from "src/utils/SnackbarUtilsConfigurator";

import { ApolloClient, ApolloLink, ApolloProvider, gql, HttpLink, InMemoryCache } from "@apollo/client";
import { loadStripe } from "@stripe/stripe-js/pure";
import { useDispatch, useSelector } from "react-redux";
import { useReplaceDataPlaceholders } from "src/components/BXUI/DataTable/ActionButton";
import { setPaginationInfo } from "src/features/appState/appStateSlice";
import { useAppState } from "src/features/appState/hooks";
import { RootState } from "src/store/store";
import { decompressData } from "src/utils/services";
import { isLanguageRTL } from "src/views/pages/BuildX/AppBuilder/forms/ManageLanguages";
import { v4 as uuidv4 } from "uuid";
import { UserProfile } from "../_mockApis/user-profile/types";
import config, { adminPaths, formBuilderPath, pageBuilderPath } from "../config";
import { useBXBuilderContext } from "./BXBuilderContext";
import { BXPermissionList } from "./types";

type BXContextProps = {
  appDescriptor: BXApp[];
  allowedApps: BXApp[];
  setAppDescriptor: Dispatch<any>;
  fqdnApp: BXApp | undefined;
  setFqdnApp: Dispatch<any>;
  currentApp?: BXApp;
  setCurrentApp: Dispatch<any>;
  multiLingual?: any;
  setMultiLingual?: Dispatch<any>;
  envs?: BXEnv[];
  setEnvs: Dispatch<any>;
  addApp: (app: BXApp) => void;
  setValue?: Function;
  getValue?: Function;
  loadingApps?: boolean;
  getAuth: (appId: string, profileId?: string, noProfile?: boolean) => any;
  registerAppDevice?: (appId: string, deviceApi?: any, fqdnApp?: any) => any;
  loginToApp?: (
    appId: string,
    email?: string,
    password?: string,
    recaptcha?: string,
    profileName?: string,
    cb?: () => void,
    profile?: any,
    _authApi?: any,
    fqdn?: any,
    loginType?: string,
    axiosParams?: any,
    disableAutoNavigate?: boolean
  ) => any;
  logoutOfApp?: (appId: string, isFQDN?: boolean) => void;
  loadApps?: (fromLogin?: boolean) => void;
  loadPermissions?: (userId?: string | number) => void;
  isAdministrationMode: boolean;
  isSwitchingMode: boolean;
  setIsSwitchingMode: (value: boolean) => void;
  setIsAdministrationMode: (value: boolean) => void;
  appProfiles: { [key: string]: any };
  setAppProfiles: Dispatch<any>;
  appTokens: { [key: string]: any };
  currentProfileId?: string;
  setCurrentProfileId: Dispatch<any>;
  setLoadingApps: Dispatch<any>;
  viewStacks: UIElement[];
  setViewStacks: Dispatch<React.SetStateAction<UIElement[]>>;
  isRTL: boolean;
  setIsRTL: Dispatch<any>;
  updateRTL: Function;
  viewsState: IViewState;
  loadingViews: any;
  setLoadingViews: Dispatch<any>;
  setViewsState: Dispatch<React.SetStateAction<IViewState>>;
  queriesStateGraphQL?: any;
  setQueriesStateGraphQL: Dispatch<React.SetStateAction<UIElement[]>>;
  selectedAppId: any;
  setSelectedAppId: any;
  selectedCollectionId: any;
  setSelectedCollectionId: any;
  selectedPageId: any;
  selectedViewId: any;
  setSelectedViewId: any;
  setSelectedPageId: any;
  leftDrawerOpened: any;
  clientSecrets: any;
  stripePromises: any;
  stripeAppearance: any;
  setStripeAppearance: any;
  stripeEndpoints: any;
  setStripeEndpoints: any;
  setLeftDrawerOpened: any;
  checkAppAuth: boolean;
  unprotectedPages: MutableRefObject<Set<string>>;
  appRoutesMap: MutableRefObject<any>;
  currentProfilePermissions?: BXPermissionList | null;
  checkPermissions: (requiredPermissions: { path: string; action: string }[], checkAll?: boolean) => boolean;
  isConfirmAction: any;
  setIsConfirmAction: Dispatch<React.SetStateAction<any>>;
  actionConfirmControl: any;
  setActionConfirmControl: Dispatch<React.SetStateAction<any>>;
};

export const BXContext = createContext<BXContextProps>({
  appDescriptor: [],
  allowedApps: [],
  setCurrentApp: _.noop,
  setMultiLingual: _.noop,
  setEnvs: _.noop,
  fqdnApp: undefined,
  setFqdnApp: _.noop,
  setAppDescriptor: _.noop,
  addApp: _.noop,
  currentApp: undefined,
  multiLingual: undefined,
  envs: undefined,
  setValue: _.noop,
  getValue: _.noop,
  getAuth: _.noop,
  loadingApps: false,
  isAdministrationMode: false,
  isSwitchingMode: false,
  setIsSwitchingMode: _.noop,
  setIsAdministrationMode: _.noop,
  isRTL: false,
  setIsRTL: _.noop,
  updateRTL: _.noop,
  appProfiles: {},
  setAppProfiles: _.noop,
  appTokens: {},
  currentProfileId: undefined,
  setCurrentProfileId: _.noop,
  setLoadingApps: _.noop,
  viewStacks: [],
  setViewStacks: _.noop,
  viewsState: {},
  setViewsState: _.noop,
  loadingViews: {},
  setLoadingViews: _.noop,
  loadPermissions: fromLogin => {},
  selectedAppId: undefined,
  setSelectedAppId: undefined,
  selectedCollectionId: undefined,
  setSelectedCollectionId: undefined,
  selectedPageId: undefined,
  selectedViewId: undefined,
  setSelectedViewId: _.noop,
  setSelectedPageId: undefined,
  currentProfilePermissions: undefined,
  checkPermissions: () => false,
  checkAppAuth: false,
  queriesStateGraphQL: {},
  setQueriesStateGraphQL: _.noop,
  unprotectedPages: { current: new Set() },
  appRoutesMap: { current: {} },
  leftDrawerOpened: false,
  clientSecrets: null,
  stripePromises: null,
  setStripeAppearance: _.noop,
  stripeAppearance: null,
  stripeEndpoints: [],
  setStripeEndpoints: _.noop,
  setLeftDrawerOpened: _.noop,
  isConfirmAction: null,
  setIsConfirmAction: _.noop,
  actionConfirmControl: {},
  setActionConfirmControl: _.noop,
});

const dynamicUriLink = new ApolloLink((operation, forward) => {
  const context = operation.getContext();
  const { uri, apolloHeaders } = context;

  const httpLink = new HttpLink({
    uri: uri || "",
  });

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      ...apolloHeaders,
    },
  }));

  return httpLink.request(operation, forward);
});

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const apolloClient = new ApolloClient({
  link: dynamicUriLink,
  cache: new InMemoryCache(),
});

/**
 *
 * @param children
 * @param appDescriptor
 * @constructor
 */
export const BXContextProvider: FC<{ appDescriptor?: BXApp[]; children?: ReactNode; setLoginFlag: any }> = ({
  children,
  appDescriptor: description,
  setLoginFlag,
}) => {
  const [isRTL, setIsRTL] = useState<boolean>(false);
  const [appTokens, setAppTokens] = useState<{ [key: string]: any }>({});
  const [currentProfileId, setCurrentProfileId] = useState();
  const [currentProfilePermissions, setCurrentProfilePermissions] = useState<BXPermissionList | null>(null);
  const [appDescriptor, setAppDescriptor] = useState<BXApp[]>(description || []);
  const [fqdnApp, setFqdnApp] = useState<BXApp>();
  const [appProfiles, setAppProfiles] = useState<{ [key: string]: any }>({});
  const [loadingApps, setLoadingApps] = useState<boolean>(true);
  const [currentApp, setCurrentApp] = useState<BXApp>();
  const [multiLingual, setMultiLingual] = useState<any>();
  const [envs, setEnvs] = useState<BXEnv[]>();
  const [isAdministrationMode, setIsAdministrationMode] = useState(false);
  const [isSwitchingMode, setIsSwitchingMode] = useState(false);
  const [viewStacks, setViewStacks] = useState<UIElement[]>([]);
  const [viewsState, setViewsState] = useState<IViewState>({});
  const [loadingViews, setLoadingViews] = useState<any>({});
  const [selectedAppId, setSelectedAppId] = useState<any>();
  const [selectedCollectionId, setSelectedCollectionId] = useState<any>();
  const [selectedPageId, setSelectedPageId] = useState<any>();
  const [selectedViewId, setSelectedViewId] = useState<any>();
  const [queriesStateGraphQL, setQueriesStateGraphQL] = useState({});
  const { replaceDataPlaceholdersRecursively } = useReplaceDataPlaceholders();
  const [leftDrawerOpened, setLeftDrawerOpened] = useState(true);
  const [stripePromises, setStripePromises] = useState<{ id: string; stripePromise: any }[]>([]);
  const [stripeAppearance, setStripeAppearance] = useState<any>(null);
  const [clientSecrets, setClientSecrets] = useState<{ id: string; clientSecret: string | null }[]>([]);
  const [stripeEndpoints, setStripeEndpoints] = useState<
    { id: string; publishableKeyEndpoint: string | null; clientSecretKeyEndpoint: string | null }[]
  >([]);
  //State stored in BX context for managing the current action to be confirmed and its data/methods
  const [isConfirmAction, setIsConfirmAction] = useState<any>(null);
  const [actionConfirmControl, setActionConfirmControl] = useState<any>({});

  const navigate = useNavigate();
  const location = useLocation();
  const { isSuperAdmin, user } = useAuth();
  const { editApp } = useBXBuilderContext();

  const defaultPermissions = useRef({ ALLOW: {}, DENY: {} });
  const unprotectedPages = useRef<Set<string>>(new Set());

  const appRoutesMap = useRef<any>({});

  useEffect(() => {
    if (stripeEndpoints.length > 0) {
      const updatedStripePromises = [...stripePromises];
      const updatedClientSecrets = [...clientSecrets];

      stripeEndpoints.forEach(async ({ id, publishableKeyEndpoint, clientSecretKeyEndpoint }) => {
        if (publishableKeyEndpoint && clientSecretKeyEndpoint) {
          const publishableResponse = await fetch(publishableKeyEndpoint);
          const { publishableKey } = await publishableResponse.json();
          loadStripe.setLoadParameters({ advancedFraudSignals: false });
          const stripePromise = await loadStripe(publishableKey);

          const existingStripePromise = updatedStripePromises.find(entry => entry.id === id);
          if (existingStripePromise) {
            existingStripePromise.stripePromise = stripePromise;
          } else {
            updatedStripePromises.push({ id, stripePromise });
          }

          const secretResponse = await fetch(clientSecretKeyEndpoint, {
            method: "POST",
            body: JSON.stringify({}),
          });
          const { clientSecret } = await secretResponse.json();

          const existingClientSecret = updatedClientSecrets.find(entry => entry.id === id);
          if (existingClientSecret) {
            existingClientSecret.clientSecret = clientSecret;
          } else {
            updatedClientSecrets.push({ id, clientSecret });
          }
        }

        setStripePromises(updatedStripePromises);
        setClientSecrets(updatedClientSecrets);
      });
    }
  }, [stripeEndpoints]);

  useEffect(() => {
    if (!loadingApps) {
      if (isAdministrationMode) {
        if (
          adminPaths.includes(location.pathname) ||
          location.pathname.startsWith(formBuilderPath) ||
          location.pathname.startsWith(pageBuilderPath)
        ) {
          navigate({ pathname: location.pathname });
        } else {
          localStorage.setItem("app-location", location.pathname);
          const adminLocation = localStorage.getItem("admin-location");
          if (!adminLocation) {
            navigate({ pathname: "/buildx/app" });
          } else {
            navigate({ pathname: adminLocation });
          }
        }

        if (currentApp?.name) {
          document.title = currentApp.name;
        }
      } else {
        document.title = fqdnApp?.name || process.env.REACT_APP_NAME!;
        const appLocation = localStorage.getItem("app-location");

        if (!appLocation) {
          if (
            (!adminPaths.includes(location.pathname) && !location.pathname.startsWith(formBuilderPath)) ||
            !location.pathname.startsWith(pageBuilderPath)
          ) {
            localStorage.setItem("admin-location", "/buildx/app");
            navigate({ pathname: "/buildx/app" });
          }
        } else {
          if (adminPaths.includes(location.pathname)) {
            localStorage.setItem("admin-location", location.pathname);
          }
          navigate({ pathname: appLocation });
        }
      }
    }
  }, [isAdministrationMode]);

  useEffect(() => {
    if (currentApp?.id && currentApp?.multilingualId) {
      axios
        .get(`${process.env.REACT_APP_WITH_BUCKET_ENDPOINT}/i18n/${currentApp?.id}.json`)
        .then(({ data }) => {
          if (data.errorCode === "MULTILINGUAL_NOT_FOUND") {
            setMultiLingual(null);
          } else {
            setMultiLingual(data);
          }
        })
        .catch(error => {
          setMultiLingual(null);
          console.error("Error fetching multilingual data:", error);
        });
    }
  }, [currentApp?.id, currentApp?.multilingualId]);

  const { checkPermissions } = useCheckPermissions(currentProfilePermissions || defaultPermissions.current);

  const setAppSession = (appId: string, accessToken?: string | null, user?: UserProfile | null, profile?: any, isFQDN?: boolean) => {
    if (profile?.id) {
      localStorage.setItem(appId + "-profileId", profile?.id);
      setCurrentProfileId(profile?.id);
    }

    if (accessToken) {
      setAppTokens((previous = {} as any) => {
        const prev = { ...previous };
        prev[appId + `-${profile?.id}-accessToken`] = accessToken;
        prev[appId + `-${profile?.id}-user`] = user;
        return prev;
      });

      localStorage.setItem(appId + `-${profile?.id}-accessToken`, accessToken);
      localStorage.setItem(appId + `-${profile?.id}-user`, JSON.stringify(user));
    } else {
      setAppTokens((previous = {} as any) => {
        const prev = { ...previous };
        delete prev[appId + `-${currentProfileId}-accessToken`];
        delete prev[appId + `-${currentProfileId}-user`];
        return prev;
      });

      localStorage.removeItem(appId + `-${currentProfileId}-accessToken`);
      localStorage.removeItem(appId + `-${currentProfileId}-user`);
      // localStorage.removeItem(appId + `-profileId`);

      if (isFQDN) {
        localStorage.removeItem(`accessToken`);
        localStorage.removeItem(`user`);
      }
    }
  };
  const allowedApps = useMemo(
    () => appDescriptor.filter(app => checkPermissions([{ path: app.name, action: "VIEW" }])),
    [appDescriptor, checkPermissions]
  );

  const loadApps = async (fromLogin?: boolean, disableAutoNavigate?: boolean) => {
    setLoadingApps(true);
    axiosServices
      .get("/application")
      .then(async res => {
        try {
          let apps: BXApp[] = res.data?.map?.((col: any) => {
            const newApp = { ...col?.app };
            if (newApp.appConfig) {
              newApp.appConfig = decompressData(col?.app?.appConfig);
            }
            if (newApp.templateConfig) {
              newApp.templateConfig = decompressData(col?.app?.templateConfig);
            }
            return newApp;
          });
          setAppDescriptor(apps);
          const _currentApp =
            apps?.find(
              (app?: BXApp) => app?.id === location.pathname.split("/")?.[3] || app?.slug === "/" + location.pathname.split("/")?.[1]
            ) || apps?.[0];

          setCurrentApp(_currentApp);

          if (apps?.length && fromLogin) {
            const firstApp = apps?.[0];
            const firstCollectionToHavePages = firstApp.templateConfig?.collections?.find(
              (collection: BXAppCollection) => collection.pages.length > 0
            );

            const collectionSlug = fqdnApp ? "" : firstApp?.slug;
            const landingPageUri = currentApp?.appConfig?.pageUri;

            const firstPageSlug = firstCollectionToHavePages?.pages?.filter(
              page => page?.info?.visibility !== "Hidden" && page?.unprotectedPage !== true
            )?.[0]?.slug;

            if (!disableAutoNavigate) {
              if (firstPageSlug) {
                navigate({
                  pathname: `${fqdnApp ? "" : firstApp.slug}${landingPageUri ?? `/${collectionSlug}/${firstPageSlug}`}`.replaceAll(
                    /\/+/g,
                    "/"
                  ),
                });
              } else {
                navigate({
                  pathname: `${fqdnApp ? "" : firstApp.slug}`.replaceAll(/\/+/g, "/"),
                });
              }
            }
          }

          if (!_currentApp) {
            setLoadingApps(false);
          }
        } catch (e) {
          setLoadingApps(false);
        }
      })
      .catch(() => {
        setLoadingApps(false);
      })
      .finally(() => {
        setLoadingApps(false);
      });
  };
  const loadPermissions = useCallback(async () => {
    setLoadingApps(true);
    return axiosServices
      .get(`/user/authorization-list`)
      .then(async res => {
        try {
          const permission = res.data;
          setCurrentProfilePermissions(permission);
        } catch (e) {}
      })
      .catch(() => {
        setLoadingApps(false);
      })
      .finally(() => {});
  }, [user?.id]);

  const addApp = (app: BXApp) => {
    setAppDescriptor(old => [app, ...old]);
  };

  const [data, setData] = useState<any>({});

  const updateRTL = (data?: any) => {
    const checkIsRTL = !isAdministrationMode && isLanguageRTL(data?.selectedLanguage || multiLingual?.selectedLanguage);
    setIsRTL(checkIsRTL);
    document.documentElement.dir = checkIsRTL ? "rtl" : "ltr";
  };

  useEffect(() => {
    updateRTL();
  }, [isAdministrationMode, currentApp, location, multiLingual?.selectedLanguage]);

  useEffect(() => {
    // if (_.isEmpty(data)) {;
    const extractedData = _.flatMapDeep(_.flatMapDeep(_.flatMapDeep(allowedApps, "templateConfig.collections"), "pages"), "views");
    const initialStateData = extractedData.reduce((prev, current) => {
      current = current as UIElement;
      let code = current?.dataSource?.simple;
      try {
        code = JSON.parse(current?.dataSource?.simple);
      } catch (e) {}
      return {
        ...prev,
        [current?.id]: current?.dataSource?.sourceType !== "SIMPLE" ? current?.dataSource?.url : code,
      };
    }, {});
    setData(initialStateData);
    // }
  }, [allowedApps, currentProfilePermissions]);

  useEffect(() => {
    if (currentApp?.id) {
      if (
        (location.pathname === "/buildx/app" ||
          location.pathname === "/buildx/users" ||
          location.pathname === "/buildx/policies" ||
          location.pathname === "/buildx/oas" ||
          location.pathname === "/buildx/env" ||
          location.pathname == "/buildx/component" ||
          location.pathname == "/buildx/templates" ||
          location.pathname == "/buildx/migrations" ||
          location.pathname.startsWith("/buildx/page-builder") ||
          location.pathname.startsWith("/buildx/form-builder")) &&
        currentApp?.name
      ) {
        document.title = currentApp?.name;
      }
      if (!currentApp?.appConfig?.auth?.authApi?.uri || currentApp?.appConfig?.auth?.type == "No Auth") return setLoadingApps(false);
      setLoadingApps(true);

      const _currentProfileId = localStorage.getItem(currentApp?.id + "-profileId");
      if (
        localStorage.getItem("admin-login") ||
        isHostAvailable ||
        (currentApp?.appConfig?.withProfiles && currentApp?.appConfig?.isSingleSignOn)
          ? true
          : !currentApp?.appConfig?.isSingleSignOn
      ) {
        axiosServices
          .get(`application/${currentApp.id}/profile`)
          .then(({ data }) => {
            if (data?.length) {
              if (!_currentProfileId) {
                localStorage.setItem(currentApp?.id + "-profileId", data?.[0]?.id);
              }
              setCurrentProfileId(_currentProfileId || data?.[0]?.id);
            }

            let count = 0;
            setAppProfiles(prev => {
              const newData = { ...prev };
              newData[currentApp.id] = data;
              if (
                !localStorage.getItem("admin-login") &&
                !isHostAvailable &&
                currentApp?.appConfig?.isSingleSignOn &&
                currentApp?.appConfig?.withProfiles &&
                currentApp?.appConfig?.enableGoogleSignIn
              ) {
                newData[currentApp.id] = [{ signInWithGoogle: true }, ...data];
                const profile = {} as any;
                const token = localStorage.getItem(currentApp?.id + `-${profile?.id}-accessToken`);
                const user = localStorage.getItem(currentApp?.id + `-${profile?.id}-user`);
                setAppTokens((previous = {} as any) => {
                  const prev = { ...previous };
                  prev[currentApp?.id + `-${profile?.id}-accessToken`] = token;
                  prev[currentApp?.id + `-${profile?.id}-user`] = JSON.parse(user || "{}");
                  return prev;
                });
              }
              data?.forEach(async (profile: any, index: number) => {
                try {
                  const token = localStorage.getItem(currentApp?.id + `-${profile?.id}-accessToken`);
                  const user = localStorage.getItem(currentApp?.id + `-${profile?.id}-user`);
                  count = count + 1;
                  if (!token || !user) {
                    await registerAppDevice?.(currentApp?.id);
                    await loginToApp?.(
                      currentApp?.id,
                      profile?.creds?.username,
                      profile?.creds?.password,
                      profile?.creds?.recaptcha,
                      profile?.creds?.profileName,
                      () => {
                        setIsSwitchingMode(false);
                      },
                      profile,
                      undefined,
                      undefined,
                      undefined,
                      {
                        skipAuthErrorClear: true,
                      }
                    );
                  } else {
                    setAppTokens((previous = {} as any) => {
                      const prev = { ...previous };
                      prev[currentApp?.id + `-${profile?.id}-accessToken`] = token;
                      prev[currentApp?.id + `-${profile?.id}-user`] = JSON.parse(user || "{}");
                      return prev;
                    });
                  }
                  if (data?.length - 1 == index) {
                    setLoadingApps(false);
                  }
                } catch (e) {
                  setLoadingApps(false);
                }
              });

              return newData;
            });
            if (!count) {
              setLoadingApps(false);
            }
          })
          .catch(() => {
            setLoadingApps(false);
          });
      } else {
        const profile = {} as any;
        const token = localStorage.getItem(currentApp?.id + `-${profile?.id}-accessToken`);
        const user = localStorage.getItem(currentApp?.id + `-${profile?.id}-user`);
        setAppTokens((previous = {} as any) => {
          const prev = { ...previous };
          prev[currentApp?.id + `-${profile?.id}-accessToken`] = token;
          prev[currentApp?.id + `-${profile?.id}-user`] = JSON.parse(user || "{}");
          return prev;
        });
        setLoadingApps(false);
      }
    }
  }, [currentApp?.id, currentApp?.appConfig?.auth?.authApi]);

  useEffect(() => {
    const { token } = getAuth(currentApp?.id!) || {};
    if (currentProfileId && currentApp?.id && token && !currentApp?.appConfig?.isSingleSignOn) {
      axios
        .get(`${replaceBaseUrl("/user/me", currentApp)}`, {
          headers: { ...getAuthorizationHeader(currentApp?.appConfig?.auth, token) },
        })
        .then(({ data }) => {
          setAppTokens((previous = {} as any) => {
            const prev = { ...previous };
            prev[currentApp?.id + `-${currentProfileId}-user`] = data;
            return prev;
          });

          localStorage.setItem(currentApp?.id + `-${currentProfileId}-user`, JSON.stringify(data));
        });
    }
  }, [currentProfileId, currentApp?.id]);

  const setValue = (newData: any, id: string) => setData((oldData: any) => ({ ...oldData, [`${id}`]: newData }));
  const getValue = useCallback(
    (id: string) => {
      if (_.isNil(id)) return null;
      return data?.[id];
    },
    [data]
  );

  const getAuth = (appId: string, _profileId?: string, noProfile?: boolean) => {
    const profileId = _profileId || (!noProfile && currentProfileId);
    const profile = profileId ? appProfiles?.[appId]?.find((profile: any) => profile?.id == profileId) : appProfiles?.[appId]?.[0];

    const token = appTokens[appId + `-${profileId || profile?.id}-accessToken`];
    const user = appTokens[appId + `-${profileId || profile?.id}-user`];

    if (
      (currentApp?.appConfig?.isSingleSignOn &&
        !localStorage.getItem("admin-login") &&
        !isHostAvailable &&
        !(currentApp?.appConfig?.withProfiles && currentApp?.appConfig?.isSingleSignOn)) ||
      (!_profileId &&
        currentApp?.appConfig?.withProfiles &&
        currentApp?.appConfig?.isSingleSignOn &&
        currentApp?.appConfig?.enableGoogleSignIn)
    ) {
      return { token, user } as any;
    }
    return profile && ({ ...profile?.[appId]?.[0], token, user } as any);
  };

  const registerAppDevice = async (appId: string, _deviceApi?: any, fqdnApp?: any) => {
    const deviceApi = getAppDataSource(appId)?.appConfig?.auth?.deviceApi || _deviceApi;
    const isGraphQL = deviceApi?.isGraphQL;
    if (!deviceApi?.uri) return;

    const deviceUDID = uuidv4();

    const deviceInfo = {
      appVersion: config.appVersion,
      deviceType: config.deviceType,
      deviceUDID,
      osVersion,
      pushToken: "",
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      userId: "",
    };

    const { token } = getAuth(currentApp?.id!) || {};

    const isTokenExist = !!localStorage.getItem(`${appId}-accessToken-device`);
    if (isTokenExist) return;
    let res;

    if (isGraphQL) {
      const isMutation = deviceApi?.graphQLMethod === "MUTATION" && deviceApi?.graphqlQuery?.includes("mutation");
      const operationFunction = (isMutation ? apolloClient.mutate : apolloClient.query) as any;

      const graphQLContext = {
        uri: encodeURLParams(replaceBaseUrl(deviceApi?.uri, currentApp || fqdnApp)),
        headers: {
          ...replaceDataPlaceholdersRecursively({
            obj: deviceApi?.headers,
          }),
        },
      };

      const operationKey = isMutation ? "mutation" : "query";
      const gqlQuery = gql`
        ${deviceApi?.graphqlQuery}
      `;
      const variables = replaceDataPlaceholdersRecursively({
        obj: JSON.parse(deviceApi?.graphqlVariables || "{}"),
        config: deviceInfo,
      });

      try {
        res = await operationFunction({
          [operationKey]: gqlQuery,
          variables,
          context: graphQLContext,
        });
      } catch (error) {
        console.error(`GraphQL ${isMutation ? "Mutation" : "Query"} Error:`, error);
        return;
      }
    } else if (!isGraphQL) {
      res = await axios.request({
        url: encodeURLParams(replaceBaseUrl(deviceApi?.uri, currentApp || fqdnApp)),
        method: deviceApi?.method || "POST",
        data: replaceDataPlaceholdersRecursively({
          obj: JSON.parse(deviceApi?.body || "{}"),
          config: deviceInfo,
        }),
        headers: {
          ...replaceDataPlaceholdersRecursively({
            obj: deviceApi?.headers,
          }),
        },
      });
    }
    const accessTokenResponse = _.get(res?.data, deviceApi?.accessTokenPath || "accessToken");

    if (accessTokenResponse) {
      localStorage.setItem(`${appId}-accessToken-device`, accessTokenResponse);
    }
    if (res?.data?.creationTime) {
      localStorage.setItem("token_created_at", res.data.creationTime);
    }
    localStorage.setItem("device_uuid", deviceUDID);
  };

  const getAppDataSource = (appId: string) => {
    return allowedApps.find(app => app.id === appId);
  };

  const logoutOfApp = (appId: string, isFQDN?: boolean) => {
    setAppSession(appId, null, null, null, isFQDN ?? false);
  };

  const loginToApp = async (
    appId: string,
    email?: string,
    password?: string,
    recaptcha?: string,
    profileName?: string,
    cb: any = () => {},
    _profile?: any,
    _authApi?: any,
    fqdnApp?: any,
    loginType?: string,
    axiosParams?: any,
    disableAutoNavigate?: boolean
  ) => {
    const authApi = getAppDataSource(appId)?.appConfig?.auth?.authApi || _authApi;
    const enableRecaptcha = currentApp?.appConfig?.enableRecaptcha;
    if (!authApi?.uri || currentApp?.appConfig?.auth?.type == "No Auth") return;
    let response = {} as any;
    const isGraphQL = authApi?.isGraphQL;

    const loginData: any = { email, password };
    if (enableRecaptcha && recaptcha) {
      loginData.recaptcha = recaptcha;
    }

    if (!loginType) {
      if (isGraphQL) {
        const isMutation = authApi?.graphQLMethod === "MUTATION" && authApi?.graphqlQuery?.includes("mutation");
        const operationFunction = (isMutation ? apolloClient.mutate : apolloClient.query) as any;

        const graphQLContext = {
          uri: encodeURLParams(replaceBaseUrl(authApi?.uri, currentApp || fqdnApp)),
          headers: replaceDataPlaceholdersRecursively({
            obj: { ...authApi?.headers },
            device: { accessToken: localStorage.getItem(`${appId}-accessToken-device`) },
          }),
        };

        const operationKey = isMutation ? "mutation" : "query";
        const gqlQuery = gql`
          ${authApi?.graphqlQuery}
        `;

        const variables = {
          ...replaceDataPlaceholdersRecursively({
            obj: JSON.parse(authApi?.graphqlVariables || "{}"),
            config: authApi,
            login: loginData,
          }),
        };

        try {
          response = await operationFunction({
            [operationKey]: gqlQuery,
            variables,
            context: graphQLContext,
          });
        } catch (error) {
          console.error(`GraphQL ${isMutation ? "Mutation" : "Query"} Error:`, error);
          return;
        }
      } else {
        try {
          const authPayload = replaceDataPlaceholdersRecursively({
            obj: JSON.parse(authApi?.body || "{}"),
            login: loginData,
          });

          const filteredAuthPayload = Object.fromEntries(
            Object.entries(authPayload).filter(([_, value]) => value !== null && value !== undefined && value !== "undefined")
          );

          const computedAuthHeaders = replaceDataPlaceholdersRecursively({
            obj: { ...authApi?.headers },
            device: { accessToken: localStorage.getItem(`${appId}-accessToken-device`) },
          });

          response = await externalAxiosServices.request({
            url: encodeURLParams(replaceBaseUrl(authApi?.uri, currentApp || fqdnApp)),
            method: authApi?.method || "POST",
            data: filteredAuthPayload,
            headers: computedAuthHeaders,
            params: {
              ...axiosParams,
            },
          });
        } catch (error) {
          return error;
        }
      }
    } else if (loginType == "google-login") {
      const { googleInfo } = fqdnApp?.appConfig || {};
      const { clientId, authEndpoint } = googleInfo || {};
      const searchParams = new URLSearchParams(location.search);
      const validateFQDN = fqdnApp?.fqdn?.replace("https://", "")?.replace("http://", "");
      const redirectUri = `http${validateFQDN.startsWith("localhost") ? "" : "s"}://` + validateFQDN + "/auth/google";
      try {
        response = await axiosServices.post(
          encodeURLParams(replaceBaseUrl(authEndpoint, fqdnApp as any)),
          {},
          {
            params: {
              client_id: clientId,
              clientId: clientId,
              code: searchParams.get("code"),
              redirectUri: redirectUri,
            },
          }
        );
      } catch (e) {
        enqueueSnackbarRef?.("The user is not authorized", {
          variant: "error",
        });
      } finally {
        navigate("/login");
      }
    }

    const { accessToken, principal } = {
      accessToken: _.get(response?.data, authApi?.accessTokenPath || "accessToken"),
      principal: {
        name: profileName || _.get(response?.data, authApi?.namePath || "principal.name") || uuidv4(),
        handle: _.get(response?.data, authApi?.usernamePath || "principal.handle"),
        profilePhoto: _.get(response?.data, authApi?.userPhotoPath || "principal.profilePhoto"),
      },
    };

    let _data;

    if (fqdnApp) {
      try {
        const { data } = await axiosServices.post(`app/${fqdnApp?.id}/token-exchange`, {
          token: accessToken,
          isLogin: true,
          errorMessage: "The user is not authorized",
          organizationId: fqdnApp?.org?.id,
          applicationId: fqdnApp?.id,
        });
        setLoginFlag(data?.principal);
        setSession(data?.accessToken, data?.principal);
        await loadPermissions?.();
        loadApps?.(true, disableAutoNavigate);
      } catch (e: any) {
        enqueueSnackbarRef?.(e.message || "Wrong Services", {
          variant: "error",
        });
        setAppSession(fqdnApp?.id, null, null, null, true);
      }
    }

    if (
      !_profile &&
      !localStorage.getItem("admin-login") &&
      !isHostAvailable &&
      !(fqdnApp?.appConfig?.withProfiles && fqdnApp?.appConfig?.isSingleSignOn)
        ? !fqdnApp
        : true
    ) {
      if (email && password && !_profile) {
        const { data } = await axiosServices.post(`application/${appId}/profile`, {
          profileName: profileName || _.get(response?.data, authApi?.namePath || "principal.name") || uuidv4(),
          creds: {
            username: email,
            password: password,
            recaptcha: recaptcha,
          },
        });
        _data = data;
      }
    }

    const data = _profile || _data;

    setAppSession(appId, accessToken, principal, data);

    setAppProfiles(previous => {
      const prev = { ...previous };
      const profiles = prev?.[appId] || [];
      const results = profiles?.filter((profile: any) => profile?.id !== data?.id);
      results.push(data);
      prev[appId] = results;
      return prev;
    });
    cb?.(response);
    return response;
  };

  const checkAppAuth = !!(
    currentApp?.appConfig?.auth?.authApi?.uri &&
    currentApp?.appConfig?.auth?.type !== "No Auth" &&
    (_.isNil(getAuth(currentApp?.id)?.token) || _.isEmpty(getAuth(currentApp?.id)?.token) || isSwitchingMode) &&
    (localStorage.getItem("admin-login") ||
      isHostAvailable ||
      (currentApp?.appConfig?.withProfiles && currentApp?.appConfig?.isSingleSignOn) ||
      !currentApp?.appConfig?.isSingleSignOn)
  );

  return (
    <QueryClientProvider client={queryClient}>
      <ApolloProvider client={apolloClient}>
        <ReactQueryDevtools />
        <BXContext.Provider
          value={{
            appDescriptor: appDescriptor,
            allowedApps,
            setAppDescriptor: setAppDescriptor,
            fqdnApp,
            setFqdnApp,
            getAuth,
            currentApp,
            multiLingual,
            setMultiLingual,
            envs,
            setEnvs,
            getValue,
            setValue,
            setCurrentApp,
            addApp,
            loginToApp,
            logoutOfApp,
            registerAppDevice,
            loadingApps,
            loadApps,
            isAdministrationMode,
            setIsAdministrationMode,
            isSwitchingMode,
            setIsSwitchingMode,
            appProfiles,
            setAppProfiles,
            appTokens,
            currentProfileId,
            setCurrentProfileId,
            setLoadingApps,
            viewStacks,
            setViewStacks,
            viewsState,
            setViewsState,
            loadingViews,
            setLoadingViews,
            queriesStateGraphQL,
            setQueriesStateGraphQL,
            selectedAppId,
            setSelectedAppId,
            selectedCollectionId,
            setSelectedCollectionId,
            selectedPageId,
            setSelectedPageId,
            selectedViewId,
            setSelectedViewId,
            loadPermissions,
            currentProfilePermissions,
            checkPermissions,
            isRTL,
            setIsRTL,
            updateRTL,
            checkAppAuth,
            unprotectedPages,
            leftDrawerOpened,
            clientSecrets,
            stripePromises,
            stripeAppearance,
            setStripeAppearance,
            stripeEndpoints,
            setStripeEndpoints,
            setLeftDrawerOpened,
            actionConfirmControl,
            setActionConfirmControl,
            isConfirmAction,
            setIsConfirmAction,
            appRoutesMap,
          }}
        >
          {children}
        </BXContext.Provider>
      </ApolloProvider>
    </QueryClientProvider>
  );
};

export const encodeURLParams = (url: string) => {
  const [path, queryString] = url?.split("?");

  if (!queryString) return url;
  const params = queryString.split("&");

  const encodedParams = params.map(param => {
    const [key, value] = param.split("=");

    if (value !== undefined) {
      return `${key}=${encodeURIComponent(value)}`;
    } else {
      return key;
    }
  });

  const encodedQueryString = encodedParams.join("&");

  return `${path}?${encodedQueryString}`;
};

export const replaceBaseUrl = (url: any, app?: BXApp, slug?: string) => {
  if (!url) return url;

  const newPath = url.startsWith("/") ? `${(slug ?? app?.baseUrl) || ""}${url}` : url;

  return newPath;
};

/**
 * consumer of BX Context
 */
export const useBXContext = () => useContext(BXContext);

type UseValueProps = {
  queryKeys: any[];
  limit?: number | undefined;
  isUserInput?: boolean;
  withInfiniteQuery?: boolean;
  options?: any;
  cursor?: any;
  __data: any;
  dataEntry?: any;
  soundPlayer?: any;
  notificationMessage?: any;
  viewId?: any;
  pagination?: any;
  pageId?: any;
  selectedItem?: any;
  endpoint?: string;
  dataSource?: ElementDataSource;
  isApiCallInFirstLoad?: boolean;
  viewName?: string;
  componentKey?: string;
  isPagination?: boolean;
  path?: any;
};

// export const useValue = (props: UseValueProps) => {
//   const {
//     queryKeys,
//     limit,
//     cursor,
//     dataEntry,
//     options,
//     withInfiniteQuery,
//     pagination,
//     isUserInput,
//     notificationMessage,
//     soundPlayer,
//     viewId,
//     pageId,
//     selectedItem = {},
//     __data,
//     endpoint,
//     dataSource,
//     isApiCallInFirstLoad,
//     viewName,
//     componentKey,
//   } = props;
//   const { viewsState, getValue, currentApp, getAuth, queriesStateGraphQL, setQueriesStateGraphQL } = useBXContext();
//   const { replaceDataPlaceholders, replaceDataPlaceholdersRecursively } = useReplaceDataPlaceholders({ viewName });
//   const { setValue } = useAppState();
//   const dispatch = useDispatch();
//   let url: any = endpoint || getValue?.(viewId) || dataSource?.apiUrl;
//   const isFixedData = !_.isString(url) || !url;

//   const isGraphQL = dataSource?.payload?.isGraphQL && !isFixedData;
//   const { token } = getAuth(currentApp?.id!) || {};
//   let apolloMethod = dataSource?.payload?.method || "Query";
//   let graphqlVariables = {};
//   let graphqlQuery = "";
//   let QUERY, mutateFunction, mutateData;
//   let fetchMore, data, loading, rest, refetch, hasNextPage, cursorGraph;

//   const requestHeaders = {
//     ...getAuthorizationHeader(currentApp?.appConfig?.auth, token || localStorage.getItem("accessToken")),
//     ...replaceDataPlaceholdersRecursively({
//       obj: dataSource?.payload?.headers,
//       viewsState,
//       pageId,
//       __data,
//       pagination,
//       env: currentApp?.env,
//       cursor,
//     }),
//   };

//   const customQuery = (
//     isGraphQL ? (apolloMethod === "MUTATION" ? useMutation : useApolloQuery) : withInfiniteQuery ? useInfiniteQuery : useReactQuery
//   ) as any;

//   if (isGraphQL) {
//     graphqlVariables = replaceDataPlaceholdersRecursively({
//       obj: JSON.parse(dataSource?.payload?.graphqlVariables || "{}"),
//       viewsState,
//       pageId,
//       __data,
//       pagination,
//       env: currentApp?.env,
//       cursor,
//       fallback: null,
//     });

//     graphqlQuery = dataSource?.payload?.graphqlQuery || "";
//     QUERY = gql`
//       ${graphqlQuery}
//     `;

//     url = replaceBaseUrl(url, currentApp);

//     url = replaceDataPlaceholders({
//       queryString: url,
//       item: selectedItem,
//       viewsState,
//       pageId,
//       __data,
//       pagination,
//       env: currentApp?.env,
//       cursor,
//       fallback: "",
//     });

//     url = removeEmptyQueryParams(url);

//     const apolloQueryContent = {
//       variables: graphqlVariables,
//       context: { uri: url, apolloHeaders: requestHeaders },
//     };

//     if (apolloMethod === "MUTATION") {
//       [mutateFunction, mutateData] = customQuery(QUERY, apolloQueryContent);
//     } else if (apolloMethod !== "MUTATION") {
//       const queryResult = customQuery(QUERY, apolloQueryContent);
//       data = queryResult.data;
//       fetchMore = queryResult.fetchMore;
//       loading = queryResult.loading;
//       refetch = queryResult.refetch;
//       rest = queryResult;
//       hasNextPage = _.get(data, (dataSource?.hasMoreKey as PropertyPath) || "more");
//       cursorGraph = _.get(data, (dataSource?.cursorKey as PropertyPath) || "cursor");
//     }
//   }

//   const fetchNextPage = async () => {
//     if (refetch && fetchMore && withInfiniteQuery && hasNextPage && isGraphQL) {
//       await fetchMore({
//         variables: replaceDataPlaceholdersRecursively({
//           obj: JSON.parse(dataSource?.payload?.graphqlVariables || "{}"),
//           viewsState,
//           pageId,
//           __data,
//           pagination,
//           env: currentApp?.env,
//           cursor: cursorGraph,
//           fallback: null,
//         }),
//         updateQuery: (previousResult, { fetchMoreResult }) => {
//           if (!fetchMoreResult) {
//             return previousResult;
//           }

//           const data = _.cloneDeep(previousResult);
//           const previousResultResults = _.get(previousResult, (dataSource?.dataEntry as PropertyPath) || "dataEntry", []);
//           const fetchMoreResultResults = _.get(fetchMoreResult, (dataSource?.dataEntry as PropertyPath) || "dataEntry", []);

//           const updatedResults = [...previousResultResults, ...fetchMoreResultResults];

//           _.set(data, dataEntry, updatedResults);

//           _.set(
//             data,
//             dataSource?.hasMoreKey as PropertyPath,
//             _.get(fetchMoreResult, (dataSource?.hasMoreKey as PropertyPath) || "hasMore")
//           );

//           _.set(data, dataSource?.cursorKey as PropertyPath, _.get(fetchMoreResult, (dataSource?.cursorKey as PropertyPath) || "cursor"));

//           return data;
//         },
//       });
//     }
//   };

//   useEffect(() => {
//     if (!withInfiniteQuery && isGraphQL && fetchMore) {
//       fetchMore({
//         variables: replaceDataPlaceholdersRecursively({
//           obj: JSON.parse(dataSource?.payload?.graphqlVariables || "{}"),
//           viewsState,
//           pageId,
//           __data,
//           pagination,
//           env: currentApp?.env,
//           cursor: cursor,
//           fallback: null,
//         }),
//         updateQuery: (previousResult, { fetchMoreResult }) => {
//           if (!fetchMoreResult) return previousResult;

//           return fetchMoreResult;
//         },
//       });
//     }
//   }, [cursor]);

//   useEffect(() => {
//     if (isGraphQL && queryKeys[0] && QUERY) {
//       const key = queryKeys[0];
//       setQueriesStateGraphQL(prevState => ({
//         ...prevState,
//         [key]: QUERY,
//       }));
//     }
//     if (mutateFunction && isGraphQL) {
//       mutateFunction();
//     }
//   }, []);

//   if (isGraphQL && apolloMethod === "MUTATION") {
//     return { mutateData };
//   } else if (isGraphQL && apolloMethod !== "MUTATION") {
//     const result = {
//       data: withInfiniteQuery ? { pages: [data] } : data,
//       fetchNextPage,
//       isFetching: loading,
//       hasNextPage,
//       refetch,
//       rest,
//     };

//     return result;
//   } else {
//     if (_.isString(url)) {
//       url = replaceBaseUrl(url, currentApp);

//       url = replaceDataPlaceholders({
//         queryString: url,
//         item: selectedItem,
//         viewsState,
//         pageId,
//         __data,
//         pagination,
//         env: currentApp?.env,
//         cursor,
//         fallback: "",
//       });
//       url = removeEmptyQueryParams(url);
//     }

//     return customQuery(
//       [...queryKeys, url],
//       async ({ pageParam }: any) => {
//         if (!_.isString(url) || !url) {
//           setValue(`${pageId}.${viewName}.${componentKey}`, url, true);
//           return url;
//         }

//         const { token } = getAuth(currentApp?.id!) || {};

//         let data: any;
//         let method = isGraphQL ? dataSource?.payload?.graphQLMethod || "QUERY" : dataSource?.payload?.method || "GET";
//         const response = await axiosServices.request({
//           method: method as any,
//           url: url,
//           params: { limit, cursor: cursor || pageParam },
//           headers: requestHeaders,
//           data: replaceDataPlaceholdersRecursively({
//             obj: JSON.parse(dataSource?.payload?.body || "{}"),
//             viewsState,
//             pageId,
//             __data,
//             pagination,
//             env: currentApp?.env,
//             cursor,
//           }),
//         });

//         data = response.data;

//         setValue(`${pageId}.${viewName}.${componentKey}`, data, true);
//         // if (!cursor && !pageParam) {
//         //   const firstPageData =
//         //     _.get((queryClient.getQueryData(queryKeys) as any)?.pages?.[0], dataEntry) ||
//         //     _.get(queryClient.getQueryData(queryKeys), dataEntry);

//         //   const result = firstPageData?.find((item: any, index: number) => _.get(data, dataEntry)?.[index]?.id != item?.id);
//         //   if (
//         //     result ||
//         //     (!firstPageData?.length &&
//         //       _.get(data, dataEntry)?.length &&
//         //       queryClient.getQueryState(queryKeys)?.status != "loading" &&
//         //       queryClient.getQueryState(queryKeys)?.status != null)
//         //   ) {
//         //     soundPlayer?.current?.play();
//         //     if (!document.hasFocus()) {
//         //       document.title = "🔴" + (notificationMessage || "The view has been updated");
//         //     }
//         //     enqueueSnackbarRef?.(notificationMessage || "The view has been updated", {
//         //       variant: "success",
//         //     });
//         //   }
//         // }
//         return data;
//       },
//       {
//         cacheTime: 10000,
//         keepPreviousData: true,
//         getNextPageParam: (lastPage: any) =>
//           (_.get(lastPage, (dataSource?.hasMoreKey as PropertyPath) || "hasMore") &&
//             _.get(lastPage, (dataSource?.cursorKey as PropertyPath) || "cursor")) ||
//           undefined,
//         retry: false,
//         refetchOnWindowFocus: false,
//         enabled: false,
//         // (isUserInput ? isApiCallInFirstLoad : true) || !!cursor,
//         ...options,
//       }
//     );
//   }
// };

export const useValue = (props: UseValueProps) => {
  const {
    queryKeys,
    limit,
    cursor,
    dataEntry,
    options,
    withInfiniteQuery,
    pagination,
    isUserInput,
    notificationMessage,
    soundPlayer,
    viewId,
    pageId,
    selectedItem = {},
    __data,
    endpoint,
    dataSource,
    isApiCallInFirstLoad,
    viewName,
    componentKey,
    isPagination,
    path,
  } = props;
  const { viewsState, getValue, currentApp, getAuth, queriesStateGraphQL, setQueriesStateGraphQL, unprotectedPages } = useBXContext();

  const { replaceDataPlaceholders, replaceDataPlaceholdersRecursively } = useReplaceDataPlaceholders({ viewName });
  const { setValue, watch } = useAppState();
  const dispatch = useDispatch();

  const checkUnprotected = path => {
    const pathSlugs = path?.split(".").slice(0, 3) || [];

    if (pathSlugs?.length < 3) {
      return true;
    }
    const pagePath = pathSlugs?.join(".");
    return unprotectedPages.current.has(pagePath as string);
  };

  let url: any = endpoint || getValue?.(viewId) || dataSource?.apiUrl;
  const isFixedData = !_.isString(url) || !url;
  const isGraphQL = dataSource?.payload?.isGraphQL && !isFixedData;
  const { token: accessToken } = getAuth(currentApp?.id!) || {};
  let apolloMethod = dataSource?.payload?.method || "Query";
  let graphqlVariables = {};
  let graphqlQuery = "";
  let QUERY, mutateFunction, refetch, mutateData;

  let isError = false;

  const [data, setData] = useState<any>(null); //Store the response of the source endpoint.
  const [firstLoadOfData, setFirstLoadOfData] = useState<any>();
  // TODO: idk what are we doing but it worked (amjad)
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);

  const elementKey = componentKey ? `${pageId}.${viewName}.${componentKey}.data` : `${pageId}.${viewName}.data`;
  const paginationInfoForElement = useSelector((state: RootState) => _.get(state.appState.pagination, elementKey));

  const deviceToken = localStorage.getItem(`${currentApp?.id}-accessToken-device`);
  const isUnprotectedPage = checkUnprotected(path);

  const token = isUnprotectedPage ? deviceToken : accessToken || localStorage.getItem("accessToken") || deviceToken;

  const requestHeaders = {
    ...getAuthorizationHeader(currentApp?.appConfig?.auth, token),
    ...replaceDataPlaceholdersRecursively({
      obj: dataSource?.payload?.headers,
      viewsState,
      pageId,
      __data,
      pagination,
      env: currentApp?.env,
      cursor,
    }),
  };

  // TODO: For GraphQL
  // if (isGraphQL) {
  //   graphqlVariables = replaceDataPlaceholdersRecursively({
  //     obj: JSON.parse(dataSource?.payload?.graphqlVariables || "{}"),
  //     viewsState,
  //     pageId,
  //     __data,
  //     pagination,
  //     env: currentApp?.env,
  //     cursor,
  //     fallback: null,
  //   });

  //   graphqlQuery = dataSource?.payload?.graphqlQuery || "";
  //   QUERY = gql`${graphqlQuery}`;

  //   url = replaceBaseUrl(url, currentApp);
  //   url = replaceDataPlaceholders({
  //     queryString: url,
  //     item: selectedItem,
  //     viewsState,
  //     pageId,
  //     __data,
  //     pagination,
  //     env: currentApp?.env,
  //     cursor,
  //     fallback: "",
  //   });

  //   url = removeEmptyQueryParams(url);

  //   const apolloQueryContent = {
  //     variables: graphqlVariables,
  //     context: { uri: url, apolloHeaders: requestHeaders },
  //   };

  //   // MUTATION or QUERY logic for GraphQL
  //   if (apolloMethod === "MUTATION") {
  //     [mutateFunction, mutateData] = useMutation(QUERY, apolloQueryContent);
  //   } else {
  //     const { data: graphQLData, fetchMore, refetch: graphQLRefetch, loading: graphQLLoading } = useApolloQuery(QUERY, apolloQueryContent);
  //     data = graphQLData;
  //     loading = graphQLLoading;
  //     refetch = graphQLRefetch;
  //     hasNextPage = _.get(data, (dataSource?.hasMoreKey as PropertyPath) || "more");
  //   }
  // }

  // REST API logic using axios
  const fetchData = async (isRefetchOperation: boolean, currentCursorParam?: any, isPaginated?: boolean) => {
    const targetKey = componentKey ? `${pageId}.${viewName}.${componentKey}.data` : `${pageId}.${viewName}.data`;

    //Fixed data
    if (!_.isString(url) || !url) {
      setValue(targetKey, url, true, true);
      setData(url);
      return;
    }

    try {
      // Set loading state
      setIsLoading(true);
      // Determine the cursor for the request
      let _currentCursor = "";
      if (!isRefetchOperation) {
        _currentCursor = withInfiniteQuery
          ? paginationInfoForElement?.nextCursor
          : isPaginated || currentCursorParam
          ? currentCursorParam
          : paginationInfoForElement?.currentCursor;
      }
      // Construct the URL and replace placeholders
      url = replaceBaseUrl(url, currentApp);
      url = replaceDataPlaceholders({
        queryString: url,
        item: selectedItem,
        viewsState,
        pageId,
        __data,
        pagination,
        env: currentApp?.env,
        cursor: _currentCursor,
        fallback: "",
      });
      url = removeEmptyQueryParams(url);
      // url = encodeURLParams(url);
      let params = {};
      if (!url.includes("limit")) {
        params = { ...params, limit };
      }
      if (!url.includes("cursor")) {
        params = { ...params, cursor: _currentCursor };
      }
      params = { ...params, skipAuthErrorClear: isHostAvailable };

      // Make the request
      const response = await externalAxiosServices.request({
        method: (dataSource?.payload?.method || "GET") as Method,
        url,
        params: params,
        headers: requestHeaders,
        data: replaceDataPlaceholdersRecursively({
          obj: JSON.parse(dataSource?.payload?.body || "{}"),
          viewsState,
          pageId,
          __data,
          pagination,
          env: currentApp?.env,
          cursor: _currentCursor,
        }),
      });
      const newData = response.data;
      let items;
      if (Array.isArray(newData)) {
        items = newData;
      } else {
        items = _.get(newData, dataSource?.dataEntry || "items", []);
      }
      setHasNextPage(_.get(newData, (dataSource?.hasMoreKey as PropertyPath) || "hasMore"));
      const paginationResult = {
        name: targetKey,
        paginationObject: {
          currentCursor: _currentCursor,
          nextCursor: _.get(newData, (dataSource?.cursorKey as PropertyPath) || "cursor"),
          hasMore: _.get(newData, (dataSource?.hasMoreKey as PropertyPath) || "hasMore"),
          length: items.length,
        },
      };

      dispatch(setPaginationInfo(paginationResult));

      if (isPagination && items.length === 0 && !withInfiniteQuery) {
        return;
      }

      // Handle infinite scroll or pagination
      if (!withInfiniteQuery) {
        // Replace the data with the current page's data (pagination case)
        setValue(targetKey, newData, true, true);
        setData(newData); // Only set the current page data
      } else {
        // Infinite scroll: append the new data to existing data or reset the pages list if refetch operation
        const existingData = _.isNil(data) ? [] : data;
        const key: any = dataSource?.dataEntry;
        let combinedData;

        if (Array.isArray(newData)) {
          combinedData = isRefetchOperation ? newData : [...existingData, ...newData];
        } else {
          const existingItems = _.get(existingData, dataSource?.dataEntry as PropertyPath, []);
          const combinedItems = isRefetchOperation ? items : [...existingItems, ...items];

          combinedData = {
            ...newData,
            [key]: combinedItems,
          };
        }
        setValue(targetKey, combinedData, true, true);
        setData(combinedData); // Append new data
      }
      // Update pagination info

      return paginationResult;
    } catch (error) {
      setValue(targetKey, error, true);
      isError = true;
      console.error("Error fetching data", error);
    } finally {
      setIsLoading(false);
    }
  };

  //Interface method for fetching data as refresh or with user input query and resetting cursor
  const refetchDataSource = async () => {
    await fetchData(true);
  };

  //Interface method for fetching data within an infinite scroll context or pagination
  const fetchNextPage = async (currentCursor?: any, isPaginated?: boolean) => {
    const paginationResult = await fetchData(false, currentCursor, isPaginated);
    return paginationResult;
  };

  //TODO: Fetch data on load if enabled
  useEffect(() => {
    if (!isGraphQL && dataSource?.sourceType !== "NONE") {
      //Initial load of data
      fetchData(false);
      const firstCallRecords = watch(elementKey);
      setFirstLoadOfData(firstCallRecords); //Track if the system data is empty or not
    }

    return () => {
      //On unmount, clear the cursors.
      const paginationResult = {
        name: elementKey,
        paginationObject: {
          currentCursor: "",
          nextCursor: "",
          hasMore: "",
          data: "",
        },
      };

      dispatch(setPaginationInfo(paginationResult));
    };
  }, []);

  if (isGraphQL && apolloMethod === "MUTATION") {
    return { mutateData };
  } else {
    //Expose these methods for data source management
    return {
      data,
      fetchNextPage,
      isFetching: isLoading,
      hasNextPage,
      refetchDataSource,
      firstLoadOfData,
      isError,
    };
  }
};
