// src/features/appState/hooks.ts
import _ from "lodash"; // Import lodash for _.get functionality
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

// Hooks to access app state
export const useAppValue = (name: string) => useSelector((state: RootState) => _.get(state.appState.values, name));
export const useAppError = (name: string) => useSelector((state: RootState) => _.get(state.appState.errors, name));
export const useAppDirty = (name: string) => useSelector((state: RootState) => _.get(state.appState.dirtyFields, name));
// Hook to listen to specific triggers/events/signals for a specific component identified by "name"
export const useAppTrigger = (name: string) => useSelector((state: RootState) => _.get(state.appState.triggers, name));