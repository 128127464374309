import { Box, Button, Divider, Typography } from "@mui/material";
import _ from "lodash";
import React, { FunctionComponent, Suspense, createElement } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { BXAPi } from "src/components/BXUI/API";
import { BXCardList } from "src/components/BXUI/CardList";
import { BXDataTable } from "src/components/BXUI/DataTable";
import { Form } from "src/components/BXUI/Form";
import { FormBuilder } from "src/components/BXUI/FormBuilder";
import { BXFormTable } from "src/components/BXUI/FormTable";
import { BXKeyValue } from "src/components/BXUI/KeyValue";
import BXPage from "src/components/BXUI/Page";
import { BXRepeatedView } from "src/components/BXUI/RepeatedView";
import { Upload } from "src/components/BXUI/Upload";
import { BXAutoComplete } from "src/components/BXUI/autoComplete";
import { PermissibleRender } from "src/components/PermissionValidation/PermissibleRender";
import { BXPageType } from "src/types/BXPageType";
import { UIElement } from "src/types/UIElement";
import { CircularChart } from "src/views/pages/BuildX/FormBuilder/components/CircularChart";
import { ColumnChart } from "src/views/pages/BuildX/FormBuilder/components/ColumnChart";
import { PieChart } from "src/views/pages/BuildX/FormBuilder/components/PieChart";
import { SplineChart } from "src/views/pages/BuildX/FormBuilder/components/SplineChart";
import { ComponentItemType } from "src/views/pages/BuildX/FormBuilder/types";

export const KeysToComponentMap: { [id: string]: any } = {
  "data-table": BXDataTable,
  "card-list": BXCardList,
  "image-grid": BXCardList,
  formTable: BXFormTable,
  form: Form,
  upload: Upload,
  api: BXAPi,
  page: BXPage,
  autoComplete: BXAutoComplete,
  divider: Divider,
  keyValue: BXKeyValue,
  "form-builder": FormBuilder,
  "repeated-view": BXRepeatedView,
  SplineChart: SplineChart,
  PieChart: PieChart,
  ColumnChart: ColumnChart,
  CircularChart: CircularChart,
};
export const AcceptsTargetTypes = [
  "Group",
  ComponentItemType.TextField,
  ComponentItemType.DatePicker,
  ComponentItemType.DateTimePicker,
  ComponentItemType.TimePicker,
  ComponentItemType.Button,
  ComponentItemType.CustomIconButton,
  ComponentItemType.CustomChip,
  ComponentItemType.Avatar,
  ComponentItemType.FlexContainer,
  ComponentItemType.GridContainer,
  ComponentItemType.Typography,
  ComponentItemType.Divider,
  ComponentItemType.CustomSwitch,
  ComponentItemType.CustomCheckbox,
  ComponentItemType.CustomRadio,
  ComponentItemType.CustomMediaCard,
  ComponentItemType.StripePaymentElements,
  ComponentItemType.StripeContainer,
  ComponentItemType.StripeCardNumberElement,
  ComponentItemType.StripeAddressElement,
  ComponentItemType.StripeCardCvcElement,
  ComponentItemType.StripeCardExpiryElement,
  ComponentItemType.CustomGoogleMap,
  ComponentItemType.GoogleMapAutocomplete,
  ComponentItemType.FileUploadInput,
  ComponentItemType.CustomContainer,
  ComponentItemType.CustomAutoCompleteBX,
  ComponentItemType.StepperContainer,
  // ComponentItemType.CustomAccordion,
  ComponentItemType.CustomTabs,
  ComponentItemType.SplineChart,
  ComponentItemType.PieChart,
  ComponentItemType.ColumnChart,
  ComponentItemType.CircularChart,
  ComponentItemType.JsonViewer,
  ComponentItemType.StepperNavigator,
  ComponentItemType.MarkdownViewer,
  ComponentItemType.ColorPicker,
  ComponentItemType.CustomQR,
  ComponentItemType.BXView,
  ComponentItemType.BXLayout,
  ComponentItemType.BXSideBar,
  ComponentItemType.BXNavBar,
  ComponentItemType.BXSelect,
  ComponentItemType.PaginationBar,
  ..._.map(KeysToComponentMap, (value, key) => key),
];

export const isNativeComponent = (tagName: string) => {
  return ["div", "p", "span", "h1", "h2", "h3", "h4", "h5", "h6", "figure", "img", "section", "header", "strong", "article"].includes(
    tagName
  );
};

const FallbackComponent = () => {
  return (
    <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' minHeight={200} padding={2}>
      <Typography variant='h3' gutterBottom mb={2}>
        Oops! Something went wrong.
      </Typography>
      <Button variant='contained' color='primary' onClick={() => window.location.reload()}>
        Refresh
      </Button>
    </Box>
  );
};

type EngineProps = {
  layout?: UIElement[];
  auth: any;
  page?: BXPageType;
  isVisible?: boolean;
  path?: string;
  [key: string]: any;
};

export const BXEngine: FunctionComponent<EngineProps> = ({ layout = [], path, page, isVisible, sharedAppViews, ...rest }) => {
  const createElementRecursively = (elements: Array<UIElement | string | number>): React.ReactElement | undefined | string => {
    const computeElement = (type: string) => {
      if (isNativeComponent(type)) {
        return type;
      }
      return KeysToComponentMap[type] || React.Fragment;
    };
    return (
      <Suspense fallback={"Loading ..."}>
        <ErrorBoundary fallback={<FallbackComponent />}>
          <>
            {/* @ts-ignore */}
            {elements.map((element: UIElement | string | number) => (
              <PermissibleRender isAllowed key={typeof element === "object" ? element.id : element} path={path} action={["VIEW"]}>
                {({ permitted }) => {
                  if (!permitted) return null;
                  // check for text nodes
                  if (["string", "number"].includes(typeof element)) {
                    return element;
                  }
                  element = element as UIElement;

                  if (element.info?.visibility == "Hidden" && !isVisible) return <></>;

                  return createElement(
                    computeElement(element.type),
                    {
                      ...element,
                      views: isNativeComponent(element.type) ? undefined : page?.views?.concat(sharedAppViews || []),
                      layout: isNativeComponent(element.type) ? undefined : page?.layout,
                      className: [element.className].join(" "),
                      info: isNativeComponent(element.type) ? undefined : element.info,
                      pageId: page?.id,
                      id: element.id,
                      key: element.id,
                      height: element?.height,
                      ...element.config,
                      dataSource: isNativeComponent(element.type) ? undefined : element.dataSource,
                      columns: isNativeComponent(element.type) ? undefined : element.config?.columns,
                      ...(isNativeComponent(element.type) ? {} : rest),
                      path: path,
                      disabled: element.disabled || rest?.disabled,
                    },
                    element.children && element.children.length > 0 ? <>{createElementRecursively(element.children)}</> : null
                  );
                }}
              </PermissibleRender>
            ))}
          </>
        </ErrorBoundary>
      </Suspense>
    );
  };

  return <>{createElementRecursively(layout)}</>;
};
