import { Box } from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import { joinObjects } from "hd-utils";
import _ from "lodash";
import React, { useContext, useLayoutEffect, useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { useLocation } from "react-router-dom";
import { ResizeElement } from "src/components/ResizeElement";
import { AcceptsTargetTypes } from "../../../../BXEngine";
import { HeightContext } from "./FormBuilderEditor";
import { ComponentItemType } from "./types";
import {
  componentsHaveChildren,
  getDirectParentChildren,
  getItemClosestProp,
  getRootParent,
  isParentFlex,
  isParentGrid,
  stepperCount,
  updateChildrenAtLevelN,
} from "./utils";

export const DragElement = (props: any) => {
  const {
    itemIndex,
    item,
    className,
    active,
    canDrag,
    boxPosition,
    children,
    setView,
    activeComponent,
    setActiveComponent,
    resizable = true,
    hideSource = true,
    layoutBreak,
    formMoveBoxFunction,
    isSideMenu,
    scaleFactor,
    parentBoxMoveBoxComponent,
    setIsContainerDragging,
    isRTL,
    isDragging: _isDragging,
  } = props;
  const theme = useTheme();
  const dragItemRef = useRef<any>();
  const location = useLocation();
  const isActiveComponent = item?.id === activeComponent;
  const { boxHeight } = useContext(HeightContext);
  const { type, leftPercentage, left, config, top } = item || {};
  const { widthPx, heightPx, widthPercentage, heightPercentage, fixedWidth, isPercentageHeight, disableResizeHeight, minWidth, minHeight } =
    config || {};

  const [{ isDragging, difference }, drag, preview] = useDrag({
    type,
    item: { ...item, index: itemIndex, boxPosition, level: null, isSideMenu },
    end: (item, monitor) => {
      if (!canDrop && dragItemRef.current) {
        dragItemRef.current.style.transform = `translate(0, 0)`;
        return;
      }
      if (!item.id) return;
      const { changeLevel } = monitor.getDropResult() as any;
      if (changeLevel === "Form" && item?.config?.parent) {
        const { level } = getRootParent(item) as any;
        setView((prevSta: any) => {
          const formBuilder = prevSta.dataSource.formBuilder;
          const directParentChildren = getDirectParentChildren(formBuilder, item.config.parent);
          const childIndex = directParentChildren.findIndex(el => el.id === item.id);
          if (childIndex === -1) return prevSta;
          const coppiedItems = [...directParentChildren];
          coppiedItems.splice(childIndex, 1);
          const orderedItems = coppiedItems.map((el, i) => ({ ...el, index: i }));

          return {
            ...prevSta,
            dataSource: {
              ...prevSta.dataSource,
              formBuilder: updateChildrenAtLevelN(prevSta.dataSource.formBuilder, item.id, item.config.parent.id, level - 1, orderedItems),
            },
          };
        });
      }
    },
    canDrag:
      item?.config?.flexCanvas || item?.config?.disableDrag || location.pathname.startsWith("/buildx/form-builder/template/history/")
        ? false
        : canDrag,
    // canDrag: location.pathname.startsWith("/buildx/form-builder/template/history/") ? false : item?.config?.flexCanvas ? false : canDrag,
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
      difference: monitor.getDifferenceFromInitialOffset(),
    }),
  });

  const [{ canDrop, isOverCurrent }, drop] = useDrop({
    accept: AcceptsTargetTypes,
    drop: (draggedItem: any, monitor) => {
      const dragIndex = draggedItem.index;
      const hoverIndex = item?.index;
      const { changeLevel } = (monitor.getDropResult() || {}) as any;

      if (dragIndex === hoverIndex || hoverIndex === undefined || !changeLevel) {
        if (draggedItem?.config?.parent?.id === undefined) {
          return formMoveBoxFunction(draggedItem, monitor);
        } else if (
          draggedItem?.config?.parent?.id &&
          parentBoxMoveBoxComponent &&
          (draggedItem?.config?.parent?.type != ComponentItemType.FlexContainer ||
            draggedItem?.config?.parent?.type != ComponentItemType.GridContainer ||
            draggedItem?.config?.parent?.type != ComponentItemType.StepperContainer)
        ) {
          return parentBoxMoveBoxComponent(draggedItem, monitor);
        }
      }

      if (changeLevel == "Box") return;
      if (draggedItem?.config?.parent?.id === item?.config?.parent?.id) {
        setView((prevState: any) => {
          if (draggedItem?.config?.parent) {
            if (item?.config?.parent) {
              if (draggedItem.config.parent.id === item.config.parent.id) {
                const formBuilder = prevState.dataSource.formBuilder;
                const directParentChildren = getDirectParentChildren(formBuilder, item.config.parent);
                const dragItem = directParentChildren[dragIndex];

                if (dragItem) {
                  const { level } = getRootParent(item) as any;

                  const coppiedStateArray = [...directParentChildren];

                  // remove item by "dragIndex"
                  coppiedStateArray.splice(dragIndex, 1);

                  // add item by "hoverIndex"
                  coppiedStateArray.splice(hoverIndex, 0, dragItem);

                  const orderedItems = coppiedStateArray.map((el, i) => ({ ...el, index: i }));

                  return {
                    ...prevState,
                    dataSource: {
                      ...prevState.dataSource,
                      formBuilder: updateChildrenAtLevelN(
                        prevState.dataSource.formBuilder,
                        item.id,
                        item.config.parent.id,
                        level - 1,
                        orderedItems
                      ),
                    },
                  };
                }
              }
            } else {
              return prevState;
            }
          } else {
            return prevState;
          }
        });
      }
    },
    canDrop: (dragItem, monitor): boolean => {
      return (
        (dragItem?.config?.parent?.type == ComponentItemType.FlexContainer &&
          item?.config?.parent?.type == ComponentItemType.FlexContainer) ||
        (dragItem?.config?.parent?.type == ComponentItemType.GridContainer &&
          item?.config?.parent?.type == ComponentItemType.GridContainer) ||
        (dragItem?.config?.parent?.type == ComponentItemType.StepperContainer &&
          item?.config?.parent?.type == ComponentItemType.StepperContainer) ||
        !isOverCurrent
      );
    },

    collect: monitor => ({
      canDrop: !monitor.canDrop(),
      isOverCurrent: monitor.isOver(),
    }),
  });

  useLayoutEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: item?.config?.flexCanvas || item?.config?.disableDrag ? false : canDrag });
    setIsContainerDragging?.(isDragging);
  }, [isDragging, difference]);

  const onResize = (size: any) => {
    if (size?.width < minWidth || size?.height < minHeight) return;
    const dx = Number(getItemClosestProp(left, layoutBreak)) <= boxPosition?.width - size.width;
    const dy =
      Number(getItemClosestProp(top, layoutBreak)) <= (boxPosition?.height || getItemClosestProp(boxHeight, layoutBreak)) - size.height;

    const width = Math.round(
      dx || isParentFlex(item) || isParentGrid(item)
        ? size.width <= boxPosition?.width
          ? size.width
          : boxPosition?.width
        : boxPosition?.width -
            (Number((getItemClosestProp(leftPercentage, layoutBreak) || "")?.replace("%", "")) / 100) * boxPosition?.width
    );

    const height = Math.round(
      dy || isParentFlex(item) || isParentGrid(item)
        ? size.height <= boxPosition?.height
          ? size.height
          : boxPosition?.height
        : (boxPosition?.height || getItemClosestProp(boxHeight, layoutBreak)) - getItemClosestProp(top, layoutBreak)
    );

    const PWidth = Math.round((width / (boxPosition?.width / scaleFactor)) * 100);
    const percentageWidth = PWidth > 100 ? "100%" : PWidth + "%";

    const PHeight = Math.round((height / (boxPosition?.height / scaleFactor)) * 100);
    const percentageHeight = PHeight > 100 ? "100%" : PHeight + "%";

    const hasCustom = item?.config?.hasCustom;

    setView((prev: any) => {
      const updateElement: any = (elements: any[]) => {
        return elements.map((element: any) => {
          const newChildren = element?.children
            ? updateElement(element.children) // Recursively update nested children
            : null;

          if (element?.id === item?.id) {
            const _element = _.cloneDeep(element);
            _element.config.hasCustom = layoutBreak === "xs";
            _element.config.widthPx = {
              ..._element.config.widthPx,
              xs: hasCustom ? _element.config.widthPx.xs : width,
              [layoutBreak]: width,
            };
            _element.config.widthPercentage = {
              ..._element.config.widthPercentage,
              xs: hasCustom ? _element?.config?.percentageWidth?.xs : percentageWidth,
              [layoutBreak]: percentageWidth,
            };
            if (!_element?.config?.disableResizeHeight) {
              _element.config.heightPx = {
                ..._element.config.heightPx,
                xs: hasCustom ? _element.config.heightPx.xs : height,
                [layoutBreak]: height,
              };
              _element.config.heightPercentage = {
                ..._element.config.heightPercentage,
                xs: hasCustom ? _element?.config?.percentageHeight?.xs : percentageHeight,
                [layoutBreak]: percentageHeight,
              };
            }
            return _element;
          } else {
            return { ...element, children: newChildren };
          }
        });
      };

      const updatedElements = updateElement(_.cloneDeep(prev?.dataSource?.formBuilder));

      return {
        ...prev,
        dataSource: {
          ...prev?.dataSource,
          formBuilder: updatedElements,
        },
      };
    });
  };

  let getDimensions = {
    position: !resizable || item?.config?.flexCanvas ? "relative" : "absolute",

    top: getItemClosestProp(top, layoutBreak),
    left: getItemClosestProp(leftPercentage, layoutBreak),
    ...(resizable && {
      width: item?.config?.isDynamicWidth
        ? "auto"
        : String(item?.config?.parent?.type) === ComponentItemType.GridContainer
        ? "100%"
        : !fixedWidth
        ? getItemClosestProp(widthPercentage, layoutBreak)
        : getItemClosestProp(widthPx, layoutBreak),
      height: item?.config?.isDynamicHeight
        ? "fit-content"
        : item?.config?.flexCanvas
        ? "100%"
        : isPercentageHeight
        ? getItemClosestProp(heightPercentage, layoutBreak)
        : String(item?.config?.parent?.type) === ComponentItemType.GridContainer
        ? "100%"
        : getItemClosestProp(heightPx, layoutBreak),
      // height: item?.config?.isDynamicHeight ? "auto" : item?.config?.flexCanvas ? "100%" : getItemClosestProp(heightPx, layoutBreak),
      border: item?.config?.flexCanvas
        ? ""
        : resizable && (isActiveComponent || active)
        ? `1px dashed ${theme.palette.text.primary}`
        : "none",
    }),
    opacity: (isDragging || _isDragging) && hideSource ? 0 : 1,
    zIndex: 1,
    backgroundColor: "transparent",
  };

  const isActiveComponentResizable = resizable && !item?.config?.isNotResizable && boxPosition && isActiveComponent;
  const ParentComponent: any = isActiveComponentResizable ? ResizeElement : Box;

  let fullInnerWidth = false;
  let parentComponentWidthHeight = {};

  if (item?.config?.parent) {
    fullInnerWidth = componentsHaveChildren.includes(item.config.parent.type);
    parentComponentWidthHeight = { height: getDimensions.height, width: getDimensions.width };
  }

  const parentFlex = isParentFlex(item);
  const parentGrid = isParentGrid(item);
  let stylesFromChild = item?.config?.parentStyle;

  const parentComponentProps: any =
    resizable && isActiveComponent
      ? { height: heightPx, width: widthPx, onResize, stepper: stepperCount, isDragging, scaleFactor, isRTL, boxPosition }
      : {};
  const isParent = item?.config?.parent === null;

  if ((parentFlex || parentGrid) && !isActiveComponentResizable) {
    getDimensions = _.set(getDimensions, "width", "100%");
    getDimensions = _.set(getDimensions, "height", "100%");
  }

  const isRemovingChildren =
    isDragging &&
    !isSideMenu &&
    (String(item?.type) === ComponentItemType.CustomContainer ||
      String(item?.type) === ComponentItemType.FlexContainer ||
      String(item?.type) === ComponentItemType.GridContainer ||
      String(item?.type) === ComponentItemType.StepperContainer);
  if (item?.config?.hideElement || item?.config?.hideIn?.includes(layoutBreak)) {
    return <></>;
  }
  return (
    <ParentComponent
      isParent={isParent}
      {...parentComponentProps}
      {...joinObjects(
        !isActiveComponentResizable && {
          ...{
            style: stylesFromChild,
            ...parentComponentWidthHeight,
            ...(item?.config?.flexCanvas
              ? {
                  height: "100%",
                }
              : {}),
          },
        }
      )}
    >
      <Box
        onClick={e => {
          e.stopPropagation();
          setActiveComponent?.(item);
        }}
        sx={joinObjects(
          getDimensions,
          (parentFlex || parentGrid) && {
            position: "static",
          },
          String(item?.config?.parent?.type) !== ComponentItemType.CustomContainer &&
            String(item?.type) !== ComponentItemType.FlexContainer &&
            String(item?.type) !== ComponentItemType.StepperContainer &&
            String(item?.type) !== ComponentItemType.GridContainer && {
              width: fullInnerWidth && !isActiveComponentResizable ? "100%" : getDimensions.width,
            }
        )}
        data-id={item?.id}
        id={item?.id}
        style={joinObjects(stylesFromChild)}
        className={`${className} ${active ? "active" : ""}`}
      >
        <Box
          height={disableResizeHeight && !isSideMenu ? item?.config?.defaultHeight * scaleFactor : "100%"}
          width={disableResizeHeight && !isSideMenu ? item?.config?.defaultWidth * scaleFactor : "100%"}
          ref={(e: any) => {
            drag(e);
            drop(e);
            dragItemRef.current = e;
          }}
          sx={{
            background: canDrop && isOverCurrent ? "rgba(255, 222, 0, 0.2)" : "unset",
            position: "relative",
          }}
        >
          {String(item?.type) !== ComponentItemType.CustomContainer &&
            String(item?.type) !== ComponentItemType.FlexContainer &&
            String(item?.type) !== ComponentItemType.StepperContainer &&
            String(item?.type) !== ComponentItemType.GridContainer && (
              <Box sx={{ position: "absolute", top: -2, right: 0, bottom: -2, left: 0, zIndex: itemIndex + 1 }} />
            )}
          {isRemovingChildren
            ? React.Children.map(props.children, (child, index) => (index === 0 ? React.cloneElement(child, { children: [] }) : child))
            : children}
        </Box>
      </Box>
    </ParentComponent>
  );
};
