import { IconButton, InputAdornment, TextField, useTheme } from "@mui/material";
import { useState } from "react";
import { BXIcon } from "src/components/BXUI/Icon";

export const CustomTextField = props => {
  const {
    InputProps,
    showPasswordToggle = true,
    isLeft,
    iconColor,
    iconSize = "24x",
    componentIconSpacing,
    type,
    IconOpacityHidden,
    iconColorHidden,
    iconColorVisible,
    IconOpacityVisible,
    iconConfig,
    iconConfigHidden,
    sameHiddenIcon,
    config,
    __config,
    metaData,
    info,
    ...restProps
  } = props;
  const [showPassword, setShowPassword] = useState(false);

  const customIcon =
    iconConfig?.icon || iconConfig?.url ? (
      <BXIcon
        width={iconSize}
        height={iconSize}
        icon={props?.iconConfig?.icon}
        url={props?.iconConfig?.url}
        visibility={props?.iconConfig?.visibility}
        color={iconColor}
        style={{
          maxWidth: "100%",
          maxHeight: "100%",
          marginLeft: isLeft ? "0px" : componentIconSpacing,
          marginRight: !isLeft ? "0px" : componentIconSpacing,
        }}
      />
    ) : null;

  const handleClickShowPassword = () => setShowPassword(prev => !prev);
  const handleMouseDownPassword = event => event.preventDefault();
  const theme = useTheme();

  const iconElement = (
    <BXIcon
      width={"20px"}
      height={"20px"}
      icon={sameHiddenIcon ? iconConfigHidden?.icon : showPassword ? iconConfig?.icon || "Eye" : iconConfigHidden?.icon || "Eye"}
      url={sameHiddenIcon ? iconConfigHidden?.url : showPassword ? iconConfig?.url : iconConfigHidden?.url}
      visibility={sameHiddenIcon ? iconConfigHidden?.visibility : showPassword ? iconConfig?.visibility : iconConfigHidden?.visibility}
      color={showPassword ? iconColorVisible || "black" : iconColorHidden || "black"}
      style={{ maxWidth: "100%", maxHeight: "100%", opacity: showPassword ? IconOpacityVisible || "1" : IconOpacityHidden || "0.3" }}
    />
  );
  if (type === "Password" && showPasswordToggle) {
    return (
      <TextField
        type={showPassword ? "text" : "password"}
        sx={{
          "& .MuiInputLabel-root": {
            top: "50%", // Center vertically
            left: 14,
            transform: "translateY(-50%)", // Adjust for perfect centering
            transition: theme.transitions.create(["top", "transform"], {
              duration: theme.transitions.duration.shorter,
            }),
          },
          // Style the Input Label when it's shrunk (focused or has value)
          "& .MuiInputLabel-shrink": {
            top: "-8px", // Default floating position
            transform: "translateY(0)", // Reset translation
            fontSize: "0.75rem", // Optional: Adjust font size when shrunk
            transition: theme.transitions.create(["top", "transform"], {
              duration: theme.transitions.duration.shorter,
            }),
          },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge='end'
                style={{ backgroundColor: "transparent", marginRight: 14 }}
              >
                {iconElement}
              </IconButton>
            </InputAdornment>
          ),
          ...InputProps,
        }}
        {...restProps}
      />
    );
  }

  return (
    <TextField
      {...restProps}
      sx={{
        "& .MuiInputLabel-root": {
          top: "50%", // Center vertically
          left: 14,
          transform: "translateY(-50%)", // Adjust for perfect centering
          transition: theme.transitions.create(["top", "transform"], {
            duration: theme.transitions.duration.shorter,
          }),
        },
        // Style the Input Label when it's shrunk (focused or has value)
        "& .MuiInputLabel-shrink": {
          top: "-8px", // Default floating position
          transform: "translateY(0)", // Reset translation
          fontSize: "0.70rem", // Optional: Adjust font size when shrunk
          transition: theme.transitions.create(["top", "transform"], {
            duration: theme.transitions.duration.shorter,
          }),
        },
      }}
      InputProps={{
        startAdornment:
          isLeft && customIcon ? (
            <InputAdornment
              sx={{
                width: "auto",
                height: "auto",
              }}
              position='start'
            >
              {customIcon}
            </InputAdornment>
          ) : null,
        endAdornment:
          !isLeft && customIcon ? (
            <InputAdornment
              sx={{
                width: "auto",
                height: "auto",
              }}
              position='end'
            >
              {customIcon}
            </InputAdornment>
          ) : null,
        ...InputProps,
      }}
    />
  );
};
