import { Backdrop, TextField, useTheme } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import moment from "moment";
import { FC, useCallback, useMemo, useState } from "react";
import { isUnixTimestamp, isValidDate } from "src/utils/generalUtils";
import { constructTimeView } from "../TimePicker/CustomTimePicker";

export const CustomDateTimePicker: FC<any> = componentProps => {
  const { config = undefined, $config = undefined, value, onChange: onSideChange, ...props } = componentProps;

  const componentConfig = $config ? $config : config;
  const { startDate, endDate, endsNow, startsNow, isUserLocalTime } = componentConfig;
  const {
    hoursEnabled = true,
    minutesEnabled = true,
    secondsEnabled = false,
    hoursStep = 1,
    minutesStep = 5,
    secondsStep = 5,
    ampm = true,
  } = componentConfig;

  const [isOpen, setIsOpen] = useState(false);

  const openDateTimePicker = () => {
    setIsOpen(true);
  };

  const closeDateTimePicker = () => {
    setIsOpen(false);
  };

  const computedValue = useMemo(() => {
    if (!value?.utcISO) {
      return null;
    }

    if (!isValidDate(value?.utcISO)) {
      return null;
    }

    return new Date(value?.utcISO);
  }, [value]);

  const defaultValue = useMemo(() => {
    if (!props?.defaultValue?.utcISO) {
      if (isUnixTimestamp(Number(props?.defaultValue))) {
        return new Date(Number(props?.defaultValue));
      }

      if (isValidDate(props?.defaultValue)) {
        return new Date(props?.defaultValue);
      }

      return null;
    }

    if (!isValidDate(props?.defaultValue?.utcISO)) {
      return null;
    }

    return new Date(props?.defaultValue?.utcISO);
  }, [props?.defaultValue]);

  const handleChange = useCallback(
    date => {
      const formattedUtcIso = moment(date).format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
      if (date && date instanceof Date && !isNaN(date.getTime())) {
        const formattedDate = isUserLocalTime
          ? {
              utcISO: date.toISOString(),
              userLocalISO: moment(date).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
              epoch: date.valueOf(),
            }
          : {
              utcISO: formattedUtcIso,
              userLocalISO: moment(formattedUtcIso).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
              epoch: moment(formattedUtcIso).valueOf(),
            };
        onSideChange?.(formattedDate);
      } else {
        onSideChange?.({
          utcISO: null,
          userLocalISO: null,
          epoch: null,
        });
      }
    },
    [onSideChange]
  );

  const formatDate = date => {
    if (!date) return null;
    if (isUserLocalTime) return date;
    return new Date(moment.utc(date).format("YYYY-MM-DDTHH:mm:ss.SSS"));
  };
  const theme = useTheme();

  return (
    <>
      <Backdrop open={isOpen} sx={{ opacity: 0, backgroundColor: "transparent" }} onClick={closeDateTimePicker} />
      <DateTimePicker
        {...props}
        onOpen={openDateTimePicker}
        onClose={closeDateTimePicker}
        defaultValue={formatDate(defaultValue)}
        value={computedValue ? formatDate(computedValue) : ""}
        open={isOpen}
        onChange={handleChange}
        slotProps={{
          textField: {
            error: componentProps?.error ?? false,
          },
        }}
        renderInput={params => <TextField {...params} {...props?.InputProps} />}
        disablePast={startsNow}
        disableFuture={endsNow}
        minDate={startDate && !startsNow ? new Date(startDate) : undefined}
        maxDate={endDate && !endsNow ? new Date(endDate) : undefined}
        views={["year", "month", "day"].concat(constructTimeView([hoursEnabled, minutesEnabled, secondsEnabled]))}
        timeSteps={{
          hours: hoursStep,
          minutes: minutesStep,
          seconds: secondsStep,
        }}
        ampm={!ampm}
        sx={{
          "& .MuiInputLabel-root": {
            top: "50%", // Center vertically
            left: 14,
            transform: "translateY(-50%)", // Adjust for perfect centering
            transition: theme.transitions.create(["top", "transform"], {
              duration: theme.transitions.duration.shorter,
            }),
          },
          // Style the Input Label when it's shrunk (focused or has value)
          "& .MuiInputLabel-shrink": {
            top: "-8px", // Default floating position
            transform: "translateY(0)", // Reset translation
            fontSize: "0.75rem", // Optional: Adjust font size when shrunk
            transition: theme.transitions.create(["top", "transform"], {
              duration: theme.transitions.duration.shorter,
            }),
          },
        }}
      />
    </>
  );
};
