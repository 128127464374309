import { LoadingButton } from "@mui/lab";
import {
  Box,
  Card,
  DialogActions,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { IconFileExport, IconFileImport, IconKey, IconPencil, IconTrashX } from "@tabler/icons-react";
import React, { FC, useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useInfiniteQuery, useMutation } from "react-query";
import { BXConfirmationDialog } from "src/components/BXUI/AlertDialog/ConfirmationDialog";
import BXModal from "src/components/BXUI/Modal";
import { enqueueSnackbarRef } from "src/utils/SnackbarUtilsConfigurator";
import axios from "src/utils/axios";
import { queryClient } from "../../../../BXEngine/BXContext";

import { cloneDeep } from "lodash";
import { CreateEnvForm } from "./CreateEnvForm";

type ManageEnvProps = {
  children?: React.ReactNode;
};

const columns: any[] = [
  { id: "id", label: "ID", minWidth: 270 },
  { id: "name", label: "Name", minWidth: 270 },
];

export const ManageEnv: FC<ManageEnvProps> = ({ children }) => {
  const { palette } = useTheme();
  const [searchText, setSearchText] = useState("");

  let querykey = ["env-list"];
  if (searchText.trim()) {
    querykey.push(searchText.trim());
  }
  const { data, hasNextPage, fetchNextPage, isFetching, isError } = useInfiniteQuery(
    querykey,
    ({ pageParam }) => {
      return axios.get(process.env.REACT_APP_HOST_API_KEY + "/api/admin/env", {
        params: {
          cursor: pageParam,
          keyword: searchText || undefined,
        },
        headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
      });
    },
    {
      keepPreviousData: true,
      getNextPageParam: (lastPage: any) => (lastPage?.data?.hasMore ? lastPage?.data?.cursor : undefined),
      refetchOnWindowFocus: false,
    }
  );

  const { mutate } = useMutation(
    (data: any) => {
      return axios.post(process.env.REACT_APP_HOST_API_KEY + "/api/admin/env", data, {
        headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
      });
    },
    {
      onSuccess: data1 => {
        queryClient.setQueryData(querykey, ({ pages: [page1, ...rest] }) => {
          return {
            // @ts-ignore
            pages: [{ ...page1, data: { items: [data1.data, ...page1.data.items] } }, ...rest],
          };
        });
      },
      onError: (e: any) => {
        enqueueSnackbarRef?.(e.message || "Wrong Services", {
          variant: "error",
        });
      },
    }
  );

  const { mutate: editEnv } = useMutation(
    (editedData: any) => {
      return axios.put(process.env.REACT_APP_HOST_API_KEY + `/api/admin/env/${editedData.id}`, editedData);
    },
    {
      onSuccess: (data1, editedData: any) => {
        queryClient.setQueryData(querykey, ({ pages: [page1, ...rest] }) => {
          return {
            // @ts-ignore
            pages: [
              {
                ...page1,
                data: { items: page1.data.items.map((item: any) => (item.id === data1.data.id ? { ...editedData, ...data1.data } : item)) },
              },
              ...rest,
            ],
          };
        });
      },
    }
  );

  const { mutate: deleteEnv } = useMutation(
    (itemToDelete: any) => {
      return axios.delete(process.env.REACT_APP_HOST_API_KEY + `/api/admin/env/${itemToDelete?.id}`);
    },
    {
      onSuccess: (_, editedData: any) => {
        enqueueSnackbarRef?.("Deleted successfully", {
          variant: "success",
        });
        queryClient.setQueryData(["env-list"], ({ pages: [page1, ...rest] }) => {
          return {
            // @ts-ignore
            pages: [{ ...page1, data: { items: page1.data.items.filter(item => item.id !== editedData?.id) } }, ...rest],
          };
        });
      },
    }
  );

  const onSubmit = (values: any) => {
    return mutate(values, {});
  };

  const [sentryRef] = useInfiniteScroll({
    loading: isFetching,
    hasNextPage: hasNextPage || false,
    onLoadMore: () => fetchNextPage(),
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: isError,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: "0px 0px 400px 0px",
  });

  const handleExportClick = (item: any) => {
    delete item.id;
    //Map/transform the env row item to the original config
    const configVariables: any = {};
    const upConfigVariables: any = {};

    item?.config?.forEach((variable: any) => {
      if (variable?.isPrivate) {
        configVariables[variable?.key] = variable?.value;
        return;
      }
      upConfigVariables[variable?.key] = variable?.value;
    });

    const env = {
      ...item,
      name: item?.name,
      config: {
        variables: configVariables,
      },
      upConfig: {
        variables: upConfigVariables,
      },
    };

    const filename = env.name;
    const jsonStr = JSON.stringify(env, null, 2);
    const blob = new Blob([jsonStr], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = filename;
    link.href = url;
    link.click();
  };

  const handleFileSelect = (event: any) => {
    if (event.target.files && event.target.files.length > 0) {
      const selectedFile = event.target.files[0];
      const reader = new FileReader();
      reader.onload = event => {
        const jsonString = event.target?.result?.toString();
        const parsedJson = JSON.parse(jsonString || "");
        onSubmit(parsedJson);
      };
      reader.readAsText(selectedFile);
    }
  };

  const handleSelectClick = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = ".json";
    fileInput.addEventListener("change", handleFileSelect);
    fileInput.click();
  };

  const environmentsList = data?.pages?.[0]?.data.items.map(env => {
    const mappedEnv = cloneDeep(env);

    const variablesList = Object.entries(mappedEnv?.config?.variables || {}).map(([key, value]) => ({
      key: key,
      value: value,
      isPrivate: true,
    }));

    const upVariablesList = Object.entries(mappedEnv?.upConfig?.variables || {}).map(([key, value]) => ({
      key: key,
      value: value,
      isPrivate: false,
    }));

    mappedEnv.config = [...variablesList, ...upVariablesList];
    delete mappedEnv.upConfig;

    return mappedEnv;
  });

  return (
    <>
      <Grid container style={{ height: "100%" }}>
        <Grid style={{ height: "100%", padding: 10, paddingTop: 0 }} item md={12}>
          <Grid container style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: 2 }}>
            <Grid item xs={12}></Grid>
          </Grid>

          <Grid container marginBottom={1}>
            <Grid container flex={1} spacing={2} alignItems={"center"}>
              <Grid item>
                <Typography fontSize={"20px"} color='textPrimary' fontWeight={600}>
                  Manage Environment
                </Typography>
              </Grid>
            </Grid>

            <DialogActions>
              <LoadingButton
                variant='contained'
                startIcon={<IconFileImport size={18} />}
                onClick={handleSelectClick}
                sx={{ marginInlineEnd: 1, height: "32px" }}
              >
                Import Environment
              </LoadingButton>
              <BXModal
                title={"Add Environment"}
                icon={<IconKey />}
                label={"Add Environment"}
                buttonProps={{
                  startIcon: <IconKey />,
                  color: "secondary",
                  variant: "contained",
                  style: { backgroundColor: palette.primary.main, borderRadius: 24 },
                  size: "small",
                }}
              >
                {(handleClose: Function) => {
                  return (
                    <CreateEnvForm
                      onSave={(data, event) => {
                        const configVariables: any = {};
                        const upConfigVariables: any = {};

                        data?.config?.forEach((variable: any) => {
                          if (variable?.isPrivate) {
                            configVariables[variable?.key] = variable?.value;
                            return;
                          }
                          upConfigVariables[variable?.key] = variable?.value;
                        });

                        onSubmit({
                          name: data?.name,
                          config: {
                            variables: configVariables,
                          },
                          upConfig: {
                            variables: upConfigVariables,
                          },
                        });
                        handleClose?.();
                      }}
                    />
                  );
                }}
              </BXModal>
            </DialogActions>
          </Grid>
          <Card style={{ padding: 24 }}>
            <Grid xs={12} item container spacing={2}>
              <Grid item spacing={2} xs={12} md={4} alignItems='center'>
                <TextField
                  size='small'
                  fullWidth
                  label={"Search"}
                  onChange={e => {
                    setSearchText(e.target.value.length > 2 ? e.target.value : "");
                  }}
                />
              </Grid>
            </Grid>
            <TableContainer style={{ backgroundColor: palette.background.paper }}>
              <Table stickyHeader aria-label='sticky table'>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ backgroundColor: palette.background.paper }}>Actions</TableCell>

                    {columns.map(column => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, backgroundColor: palette.background.paper }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.pages?.[0]?.data.items?.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={columns.length + 1} align={"center"}>
                        No Environment yet
                      </TableCell>
                    </TableRow>
                  )}
                  {environmentsList?.map((row: any) => {
                    return (
                      <TableRow hover role='checkbox' tabIndex={-1} key={row.code}>
                        <TableCell>
                          <DialogActions sx={{ padding: 0, display: "flex", justifyContent: "flex-start" }}>
                            <BXModal
                              title={"Edit Environment "}
                              icon={<IconPencil color={palette.primary.main} height={26} width={26} style={{ padding: 4 }} />}
                              withoutLabel
                              buttonProps={{
                                startIcon: <IconKey />,
                                color: "primary",
                                size: "small",
                                variant: "contained",
                              }}
                            >
                              {(handleClose: Function) => {
                                return (
                                  <CreateEnvForm
                                    row={row}
                                    onSave={data => {
                                      const configVariables: any = {};
                                      const upConfigVariables: any = {};

                                      data?.config?.forEach((variable: any) => {
                                        if (variable?.isPrivate) {
                                          configVariables[variable?.key] = variable?.value;
                                          return;
                                        }
                                        upConfigVariables[variable?.key] = variable?.value;
                                      });

                                      editEnv({
                                        id: row?.id,
                                        name: data?.name,
                                        config: {
                                          variables: configVariables,
                                        },
                                        upConfig: {
                                          variables: upConfigVariables,
                                        },
                                      });
                                      handleClose?.(true);
                                    }}
                                  />
                                );
                              }}
                            </BXModal>
                            <IconButton onClick={() => handleExportClick(row)}>
                              <IconFileExport size={20} />
                            </IconButton>
                            <Box marginInlineStart={1}>
                              <BXConfirmationDialog
                                title={"Are you sure you want to delete this Environment?"}
                                iconButton
                                buttonProps={{
                                  color: "error",
                                  children: <IconTrashX height={20} width={20} style={{ padding: 0 }} />,
                                }}
                                onConfirm={() => {
                                  deleteEnv(row);
                                }}
                              />
                            </Box>
                          </DialogActions>
                        </TableCell>
                        {columns.map(column => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id}>
                              <Grid container alignItems={"center"}>
                                {value}
                              </Grid>
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                  {(isFetching || hasNextPage) && (
                    <TableRow ref={sentryRef}>
                      <TableCell colSpan={columns.length || 1}>Loading</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
