// withLogging.js

import { Box } from "@mui/material";
import { useBXContext } from "src/BXEngine/BXContext";
import { FormBuilder } from "../FormBuilder";

const withPageLayout = WrappedComponent => {
  return function WithPageLayout(props) {
    const { page, layouts } = props;
    const layout = layouts?.find(layout => layout?.id == page?.layoutId);

    const { currentApp } = useBXContext();

    const views = currentApp?.templateConfig?.collections?.flatMap(col => col.pages).flatMap(page => page.views);

    if (!layout)
      return (
        <Box marginX={1}>
          <WrappedComponent {...props} />
        </Box>
      );

    return <FormBuilder {...layout} views={views} pageOutlet={<WrappedComponent {...props} />} />;
  };
};

export default withPageLayout;
