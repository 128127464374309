import { Accordion, AccordionDetails, AccordionSummary, Theme, Typography } from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import React from "react";
import { ReactComponent as ExpandMoreIcon } from "../../../../.././../assets/images/icons/ExpandMoreIcon.svg";

interface ItemsPropertiesAccordionProps {
  expanded: boolean;
  onChange: () => void;
  label: string;
  isAdmin?: boolean;
}

const ItemsPropertiesAccordion: React.FC<ItemsPropertiesAccordionProps> = ({ expanded, onChange, label, isAdmin, children }) => {
  const theme = useTheme();

  return (
    <Accordion
      expanded={expanded}
      onChange={onChange}
      disableGutters
      elevation={0}
      sx={{
        marginBottom: 0,
        borderTop: `1px solid ${theme.palette.background.default}`, // Set the top border style here
        borderBottom: `1px solid ${theme.palette.background.default}`, // Set the bottom border style here
        borderLeft: "none",
        width: "100%",
        borderRight: "none",
        "&:before": {
          display: "none",
        },
        "&:not(:last-child)": {
          borderBottom: "none",
        },
        "&:first-child": {
          borderTop: "none", // Remove top border for the first child
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{ transform: expanded ? "rotate(270deg)" : "rotate(0deg)" }} />}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "row-reverse",
          margin: "0 !important",
          padding: "0 8px !important",
          position: "relative",
        }}
      >
        <Typography
          variant={isAdmin ? "h3" : "h5"}
          sx={{
            userSelect: "none",
            color: (theme: Theme) => theme?.palette?.primary[200],
          }}
        >
          {label}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>{children}</AccordionDetails>
    </Accordion>
  );
};
export default ItemsPropertiesAccordion;
