import React, { FC, useCallback } from "react";
import { Outlet, useLocation } from "react-router-dom";

// material-ui
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import useTheme from "@mui/material/styles/useTheme";
import Toolbar from "@mui/material/Toolbar";
import useMediaQuery from "@mui/material/useMediaQuery";
import makeStyles from "@mui/styles/makeStyles";

// third-party
import clsx from "classnames";

// project imports
import Breadcrumbs from "src/components/extended/Breadcrumbs";
import { drawerWidth } from "src/stores/constant";
import Header from "./Header";
import Sidebar from "./Sidebar";

import { useState } from "react";
import useAuth from "src/hooks/useAuth";

// assets
import { IconChevronRight } from "@tabler/icons-react";
import { cloneDeep } from "lodash";
import { useDrop } from "react-dnd";
import { RouteObject } from "react-router";
import { useBXContext } from "src/BXEngine/BXContext";
import { BXApp } from "src/types/BXAppType";
import BuildxGear from "./BuildxGear/BuildxGear";

// style constant
const useStyles = makeStyles((theme: any) => ({
  root: {
    display: "flex",
  },
  appBar: {
    backgroundColor: theme.palette.background.default,
  },
  appBarWidth: {
    transition: theme.transitions.create("width"),
    backgroundColor: theme.palette.background.paper,
  },
  content: {
    ...theme.typography.mainContent,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    border: 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up("md")]: {
      marginInlineStart: -(drawerWidth - 20),
      width: `calc(100% - ${drawerWidth}px)`,
    },
    [theme.breakpoints.down("md")]: {
      marginInlineStart: "20px",
      width: `calc(100% - ${drawerWidth}px)`,
      padding: "16px",
    },
    [theme.breakpoints.down("sm")]: {
      marginInlineStart: "10px",
      width: `calc(100% - ${drawerWidth}px)`,
      padding: "16px",
    },
    transform: "translateX(-100)",
    marginInlineEnd: 0,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginInlineStart: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    padding: 0,
    [theme.breakpoints.down("md")]: {
      marginInlineStart: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      marginInlineStart: "10px",
    },
  },
}));

// ==============================|| MAIN LAYOUT ||============================== //
type MainLayoutProps = {
  navigation: RouteObject[];
  currentApp?: BXApp;
  fqdnApp?: BXApp;
};
const MainLayout: FC<MainLayoutProps> = ({ navigation, currentApp, fqdnApp }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();
  const { checkAppAuth, leftDrawerOpened, setLeftDrawerOpened, appRoutesMap, loadingApps, isAdministrationMode } = useBXContext();
  const { isSuperAdmin } = useAuth();
  const [position, setPosition] = useState<any>({ x: 20, y: window.innerHeight - 60 });
  const updatePosition = useCallback((newPosition: any) => {
    setPosition(newPosition);
  }, []);

  // Only drop logic in MainLayout
  const [, drop] = useDrop(
    () => ({
      accept: "gear",
      drop: (_, monitor) => {
        const delta = monitor.getDifferenceFromInitialOffset();
        if (delta) {
          const newX = Math.round(position.x + delta.x);
          const newY = Math.round(position.y + delta.y);
          updatePosition({ x: newX, y: newY });
          return { position: { x: newX, y: newY } };
        }
        return undefined;
      },
    }),
    [position, updatePosition]
  );

  const handleLeftDrawerToggle = () => {
    setLeftDrawerOpened(!leftDrawerOpened);
  };

  React.useEffect(() => {
    setLeftDrawerOpened(!matchDownMd);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownMd]);

  const path = window.location.pathname;
  const pathSlugs: any[] = window.location.pathname?.split("/");

  const isRootCollection = appRoutesMap?.current?.[path]?.isRootCollection;
  pathSlugs?.shift();
  let [appName, collectionName, pageName] = pathSlugs;

  if (fqdnApp) {
    if (isRootCollection) {
      pageName = pathSlugs[0];
    } else {
      collectionName = pathSlugs[0];
      pageName = pathSlugs[1];
    }
  } else {
    if (isRootCollection) {
      appName = pathSlugs[0];
      pageName = pathSlugs[1];
    } else {
      appName = pathSlugs[0];
      collectionName = pathSlugs[1];
      pageName = pathSlugs[2];
    }
  }

  // // For FQDN Apps, the app name is not available in the URL, and for Root Collection, there is no collection name
  // if (pathSlugs.length === 1) {
  //   // If there is only one segment, treat it as the pageName for FQDN apps
  //   pageName = pathSlugs[0];
  //   appName = undefined;
  //   collectionName = undefined;
  // } else if (!pageName) {
  //   // If no pageName is present (3 segments case), FQDN Case with collection and page or default case with root collection
  //   [collectionName, pageName] = pathSlugs;
  // } else {
  //   // Default case (more than 2 segments)
  //   [appName, collectionName, pageName] = pathSlugs;
  // }

  let _templateConfig;
  if (currentApp?.templateConfig) {
    _templateConfig = cloneDeep(currentApp?.templateConfig);
  } else {
    _templateConfig = cloneDeep(currentApp?.upTemplateConfig);
  }

  const collection = isRootCollection
    ? _templateConfig?.collections?.find(collection => collection?.slug === `/`)
    : _templateConfig?.collections?.find(collection => collection?.slug === `/${collectionName}`);

  const page = collection?.pages?.find(page => page?.slug?.replace(/\/:[^/]+/g, "") === `/${pageName}`); //Process dynamic variables on path
  const layout = _templateConfig?.layouts?.find(layout => layout?.id === page?.layoutId);
  const isAdmin = isAdministrationMode || location.pathname.startsWith("/buildx");

  //Case 1: There is layout for the current page
  //Case 2: There is no page (mainly its still loading) and is not is the administrator mode and there is an FQDN App.
  if (layout || (!page && !isAdmin && fqdnApp)) {
    return (
      <>
        <div ref={drop} style={{ display: "contents" }}>
          {/* BuildxGear */}
          {!location.pathname.startsWith("/buildx/form-builder") && !location.pathname.startsWith("/buildx/page-builder") && (
            <>{isSuperAdmin() && <BuildxGear position={position} updatePosition={updatePosition} />}</>
          )}
          <CssBaseline />
          <Outlet />
        </div>
      </>
    );
  }

  return (
    <div className={classes.root} ref={drop}>
      <CssBaseline />
      {/* header */}
      <AppBar
        enableColorOnDark
        position='fixed'
        color='inherit'
        elevation={0}
        id='appBar'
        className={leftDrawerOpened ? classes.appBarWidth : classes.appBar}
      >
        <Toolbar>
          <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
        </Toolbar>
      </AppBar>

      {/* drawer and BuildxGear */}
      {!location.pathname.startsWith("/buildx/form-builder") && !location.pathname.startsWith("/buildx/page-builder") && (
        <>
          <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />
          {isSuperAdmin() && <BuildxGear position={position} updatePosition={updatePosition} />}
        </>
      )}

      {/* main content */}
      <main
        className={clsx([
          classes.content,
          {
            [classes.contentShift]: leftDrawerOpened,
          },
        ])}
      >
        {/* breadcrumb */}
        <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
        <Outlet />
      </main>
    </div>
  );
};

export default MainLayout;
