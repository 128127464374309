import { Grid } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { v4 as uuid } from "uuid";
import { BXMapValues } from "../../BXMapValues";
import { components } from "../../utils";

const MapValueSelector = ({ item, onChangeProp, handleChangeProp, handleConfigChange, setView }) => {
  const { mapKey } = item?.config || {};
  const { conditionMapKey } = item?.config || {};
  const { isMapValues } = item?.props || {};
  const { prevSelectedType, selectedType, optionMap, ...restOfItem } = item || {};
  const [selectedValue, setSelectedValue] = useState<string>(item?.selectedType || "default");
  const [initialItem, setInitialItem] = useState();
  const [condition, setCondition] = useState(item?.config?.conditionMapKey || "equal");

  const handleConditionChange = (newCondition: string) => {
    setCondition(newCondition);
    const newItem = {
      ...item,
      config: {
        ...item?.config,
        conditionMapKey: newCondition,
      },
    };
    onChangeProp?.(newItem);
  };

  useEffect(() => {
    if (!item?.optionMap) {
      const newItem = {
        ...item,
        selectedType: "default",
        initItemValue: { ...item },
        optionMap: {
          default: {
            ...item,
            id: uuid(),
            children: JSON.parse(JSON.stringify(item?.children || [])),
            initItemValue: { ...item },
          },
        },
      };
      setSelectedValue("default");
      onChangeProp?.(newItem);
    }
  }, []);
  const prevSelectedTypeRef = useRef(selectedType);
  const prevItemIdRef = useRef();

  useEffect(() => {
    const prevSelectedOption = item?.prevSelectedType;
    if (prevItemIdRef.current !== item?.id) {
      prevItemIdRef.current = item?.id;
      return;
    }
    let prevItem: any = {};
    if (prevSelectedOption) {
      prevItem = {
        ...item,
        optionMap: {
          ...item.optionMap,
          [prevSelectedOption]: {
            ...restOfItem,
            children: JSON.parse(JSON.stringify(restOfItem?.children || [])),
          },
        },
      };
      onChangeProp?.(prevItem);
    }

    const selectedOption = item?.optionMap?.[selectedType];
    if (selectedOption) {
      const newItem = {
        ...selectedOption,
        config: {
          ...selectedOption?.config,
          parent: prevItem?.config?.parent,
          mapKey: item?.config?.mapKey,
          conditionMapKey: item?.config?.conditionMapKey,
        },
        optionMap: prevItem?.optionMap || item?.optionMap,
        selectedType: item?.selectedType,
        prevSelectedType: prevItem?.prevSelectedType,
        initItemValue: prevItem?.initItemValue ? { ...prevItem?.initItemValue } : { ...item?.initItemValue },
      };
      onChangeProp(newItem);
    }
  }, [selectedType]);

  function updateChildrenIds(item) {
    if (!item?.children || !Array.isArray(item.children)) {
      return [];
    }

    return item.children.map((child, index) => ({
      ...child,
      id: `newId_${index}${uuid()}`,
    }));
  }
  function copyFromDefault(value, selectedValue) {
    let prevItem: any = {};
    prevItem = {
      ...item,
      optionMap: {
        ...item.optionMap,
        [selectedValue]: { ...restOfItem, children: [...updateChildrenIds(restOfItem)] },
      },
    };
    onChangeProp?.(prevItem);

    const defaultValue = prevItem?.optionMap?.default || {};
    const newItem = {
      ...item,
      selectedType: value,
      prevSelectedType: selectedValue,
      optionMap: {
        ...item?.optionMap,
        [value]: { ...defaultValue, children: [...updateChildrenIds(defaultValue)] },
      },
    };
    onChangeProp?.(newItem);
    setSelectedValue(value);
  }

  function copyFromValue(value, source, selectedValue) {
    let prevItem: any = {};
    prevItem = {
      ...item,
      optionMap: {
        ...item.optionMap,
        [selectedValue]: { ...restOfItem },
      },
    };
    onChangeProp?.(prevItem);

    const newItem = {
      ...item,
      prevSelectedType: selectedValue,
      selectedType: value,
      optionMap: {
        ...item?.optionMap,
        [value]: { ...prevItem?.optionMap?.[source], children: [...updateChildrenIds(prevItem?.optionMap?.[source])] },
      },
    };
    onChangeProp?.(newItem);
  }

  function addNewValue(value, selectedValue) {
    let prevItem: any = {};
    prevItem = {
      ...item,
      optionMap: {
        ...item.optionMap,
        [selectedValue]: { ...restOfItem },
      },
    };
    onChangeProp?.(prevItem);
    const newValue: any = components.find((comp: any) => comp?.type === item.type) || {};

    setSelectedValue(value);

    const newItem = {
      ...item,
      prevSelectedType: selectedValue,
      selectedType: value,
      optionMap: {
        ...item?.optionMap,
        [value]: {
          ...newValue,
          id: item.id,
          props: {
            ...newValue.props,
            key: prevItem?.props?.key,
            id: item?.id,
            isMapValues: true,
          },
          config: item.config,
          children: [],
          leftPercentage: prevItem?.optionMap?.[selectedValue]?.leftPercentage,
          left: prevItem?.optionMap?.[selectedValue]?.left,
          top: prevItem?.optionMap?.[selectedValue]?.top,
        },
      },
    };

    onChangeProp?.(newItem);
  }
  function deleteChain(value) {
    const updatedOptionMap = { ...item?.optionMap };
    delete updatedOptionMap[value];
    onChangeProp?.({
      ...item,
      selectedType: "default",
      optionMap: updatedOptionMap,
    });
    setSelectedValue("default");
  }

  return (
    <Grid display='flex' gap={2} marginInlineStart={"5px"} marginInlineEnd={"5px"} item xs={12} justifyContent='center' alignItems='center'>
      <BXMapValues
        key={item.id}
        initialKey={mapKey}
        queryString='mapKey'
        targetMap={optionMap}
        handleKeyChange={handleConfigChange}
        setSelectedValue={setSelectedValue}
        addNewValue={addNewValue}
        copyFromDefault={copyFromDefault}
        copyFromValue={copyFromValue}
        deleteValue={deleteChain}
        selectedValue={selectedValue}
        item={item}
        onChangeProp={onChangeProp}
        condition={condition}
        setCondition={setCondition}
        handleConditionChange={handleConditionChange}
        type='item'
      />
    </Grid>
  );
};

export default MapValueSelector;
