import _ from "lodash";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { isHostAvailable } from "src/App";
import { useBXContext } from "src/BXEngine/BXContext";
import useAuth from "src/hooks/useAuth";

interface RedirectGuardProps {
  children: React.ReactNode;
  protectedLandingPage?: string;
}
/**
 * Redirection guard middleware for pages that should send user to landing page if logged in
 */
export const RedirectGuard: FC<RedirectGuardProps> = ({ children, protectedLandingPage }) => {
  const { isLoggedIn } = useAuth();
  const { fqdnApp, currentProfileId, appTokens, currentApp } = useBXContext();
  const navigate = useNavigate();

  //If not FQDN, check the access token for the current app selected and the current profile Id
  if (isHostAvailable) {
    const tokenKey = `${currentApp?.id}-${currentProfileId}-accessToken`;
    if (appTokens?.hasOwnProperty(tokenKey) && !_.isEmpty(appTokens?.[tokenKey]) && !_.isNil(appTokens?.[tokenKey])) {
      navigate(`${protectedLandingPage ?? "/"}`, { replace: true });
    }
  } else if (fqdnApp && isLoggedIn) {
    navigate(`${protectedLandingPage ?? "/"}`, { replace: true });
  }

  return <>{children}</>;
};
