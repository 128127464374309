import { id } from "date-fns/locale";
import _ from "lodash";
import { useEffect } from "react";
import { useBXContext, useValue } from "src/BXEngine/BXContext";
import { useAppState } from "src/features/appState/hooks";
import { ComponentItemType, StepperComponentState } from "src/views/pages/BuildX/FormBuilder/types";
import { stepperGroupComponentState } from "src/views/pages/BuildX/FormBuilder/utils";
import { useReplaceDataPlaceholders } from "../DataTable/ActionButton";
import { RenderChildren } from "./RenderChildren";

function updatePagesForAllGroups(stepperGroups: any, pageReference: string, replicaCount: number) {
  if (replicaCount < 0) {
    return stepperGroups;
  }

  const newStepperGroups = _.cloneDeep(stepperGroups);

  _.forEach(newStepperGroups, (stepperObject: any, groupKey: string) => {
    if (!stepperObject?.pages) return;
    const referenceStepperObject = stepperGroups?.[groupKey];
    if (referenceStepperObject) {
      stepperObject.pages = _.cloneDeep(referenceStepperObject.pages);
    }

    const mainPageIndex = _.findIndex(stepperObject.pages, (page: any) => page.pageReference === pageReference);

    if (mainPageIndex !== -1) {
      if (replicaCount === 0) {
        stepperObject.pages.splice(mainPageIndex, 1);
      } else {
        _.assign(stepperObject.pages[mainPageIndex], {
          pageReference: `${pageReference}-0`,
          repeatReference: pageReference,
        });

        const replicas = _.times(replicaCount - 1, i =>
          _.assign(_.cloneDeep(stepperObject.pages[mainPageIndex]), { pageReference: `${pageReference}-${i + 1}` })
        );

        stepperObject.pages.splice(mainPageIndex + 1, 0, ...replicas);
      }
    }
  });
  return newStepperGroups;
}

const getChildrenKeys = element => {
  const childrenKeys: any = [];
  const leafChildren: any = [];
  const traverseOnChildren = el => {
    const key = el?.props?.key;

    childrenKeys.push(key);

    if (el.type === ComponentItemType.TextField) {
      leafChildren.push(key);
      return;
    }

    if (Array.isArray(el.children)) {
      el.children.forEach(child => {
        traverseOnChildren(child);
      });
    }
  };
  traverseOnChildren(element);

  return { leafChildren, childrenKeys };
};

export const RepeatedItemsComponent = ({
  element,
  item,
  viewsState,
  pageId,
  __data,
  currentApp,
  parentKey,
  multiLingual,
  viewName,
  referenceStepperGroups,
  props,
}) => {
  const { handleSelectRow: _handleSelectRow, ...restProps } = props;

  const { replaceDataPlaceholders } = useReplaceDataPlaceholders({ viewName: props?.info?.viewName });
  const { setViewsState } = useBXContext();
  const { setValue, watch } = useAppState();

  const queryKeys = [`${pageId}-${viewName}-${element?.props?.key}`];

  useValue({
    queryKeys,
    __data,
    viewId: id,
    limit: element?.configData?.paginationKey || 20,
    dataEntry: element?.configData?.dataEntry,
    pageId,
    options: {
      enabled: element?.configData?.sourceType == "API" || element?.configData?.sourceType == "LIST",
    },
    dataSource: element?.configData?.sourceType == "API" && element?.configData?.source,
    endpoint: element?.configData?.sourceType == "LIST" && element?.configData?.selectValues,
    viewName: viewName,
    componentKey: element?.props?.key,
    path: props?.path,
  });

  const key = `${pageId}.${viewName}.${element?.props?.key}`;
  const defaultNumItems = Number(element.config.repeated.defaultItems);

  const repeatedData = replaceDataPlaceholders({
    queryString: element?.config?.repeated?.data,
    item,
    viewsState,
    pageId,
    __data,
    viewName: props?.info?.viewName,
    env: currentApp?.env,
    fallback: "",
    multiLingual: multiLingual,
  });

  const stepperGroupsPath = `${pageId}.${viewName}._BX_stepperGroups`;
  const stepperGroups = watch(stepperGroupsPath);

  function handleSelectRow(selectedRowData) {
    const key = `${pageId}-${viewName}`;
    setViewsState(prev => {
      const data = { ...prev };
      data[key] = {
        ...data?.[key],
        _selectedItem: selectedRowData,
      };

      return data;
    });
  }

  useEffect(() => {
    if (key) {
      const { leafChildren, childrenKeys } = getChildrenKeys(element);
      if (element?.config?.repeated?.data) {
        const data = _.isArray(repeatedData) ? repeatedData : [];

        setValue(
          `${key}.value`,
          new Array(data.length).fill({}).map((_, index) => ({ index }))
        );
        setValue(`${key}.minItems`, Number(element.config.repeated.minItems));
        setValue(`${key}.maxItems`, Number(element.config.repeated.maxItems));
        setValue(`${key}.children`, childrenKeys);
        setValue(`${key}.leafChildren`, leafChildren);
      } else {
        setValue(
          `${key}.value`,
          new Array(defaultNumItems).fill({}).map((_, index) => ({ index }))
        );
        setValue(`${key}.minItems`, Number(element.config.repeated.minItems));
        setValue(`${key}.maxItems`, Number(element.config.repeated.maxItems));
        setValue(`${key}.children`, childrenKeys);
        setValue(`${key}.leafChildren`, leafChildren);
      }
    }
  }, [repeatedData]);

  useEffect(() => {
    const componentStepperState = stepperGroupComponentState(element?.props?.key, referenceStepperGroups);

    if (componentStepperState !== StepperComponentState.NotFound) {
      const updatedStepperGroups = updatePagesForAllGroups(referenceStepperGroups, element?.props?.key, repeatedData.length);

      setValue(stepperGroupsPath, updatedStepperGroups, true);
    }
  }, [repeatedData, referenceStepperGroups, item, pageId]);

  const isParentInStepper = stepperGroupComponentState(`${element?.props?.key}`, referenceStepperGroups) !== StepperComponentState.NotFound;
  return watch(`${key}.value`)?.map((_item, itemIndex) => {
    const repeatedComponentStepperState = stepperGroupComponentState(`${element?.props?.key}-${itemIndex}`, stepperGroups);

    const hiddenByStepper =
      repeatedComponentStepperState === StepperComponentState.FoundButNotSelected ||
      (repeatedComponentStepperState === StepperComponentState.NotFound && isParentInStepper);

    return (
      <RenderChildren
        key={element?.id + "-" + itemIndex}
        handleSelectRow={handleSelectRow}
        {...restProps}
        element={element}
        currentRepeatedItem={repeatedData[itemIndex]}
        index={_item?.index ?? itemIndex}
        hiddenByStepper={hiddenByStepper}
        parentKey={`${parentKey}.${element?.props?.key}`}
        repeatedParentKey={element?.props?.key}
      />
    );
  });
};
