import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { ThemeProvider } from "@mui/material/styles";
import { useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { BXContext } from "src/BXEngine/BXContext";
import themes from "src/themes";
import rtlPlugin from "stylis-plugin-rtl";
import { appColor as color } from "./colors";

function ThemeProviderWrapper(props: any) {
  const { currentApp, isAdministrationMode, isRTL, fqdnApp } = useContext(BXContext);

  const [appTheme, setAppTheme] = useState((currentApp ?? fqdnApp)?.appConfig?.theme || {});
  const location = useLocation();

  useEffect(() => {
    if (currentApp?.appConfig?.theme) {
      setAppTheme(currentApp.appConfig.theme);
    } else {
      setAppTheme({});
    }
  }, [currentApp]);

  const isAdminBuilder =
    isAdministrationMode && !location.pathname.startsWith("/buildx/form-builder") && !location.pathname.startsWith("/buildx/page-builder");

  const { background, paper, textPrimary, primaryLight, primaryMain } = appTheme || {};

  const colors = {
    ...color,
    background: isAdminBuilder || !background ? (fqdnApp ? "white" : color.background) : background,
    paper: isAdminBuilder || !paper ? color.paper : paper,
    textPrimary: isAdminBuilder || !textPrimary ? color.textPrimary : textPrimary,
    primaryLight: isAdminBuilder || !primaryLight ? color.primaryLight : primaryLight,
    primaryMain: isAdminBuilder || !primaryMain ? color.primaryMain : primaryMain,
  };

  const cacheRtl = useMemo(
    () =>
      createCache({
        key: isRTL ? "muirtl-rtl" : "muirtl-ltr",
        stylisPlugins: isRTL ? [rtlPlugin] : [],
        prepend: true,
      }),
    [isRTL]
  );

  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={themes(colors, isAdminBuilder ? {} : fqdnApp?.appConfig?.theme || appTheme, isRTL)}>
        {props.children}
      </ThemeProvider>
    </CacheProvider>
  );
}

export default ThemeProviderWrapper;
