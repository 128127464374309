import { FC, memo } from "react";
import { useBXContext } from "src/BXEngine/BXContext";
import withStripe from "./ComponentWithStripe";
import { RenderItems } from "./RenderItems";

interface ContainerChildrenProps {
  props: any;
  data: any;
  path: string;
  index: number;
  parentKey: string;
  disabled: boolean;
  stripe?: any;
  elements?: any;
  element: any;
}

const ContainerChildrenBase: FC<ContainerChildrenProps> = ({ props, data, path, index, parentKey, disabled, stripe, elements }) => {
  return (
    <RenderItems
      {...props}
      data={data}
      path={path}
      index={index}
      parentKey={parentKey}
      disabled={disabled}
      stripe={stripe}
      elements={elements}
    />
  );
};

const ContainerChildren: FC<ContainerChildrenProps> = props => {
  const { element } = props;
  const { stripePromises, clientSecrets } = useBXContext();
  const matchingClientSecret = clientSecrets?.find(secret => secret.id === element?.id);
  const matchingStripePromise = stripePromises?.find(promise => promise.id === element?.id);

  if (element?.config?.isStripeWrapper && matchingStripePromise && matchingClientSecret) {
    return withStripe(ContainerChildrenBase)(props);
  } else {
    return <ContainerChildrenBase {...props} />;
  }
};

export default memo(ContainerChildren);
