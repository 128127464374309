import { FormHelperText } from "@mui/material";
import { AddressElement } from "@stripe/react-stripe-js";
import { memo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useBXContext } from "src/BXEngine/BXContext";

export const StripeAddressElement = memo((props: any) => {
  const { clientSecrets, stripePromises } = useBXContext();
  const [error, setError] = useState<any>(null);
  const location = useLocation();
  const builderView = location.pathname.startsWith("/buildx/form-builder");
  const parentId = builderView ? props?.$config?.parent?.id : props?.__config?.parent?.id;
  const matchingClientSecret = clientSecrets?.find(clientSecret => clientSecret.id === parentId);
  const matchingStripePromise = stripePromises?.find(stripePromise => stripePromise.id === parentId);
  return (
    <>
      {matchingClientSecret && matchingStripePromise && (
        <>
          <AddressElement
            options={{ mode: "shipping" }}
            onChange={(e: any) => {
              setError(e.error);
            }}
          />
          <FormHelperText error>{error?.message}</FormHelperText>
        </>
      )}
    </>
  );
});
