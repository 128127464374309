import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Dialog, DialogActions, DialogTitle, Divider, Grid, ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import { IconX } from "@tabler/icons-react";
import { FC, memo, useState } from "react";
import BXModal from "src/components/BXUI/Modal";
import ImportIcons from "../../../../assets/images/icons/Import.svg";
import UploadIcons from "../../../../assets/images/icons/Upload Minimalistic.svg";
import CreateTemplateModal from "../ManageTemplates/CreateTemplateModal";
import TemplatesTable from "../ManageTemplates/TemplatesTable";
import OASSelector from "../OASSelector";
import { LayoutToggleButtons } from "./components/LayoutToggleButtons";
import { recursiveRemove } from "./utils";

const ViewBuilderHeader: FC<any> = ({
  isEmptyView,
  layoutBreak,
  handleBackButton,
  templateData,
  isEditingTemplate,
  handleChangeLayout,
  template,
  onUpdateTemplate,
  onExportTemplate,
  addTemplate,
  handleBuilderSave,
  isHistory,
  isSaving,
  setView,
  view,
  isLoading,
  viewName,
  setHasLayoutReset,
  appBuilderMode,
}) => {
  const [openTemplateList, setOpenTemplateList] = useState(false);
  const [openExportTemplate, setOpenExportTemplate] = useState(false);
  const [openReplaceModal, setOpenReplaceModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const [anchorEl, setAnchorEl] = useState(null);
  const [isIconRotated, setIsIconRotated] = useState(false);

  const handleOpenMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
    setIsIconRotated(!isIconRotated);

    if (!isIconRotated) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setIsIconRotated(!isIconRotated);
  };

  const handleSelectOption = (option: any) => {
    handleCloseMenu();
    if (option === "Upload") {
      setOpenExportTemplate(true);
      setIsIconRotated(!isIconRotated);
    } else if (option === "Import") {
      setOpenTemplateList(true);
      setIsIconRotated(!isIconRotated);
    }
    handleCloseMenu();
  };

  const handleSynchronizeLayout = () => {
    const currentLayoutBreakpoint = layoutBreak;

    setView(prevView => {
      //Recursively traverse the view object properties and delete the current layout settings for form builder items and other.
      recursiveRemove(prevView, currentLayoutBreakpoint);
      return prevView;
    });

    setHasLayoutReset(prev => !prev);
  };
  const url = window.location.pathname;
  let defaultType;
  if (appBuilderMode) {
    defaultType = "APPLICATION";
  } else {
    if (url.startsWith("/buildx/page-builder/")) {
      defaultType = "PAGE";
    } else {
      defaultType = "VIEW";
    }
  }

  return (
    <>
      <LoadingButton loading={isLoading} variant='outlined' onClick={handleBackButton}>
        {isEditingTemplate ? "Back" : "Back to config"}
      </LoadingButton>
      {!isHistory && (
        <>
          {isEditingTemplate ? (
            <LoadingButton
              variant='contained'
              loading={isSaving}
              onClick={() => {
                onUpdateTemplate(templateData?.data);
              }}
            >
              Update Template
            </LoadingButton>
          ) : (
            <LoadingButton variant='contained' loading={isSaving} onClick={handleBuilderSave}>
              Save
            </LoadingButton>
          )}
        </>
      )}
      {!isHistory && (
        <>
          <Button
            onClick={event => {
              handleOpenMenu(event);
            }}
            aria-controls='template-menu'
            aria-haspopup='true'
            endIcon={
              <ArrowDropUpIcon
                style={{
                  fontSize: 28,
                  transform: isIconRotated ? "rotate(180deg)" : "rotate(0deg)",
                  transition: "transform 0.3s ease",
                }}
              />
            }
          >
            Template
          </Button>
          <Menu id='template-menu' anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
            {!isEditingTemplate && (
              <MenuItem onClick={() => handleSelectOption("Upload")}>
                <ListItemIcon>
                  <img src={UploadIcons} alt='Upload Icon' width='24' height='24' />
                </ListItemIcon>
                Export
              </MenuItem>
            )}
            <MenuItem onClick={() => handleSelectOption("Import")}>
              <ListItemIcon>
                <img src={ImportIcons} alt='Import Icon' width='24' height='24' />
              </ListItemIcon>
              Import
            </MenuItem>
          </Menu>
        </>
      )}
      <LayoutToggleButtons activeLayout={layoutBreak} onLayoutChange={handleChangeLayout} />

      {!isHistory && (
        <Grid display='flex' item xs={12} alignItems='center' gap={"1px"}>
          <OASSelector
            swaggerProps={{
              type: "form-builder",
              onlyModels: true,
              formBuilder: view?.dataSource?.formBuilder,
              fillFormBuilder: true,
              onSuccess: (values: any) => {
                setView((prev: any) => {
                  return {
                    ...prev,
                    dataSource: {
                      ...prev?.dataSource,
                      formBuilder: values.formBuilder,
                    },
                  };
                });
              },
            }}
          />
        </Grid>
      )}

      {!isHistory && (
        <>
          <BXModal
            title={"Import template"}
            open={!!openTemplateList}
            onClose={() => {
              setOpenTemplateList(false);
            }}
          >
            <TemplatesTable
              isImport={true}
              setOpenTemplateList={setOpenTemplateList}
              setSelectedRow={setSelectedRow}
              setOpenReplaceModal={setOpenReplaceModal}
              addTemplate={addTemplate}
              isEmptyView={isEmptyView}
              templateId={template}
              defaultType={defaultType}
            />
          </BXModal>
          <Dialog
            open={openReplaceModal}
            onClose={() => setOpenReplaceModal(false)}
            closeAfterTransition
            keepMounted={false}
            BackdropProps={{
              timeout: 500,
            }}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
            fullWidth
            maxWidth={"sm"}
          >
            <DialogTitle display={"flex"} alignItems='center'>
              <Box flex={1} display={"flex"} alignItems='center'>
                <Typography marginInlineStart={1} id='modal-modal-title' fontSize={16} fontWeight={800}>
                  Do you want to merge or replace?
                </Typography>
              </Box>
              <Box sx={{ cursor: "pointer", display: "flex" }} onClick={() => setOpenReplaceModal(false)}>
                <IconX />
              </Box>
            </DialogTitle>
            <Divider />

            <DialogActions sx={{ justifyContent: "center", alignItems: "center" }}>
              <Button
                autoFocus
                variant='contained'
                onClick={() => {
                  addTemplate(selectedRow, false);
                  setOpenReplaceModal(false);
                }}
              >
                Merge
              </Button>
              <Button
                variant='outlined'
                autoFocus
                onClick={() => {
                  addTemplate(selectedRow, true);
                  setOpenReplaceModal(false);
                }}
              >
                Replace
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
      {!isEditingTemplate && (
        <>
          <CreateTemplateModal
            open={openExportTemplate}
            isExport={true}
            defaultName={viewName}
            templateID={template}
            defaultVisibility={"PUBLIC"}
            defaultType={defaultType}
            onSubmit={onExportTemplate}
            onClose={() => {
              setOpenExportTemplate(false);
            }}
          />
        </>
      )}

      {layoutBreak !== "lg" && (
        <Button variant='outlined' onClick={handleSynchronizeLayout}>
          Synchronize Layout
        </Button>
      )}
    </>
  );
};

export default memo(ViewBuilderHeader);
