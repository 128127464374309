import { useElements, useStripe } from "@stripe/react-stripe-js";

const withStripe = (WrappedComponent: any) => {
  return (props: any) => {
    const stripe = useStripe();
    const elements = useElements();
    if (!stripe || !elements) {
      return <></>;
    }

    return <WrappedComponent {...props} stripe={stripe} elements={elements} />;
  };
};

export default withStripe;
