import { Button, CardMedia, DialogActions, InputAdornment, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

import { IconPictureInPicture, IconXboxX } from "@tabler/icons-react";
import axios from "axios";
import { FC, useEffect, useRef, useState } from "react";
import ReactCrop, { Crop, PixelCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { encodeURLParams, replaceBaseUrl, useBXContext } from "src/BXEngine/BXContext";
import { BXIcon } from "src/components/BXUI/Icon";
import BXModal from "src/components/BXUI/Modal";
import { enqueueSnackbarRef } from "src/utils/SnackbarUtilsConfigurator";
import { getAuthorizationHeader, getCroppedImageFile } from "src/utils/generalUtils";
import { CustomIconButton } from "../CustomIconButton";

export const FileUploadInput: FC<any> = props => {
  const {
    onChange,
    config,
    startIcon,
    startIconColor,
    endIconColor,
    endIconSize,
    startIconSize,
    uploaderType = "Default",
    uploadLabel,
    uploadLabelColor,
    uploadLabelSize,
    uploadLabelWeight,
    uploadLabelTypography = "Typography",
    uploadColorTypography,
    uploadSizeTypography,
    borderBottomTypography,
    endIcon,
    // TODO: remove this after supporting action config upload
    iconColor,
    IconBorderWidth,
    iconSize,
    IconBorderColor,
    iconName,
    IconBackgroundColor,
  } = props;
  const { value, ...rest } = props;
  const { uploadConfig } = config || {};

  const {
    fileNumber = 1,
    multipleFiles = false,
    isCropImage = false,
    withPreview = true,
    borderRadiusPreview,
    widthPreview,
    heightPreview,
  } = uploadConfig || {};
  const [croppedImage, setCroppedImage] = useState<any>();
  const [crop, setCrop] = useState<Crop>();
  const imgRef = useRef<HTMLImageElement>(null);
  const [imageData, setImageData] = useState({ imageSrc: "", onChange: () => {} });
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const { currentApp, currentProfileId } = useBXContext();

  const fileInputRef = useRef<any>();
  const [files, setFiles] = useState<any>([]);
  const [maxFiles, setMaxFiles] = useState<any>(1);
  const [maxFilesError, setMaxFilesError] = useState<any>("");
  const [isLoading, setIsLoading] = useState<any>(null);
  const [fileName, setFileName] = useState("");

  useEffect(() => {
    if (fileNumber) {
      setMaxFiles(Number(fileNumber));
    }
  }, []);

  useEffect(() => {
    if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
      // We use canvasPreview as it's much faster than imgPreview.
      getCroppedImageFile(imgRef.current, previewCanvasRef.current, completedCrop, (croppedImage: File) => {
        setCroppedImage(croppedImage);
      });
    }
  }, [completedCrop]);

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>, onChange?: any) => {
    if (!event.target.files?.length) return;

    if (maxFiles - files?.length < 1 && multipleFiles) {
      event.target.value = "";
    }
    const fileCount = !multipleFiles
      ? 1
      : event.target.files?.length < maxFiles - files?.length
      ? event.target.files?.length
      : maxFiles - files?.length;

    if (!fileCount) {
      setMaxFilesError(true);
    } else {
      setMaxFilesError(false);
    }

    let _files: any = files;
    for (let i = 0; i < fileCount; i++) {
      const file = event.target.files[i];
      setFileName(event.target.files[i].name);

      if (!file) return;
      const urlObject = URL.createObjectURL(file);
      const fileObject = { url: urlObject, file: { ...file, type: file.type.split("/")[0] } };
      _files = !multipleFiles ? [fileObject] : [..._files, fileObject];

      setFiles((prev: any) => (!multipleFiles ? [fileObject] : [...prev, fileObject]));
      setIsLoading(true);

      const token = uploadConfig?.customToken || localStorage.getItem(currentApp?.id + `-${currentProfileId}-accessToken`);

      let key: any = null;
      let finalizedResponseUrl: any = null;

      try {
        if (uploadConfig?.uploadUrlType == "aws") {
          const { data } = await axios.get(encodeURLParams(replaceBaseUrl(uploadConfig?.signedUrl, currentApp)), {
            headers: { ...getAuthorizationHeader(currentApp?.appConfig?.auth, token) },
          });

          const { key: responseKey, url } = data;
          key = responseKey;
          const extn = file.type.split("/")[1];

          await axios.put(url, file, {
            headers: {
              "Content-Type": file.type,
            },
          });
          if (uploadConfig?.finalizedUrl) {
            const { data } = await axios.post(encodeURLParams(replaceBaseUrl(uploadConfig?.finalizedUrl, currentApp)), undefined, {
              params: {
                key,
                extn,
              },
              headers: { ...getAuthorizationHeader(currentApp?.appConfig?.auth, token) },
            });
            finalizedResponseUrl = data?.url;
          }
        } else {
          await axios.post(encodeURLParams(replaceBaseUrl(uploadConfig?.customUrl, currentApp)), file, {
            headers: {
              "Content-Type": file.type,
            },
          });
        }

        _files = _files.map((item: any) => {
          const newData = {
            ...item,
            value: { extn: file.type.split("/")[1], key, ...(finalizedResponseUrl && { url: finalizedResponseUrl }) },
          };
          return item?.url == urlObject ? newData : item;
        });
        if (i == fileCount - 1) {
          onChange?.(
            !multipleFiles
              ? { url: finalizedResponseUrl || fileObject?.url, key, ext: file.type.split("/")[1], name: file.name, file }
              : _files.map((item: any) => ({ type: item?.file?.type, url: item?.url, ...item?.value }))
          );
          setFiles(_files);
        }
      } catch (e) {
        enqueueSnackbarRef?.("Something went wrong", {
          variant: "error",
        });
        event.target.value = "";
        setFiles((prev: any) => prev.filter((item: any) => item?.url != urlObject));
      } finally {
        if (uploadConfig?.isInput != "Yes" || multipleFiles) {
          if (i == fileCount - 1) {
            event.target.value = "";
          }
        }
        setIsLoading(false);
      }
    }
  };

  const handleClick = () => {
    if (fileInputRef.current) {
      const inputElement = fileInputRef.current.querySelector('input[type="file"]');
      if (inputElement) {
        inputElement?.click();
      }
    }
  };

  return (
    <>
      <BXModal
        open={!!imageData?.imageSrc}
        label={"Crop Image"}
        icon={<IconPictureInPicture />}
        buttonProps={{ variant: "text", color: "secondary", startIcon: <IconPictureInPicture /> }}
        title={"Crop Image"}
        onClose={() => {
          setImageData(prev => ({ ...prev, imageSrc: "" }));
        }}
      >
        {(handleClose: Function) => {
          return (
            <>
              <ReactCrop
                crop={crop}
                onComplete={c => {
                  setCompletedCrop(c);
                }}
                aspect={16 / 9}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
              >
                <img alt='Crop me' ref={imgRef} src={imageData.imageSrc} />
              </ReactCrop>
              <Grid item xs={12}>
                <DialogActions style={{ padding: 0, marginTop: 16, justifyContent: "center" }}>
                  <Button
                    variant={"contained"}
                    onClick={() => {
                      handleClose();
                      setImageData(prev => ({ ...prev, imageSrc: "" }));
                      handleChange({ target: { files: [croppedImage] } } as any, imageData.onChange);
                    }}
                  >
                    Done
                  </Button>
                </DialogActions>
              </Grid>
              <div style={{ display: "none" }}>
                {!!completedCrop && (
                  <canvas
                    ref={previewCanvasRef}
                    style={{
                      border: "1px solid black",
                      objectFit: "contain",
                      width: completedCrop.width,
                      height: completedCrop.height,
                    }}
                  />
                )}
              </div>
            </>
          );
        }}
      </BXModal>
      {
        // TODO: remove this after supporting action config upload
      }
      {uploaderType === "IconButton" && (
        <CustomIconButton
          iconName={iconName}
          backgroundColor={IconBackgroundColor}
          borderColor={IconBorderColor}
          iconSize={iconSize}
          borderWidth={IconBorderWidth}
          iconColor={iconColor}
          onClick={handleClick}
        />
      )}
      {uploaderType === "TextField" && (
        <TextField
          fullWidth
          defaultValue={uploadLabel}
          label={!!fileName ? uploadLabel : ""}
          InputProps={{
            ...props?.inputProps,
            accept: uploadConfig?.uploadAllowedTypes?.map(item => `${item.toLowerCase()}${item[0] !== "." ? "/*" : ""}`).join(","),
            multiple: multipleFiles && !isCropImage,
            startAdornment: startIcon && (
              <InputAdornment position='start'>
                <BXIcon icon={startIcon} width={startIconSize} height={startIconSize} color={startIconColor} />
              </InputAdornment>
            ),
            endAdornment: endIcon && (
              <InputAdornment position='end'>
                <BXIcon icon={endIcon} width={endIconSize} height={endIconSize} color={endIconColor} />
              </InputAdornment>
            ),
          }}
          sx={{
            height: "100%",
            cursor: "pointer",
            "& .MuiInputAdornment-root, & .MuiInputBase-input, & .MuiOutlinedInput-root": {
              cursor: "inherit",
            },
            "& .MuiOutlinedInput-root": {
              height: "100%",
            },
            "& .MuiInputBase-input": {
              color: uploadLabelColor || "white",
              fontSize: uploadLabelSize || "14px",
              fontWeight: uploadLabelWeight || "500",
            },
          }}
          disabled={!!isLoading}
          type='text'
          value={fileName || uploadLabel}
          inputProps={{ readOnly: true }}
          onClick={handleClick}
          aria-errormessage='error message'
          size='medium'
        />
      )}
      {uploaderType === "Typography" && (
        <Typography
          onClick={handleClick}
          sx={{
            cursor: "pointer",
            color: uploadColorTypography || "white",
            fontSize: uploadSizeTypography || "14px",
            ...(borderBottomTypography && { textDecoration: "underline" }),
            textDecorationColor: uploadColorTypography || "white",
          }}
        >
          {uploadLabelTypography}
        </Typography>
      )}

      <TextField
        {...rest}
        ref={fileInputRef}
        fullWidth
        InputProps={{
          ...props?.inputProps,
          accept: uploadConfig?.uploadAllowedTypes?.map((item: any) => `${item.toLowerCase()}${item[0] != "." ? "/*" : ""}`).join(","),
          multiple: multipleFiles && !isCropImage,
          startAdornment: startIcon && (
            <InputAdornment position='start'>
              <BXIcon icon={startIcon} width={startIconSize} height={startIconSize} color={startIconColor} />
            </InputAdornment>
          ),
          endAdornment: endIcon && (
            <InputAdornment position='end'>
              <BXIcon icon={endIcon} width={endIconSize} height={endIconSize} color={endIconColor} />
            </InputAdornment>
          ),
        }}
        type='file'
        disabled={!!isLoading}
        aria-errormessage={"error message"}
        onChange={(e: any) => {
          if (!isCropImage) {
            handleChange(e, onChange);
          } else {
            const urlObject = URL.createObjectURL(e.target.files[0]);
            setImageData({ imageSrc: urlObject, onChange });
          }
        }}
        size={"medium"}
        style={uploaderType === "Default" ? { display: "block" } : { display: "none" }}
      />

      {maxFilesError && (
        <Box minHeight={"22px"}>
          <FormHelperText error id='standard-weight-helper-text-email-login'>
            You have reached the maximum number of files
          </FormHelperText>
        </Box>
      )}
      {!!withPreview && !!files?.length && (
        <Grid container flexWrap={"wrap"} spacing={2} mt={2}>
          {files.map((file: any, index: number) => {
            return (
              <Grid item key={`${file?.url}-${index}`} paddingTop={"0px !important"}>
                <Box position='relative'>
                  <Box
                    sx={{
                      position: "absolute",
                      top: 1,
                      left: 1,
                      cursor: "pointer",
                      zIndex: 999,
                    }}
                    onClick={() => {
                      setFiles((prev: any) => {
                        const values = [...prev.map((item: any) => (item?.url == file?.url ? null : item?.value)).filter(Boolean)];
                        onChange?.(!multipleFiles ? values.join("") : values);

                        return prev.filter((item: any) => item?.url != file?.url);
                      });
                    }}
                  >
                    <IconXboxX color={"red"} />
                  </Box>
                  {file?.file?.type == "image" ? (
                    <img
                      src={file?.url}
                      alt=''
                      style={{
                        maxHeight: heightPreview,
                        maxWidth: widthPreview,
                        objectFit: "contain",
                        border: "1px solid #5a5a5a",
                        borderRadius: borderRadiusPreview,
                      }}
                    />
                  ) : (
                    <CardMedia
                      sx={{ border: `0px` }}
                      style={{
                        maxHeight: file?.file?.type == "audio" ? 50 : 150,
                        marginTop: 12,
                      }}
                      component='video'
                      src={file?.url}
                      controls
                    />
                  )}
                </Box>
              </Grid>
            );
          })}
        </Grid>
      )}
    </>
  );
};
