import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";

export let validationRegistry: Record<string, { [key: string]: (value: any) => true | string }> = {};

// Register a validation function for a specific field
export const registerValidation = (name: string, validate: { [key: string]: (value: any) => true | string }) => {
  if (_.get(validationRegistry, name)) {
    return;
  }
  _.set(validationRegistry, name, validate);
};
interface AppState {
  values: Record<string, any>;
  errors: Record<string, string>;
  dirtyFields: Record<string, boolean>;
  initialValues: Record<string, any>;
  loading: Record<string, any>; //Track loading views and pages state
  triggers: Record<string, any>; //Track and produce events for subscribers/listeners to consume.
  pagination: Record<string, any>; //Track pagination info data sources for views and components
}

const initialState: AppState = {
  values: {},
  errors: {},
  dirtyFields: {},
  initialValues: {},
  loading: {},
  triggers: {},
  pagination: {},
};

const appStateSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    initializeAppField: (state, action: PayloadAction<{ name: string; value: any; hierarchyName: string }>) => {
      const { name, value, hierarchyName } = action.payload;
      setNestedValue(state.values, name, value);
      setNestedValue(state.initialValues, name, value);
      setNestedValue(state.dirtyFields, hierarchyName, false, true);
      deleteNestedValue(state.errors, hierarchyName);
    },
    setAppValue: (
      state,
      action: PayloadAction<{ name: string; value: any; isDisabledDirtyField?: boolean; isDisabledErrors?: boolean }>
    ) => {
      const { name, value, isDisabledDirtyField, isDisabledErrors } = action.payload;
      setNestedValue(state.values, name, value);

      if (!isDisabledDirtyField) {
        const isDirty = value !== getNestedValue(state.initialValues, name, "");
        setNestedValue(state.dirtyFields, getHierarchyName(name, state.dirtyFields), isDirty, true);
      }
      if (!isDisabledErrors) {
        deleteNestedValue(state.errors, getHierarchyName(name, state.errors));
      }
    },
    setAppError: (state, action: PayloadAction<{ name: string; error: string }>) => {
      const { name, error } = action.payload;
      if (error) {
        setNestedValue(state.errors, name, error);
      } else {
        deleteNestedValue(state.errors, name); // Remove the error property if no error
      }
    },
    removeAppField: (state, action: PayloadAction<{ name: string }>) => {
      const { name } = action.payload;
      deleteNestedValue(state.pagination, name);
      deleteNestedValue(state.triggers, name);
      deleteNestedValue(state.values, name);
      deleteNestedValue(state.initialValues, name);
      deleteNestedValue(state.dirtyFields, getHierarchyName(name, state.dirtyFields));
      deleteNestedValue(state.errors, getHierarchyName(name, state.errors));
      // TODO: we need also to remove validations for this field
      validationRegistry = {};
    },
    deleteAppValue: (state, action: PayloadAction<{ name: string }>) => {
      const { name } = action.payload;
      deleteNestedValue(state.values, name);
      deleteNestedValue(state.initialValues, name);
      deleteNestedValue(state.dirtyFields, getHierarchyName(name, state.dirtyFields));
      deleteNestedValue(state.errors, getHierarchyName(name, state.errors));
    },
    resetAppState: (state, action: PayloadAction<{ name?: string }>) => {
      const { name } = action.payload;

      if (name) {
        // Reset a specific field if name is provided
        const initialValue = getNestedValue(state.initialValues, name);
        setNestedValue(state.values, name, initialValue);
        deleteNestedValue(state.errors, getHierarchyName(name, state.errors));
        deleteNestedValue(state.dirtyFields, getHierarchyName(name, state.dirtyFields));
      } else {
        // Reset the entire state
        state.values = { ...state.initialValues };
        state.errors = {};
        state.dirtyFields = {};
      }
    },
    //Set and reset the loading state for a specific page or view by key (name).
    setLoading: (state, action: PayloadAction<{ keys?: any }>) => {
      const { keys } = action.payload;

      if (keys) {
        if (keys.page) {
          state.loading[keys.page] = true;
        }
        if (keys.view) {
          state.loading[keys.view] = true;
        }
      }
    },
    resetLoading: (state, action: PayloadAction<{ keys?: any }>) => {
      const { keys } = action.payload;

      if (keys) {
        if (keys.page) {
          state.loading[keys.page] = false;
        }
        if (keys.view) {
          state.loading[keys.view] = false;
        }
      }
    },
    removeDirtyFlags: (state, action: PayloadAction<{ name?: string }>) => {
      const { name } = action.payload;
      if (name) {
        deleteNestedValue(state.dirtyFields, getHierarchyName(name, state.dirtyFields));
      } else {
        state.dirtyFields = {};
      }
    },

    /**
     * Produce an event trigger for a specific field or view.
     * When a "trigger" is produced for a field,
     * it sends a signal to the field (or view) to perform a certain action.
     *
     * @param name - The field or view that this trigger is associated with.
     * @param type - The type of event that is being triggered (e.g., 'refetch', 'update', 'delete', 'fetchNextPage).
     * @param eventPayload - Any additional data that needs to be passed along with the trigger event.
     *
     * Example:
     * If a table view needs to fetch new data, you could trigger a 'refetch' event with relevant payload.
     */
    produceTrigger: (state, action: PayloadAction<{ name?: string; type?: string; eventPayload?: any }>) => {
      const { name } = action.payload;
      const signalEventPayload = {
        type: action.payload.type, // Event type (e.g., 'refetch', 'reset')
        payload: action.payload.eventPayload, // Any additional data needed for handling the event
      };

      if (name) {
        setNestedValue(state.triggers, name, signalEventPayload); // Store the trigger event under the specific field or view
      }
    },
    setPaginationInfo: (state, action: PayloadAction<{ name?: string; paginationObject: any }>) => {
      const { name, paginationObject } = action.payload;

      if (name) {
        setNestedValue(state.pagination, name, paginationObject);
      }
    },
    setDirtyFlag: (state, action: PayloadAction<{ name: string; isDirty: boolean }>) => {
      const { name, isDirty } = action.payload;
      if (name) {
        setNestedValue(state.dirtyFields, getHierarchyName(name, state.dirtyFields), isDirty, true);
      }
    },
    updateDirtyFields: (state, action: PayloadAction<{ name: string; value: any }>) => {
      const { name, value } = action.payload;
      if (name) {
        setNestedValue(state.dirtyFields, getHierarchyName(name, state.dirtyFields), value);
      }
    },
    clearErrorFields: (state, action: PayloadAction<{ name: string; value: any }>) => {
      const { name, value } = action.payload;
      if (name) {
        setNestedValue(state.errors, getHierarchyName(name, state.errors), value);
      }
    },
  },
});

export const {
  setAppValue,
  setAppError,
  initializeAppField,
  removeAppField,
  resetAppState,
  setLoading,
  resetLoading,
  removeDirtyFlags,
  updateDirtyFields,
  setDirtyFlag,
  produceTrigger,
  setPaginationInfo,
  clearErrorFields,
  deleteAppValue,
} = appStateSlice.actions;
export default appStateSlice.reducer;

// Parsing function to convert the path into an array of keys
function parsePath(path: string): (string | number)[] {
  const regex = /([^[.\]]+)/g;
  const matches = path.match(regex) || [];
  return matches.map(key => {
    const index = Number(key);
    return isNaN(index) ? key : index;
  });
}

// Helper functions to set/get nested values
function setNestedValue(object: any, path: string | null, value: any, override = false) {
  if (path === null) return;
  const keys = parsePath(path);
  let current = object;

  keys.slice(0, -1).forEach((key, index) => {
    const nextKey = keys[index + 1];
    if (typeof nextKey === "number") {
      // Next key is a number, so current key should be an array
      if (!Array.isArray(current[key])) {
        current[key] = [];
      }
    } else {
      // Next key is not a number, current key should be an object
      if (!current[key] || typeof current[key] !== "object" || Array.isArray(current[key])) {
        // Check if current[key] is an array and needs to be converted to an object
        if (Array.isArray(current[key])) {
          current[key] = [...current[key]]; // Convert array to object
        } else {
          current[key] = {};
        }
      }
    }
    current = current[key];
  });

  if (override && _.isObject(current[keys[keys.length - 1]])) {
    return;
  }

  const finalKey = keys[keys.length - 1];
  current[finalKey] = value;
}

function getNestedValue(object: any, path: string, defaultValue: any = undefined) {
  const keys = path.replace(/\[(\w+)\]/g, ".$1").split(".");
  let current = object;

  for (let key of keys) {
    if (current[key] === undefined) {
      return defaultValue;
    }
    current = current[key];
  }

  return current;
}

function deleteNestedValue(object: any, path: string | null) {
  if (path === null) return;
  const keys = path.replace(/\[(\w+)\]/g, ".$1").split(".");
  let current = object;
  let parents: {
    parent: any;
    key: string;
  }[] = [];

  // Traverse to the desired nested object
  for (let i = 0; i < keys.length - 1; i++) {
    const key = keys[i];
    if (current[key] === undefined) {
      return; // Exit if the path doesn't exist
    }
    parents.push({ parent: current, key });
    current = current[key];
  }

  const finalKey = keys[keys.length - 1];
  delete current[finalKey]; // Delete the specific error property

  // Clean up empty parent objects
  for (let i = parents.length - 1; i >= 0; i--) {
    const { parent, key } = parents[i];
    if (Object.keys(parent[key]).length === 0) {
      delete parent[key];
    } else {
      break; // Stop if we find a non-empty parent
    }
  }
}

export function getHierarchyName(name: string, obj: any): string | null {
  const keys = name.split(".");
  let resultPath: string[] = [];

  function traverse(currentObj: any, remainingKeys: string[], path: string[]): boolean {
    if (remainingKeys.length === 0) {
      return true;
    }

    const [currentKey, ...restKeys] = remainingKeys;

    if (currentObj && currentObj.hasOwnProperty(currentKey)) {
      path.push(currentKey);
      return traverse(currentObj[currentKey], restKeys, path);
    } else {
      for (const key in currentObj) {
        if (typeof currentObj[key] === "object") {
          path.push(key);
          if (traverse(currentObj[key], remainingKeys, path)) {
            return true;
          }
          path.pop();
        }
      }
    }

    return false;
  }

  traverse(obj, keys, resultPath);
  if (resultPath[resultPath.length - 1] === keys[keys.length - 1]) {
    return resultPath.join(".");
  }
  return null;
}
